import React, { useState } from "react";
import axios from "axios";
import "./offer.css";
import Footer from "../Footer/footer";
import { useForm } from "react-hook-form";

const UpdateOffer = (props) => {
    
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [image, setImage] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [disImage, setDisImage] = useState("");

  const {register,handleSubmit,formState:{errors},reset} =useForm();
  
  function updateOfferData(postdata) {
    console.log(props.updatedData,"123456789")
    var data = new FormData();
    data.append("_id", props.updatedData?._id);
    data.append(
      "Discount",  postdata.title === "" || null ? props.updatedData?.Discount  :  postdata.title);

      data.append(
        "heading", postdata.heading === "" || null ? props.updatedData?.heading  : postdata.heading);
     
     data.append(
        "offerdiscreption", postdata.discreption === "" || null ? props.updatedData?.offerdiscreption  : postdata.discreption);
      data.append(
        "image",
        image === "" || null ? props.updatedData?.image : image
      );
  
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editOffer`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        props.onBox()
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };
  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Offer
      </h5>
      <button
        type="button"
        className="btn-close"
        onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateOfferData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Offer<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.Discount}
              className="form-control"
              {...register("title", {
                required: true,
                min:1
              })}
            />
            {errors?.title?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.title?.type === "min" && (
              <p>Minimum one letter is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Heading<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.heading}
              className="form-control"
              {...register("heading", {
                required: true,
                min:1
              })}
            />
            {errors?.heading?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.heading?.type === "min" && (
              <p>Minimum one letter is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Description<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.offerdiscreption}
              className="form-control"
              {...register("discreption", {
                required: true,
                min:1
              })}
            />
            {errors?.discreption?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.discreption?.type === "min" && (
              <p>Minimum one letter is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
       

        <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  />
                ) : (
                  renderImages(disImage)
                )}
          </div>
      </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
    </form>
  </>)
}

export default UpdateOffer;