import { AiFillAppstore } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { AiFillTags } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { FaList } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { FiList } from "react-icons/fi";
import { VscServerProcess } from "react-icons/vsc";

import { FaCity } from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { GiFuelTank } from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";

import { FcInspection } from "react-icons/fc";
import { MdOutlineSettingsSuggest } from "react-icons/md";

import { FaBorderAll } from "react-icons/fa";

export const Role = {
  ADMIN: "Admin",
  SUBADMIN: "Subadmin",
};

export const modules = [
  {
    value: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <AiFillAppstore />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "order",
    label: "Order",
    link: "/Order",
    icon: <FaBorderAll />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "payment_details",
    label: "Payment Details",
    link: "/paymentdetails",
    icon: <FaBorderAll />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "users",
    label: "Users",
    link: "/User",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "scout_management",
    label: "Scout Management",
    link: "/Scout",
    icon: <FiList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "city_management",
    label: "City Management",
    link: "/Citys",
    icon: <FaCity />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "banner_master",
    label: "Banners Master",
    link: "/bannerCategory",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "category_master",
    label: "Category Master",
    link: "/Category",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "subcategory_master",
    label: "Sub-Category Master",
    link: "/Subcategory",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "service_elements",
    label: "Service Elements....",
    link: "/Serviceparts",
    icon: <VscServerProcess />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "plan_management",
    label: "Plan Management",
    link: "/Plan",
    icon: <FaPaperPlane />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "master_plan",
    label: "Master Plan",
    link: "/masterplan",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "offer",
    label: "Offer",
    link: "/offer",
    icon: <AiFillAppstore />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "car_listing",
    label: "Car Management",
    children: [
      {
        value: "brand_master",
        label: "Brands Master",
        link: "/Brand",
        icon: <SiBrandfolder />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "fuel",
        label: "Fuel",
        link: "/Fuel",
        icon: <GiFuelTank />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "car_category",
        label: "Car Category",
        link: "/CarCategory",
        icon: <FaList />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
    ],
    icon: <AiFillCar />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "car_master",
    label: "Car Master",
    link: "/Cars",
    icon: <AiFillCar />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "tracking_preset",
    label: "Tracking Presets",
    link: "/Preset",
    icon: <MdOutlineSettingsSuggest />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "reports",
    label: "Reports",
    children: [
      {
        value: "pre_inspection",
        label: "Pre-Inspection",
        link: "/PreInspection",
        icon: <FaList />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "post_inspection",
        label: "Post-Inspection",
        link: "/PostInspection",
        icon: <FaList />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
    ],
    icon: <AiFillCar />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "workshop_master",
    label: "Workshop Master",
    link: "/workshopmaster",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },

  
  // {
  //   value: "inspection_subcategory",
  //   label: "Inspection Sub..",
  //   link: "/InspectionSubCategory",
  //   icon: <FaList />,
  //   isAdding: true,
  //   isEditing: true,
  //   isDeleting: true,
  //   isViewing: true,
  //   isDisabled: false,
  //   isPermission: false,
  //   isVerify: false,
  //   isShow: true,
  //   isHide: false,
  //   isReadonly: false,
  // },
  // {
  //   value: "job_card",
  //   label: "Job Card",
  //   link: "/JobCard",
  //   icon: <FaList />,
  //   isAdding: true,
  //   isEditing: true,
  //   isDeleting: true,
  //   isViewing: true,
  //   isDisabled: false,
  //   isPermission: false,
  //   isVerify: false,
  //   isShow: true,
  //   isHide: false,
  //   isReadonly: false,
  // },
 
  {
    value: "sos",
    label: "SOS",
    link: "/Sos",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "support",
    label: "Support",
    link: "/feedback",
    children: [
      {
        value: "user_msg",
        label: "User",
        link: "/usermsg",
        icon: <FaUsers />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "scout_msg",
        label: "Scout",
        link: "/scoutmsg",
        icon: <FiList />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
    ],
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "cms_pages",
    label: "CMS Pages",
    children: [
      {
        value: "about_us",
        label: "About Us",
        link: "/About",
        icon: <FaUsers />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "privacy_policy",
        label: "Privacy Policy",
        link: "/privacypolicy",
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "terms_and_conditions",
        label: "Terms & Conditions",
        link: "/termsandconditions",
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
      {
        value: "blog",
        label: "Blog",
        link: "/blog",
        icon: <FaUsers />,
        isAdding: true,
        isEditing: true,
        isDeleting: true,
        isViewing: true,
        isDisabled: false,
        isPermission: false,
        isVerify: false,
        isShow: true,
        isHide: false,
        isReadonly: false,
      },
    ],
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "faq",
    label: "FAQ",
    link: "/faq",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
 
  
  {
    value: "feedback",
    label: "Feedback",
    link: "/feedback",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },
  {
    value: "training",
    label: "Training",
    link: "/training",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
    isReadonly: false,
  },

];

export const userRole = {};
