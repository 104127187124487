
import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import Select from 'react-select'
import ServiceUpdat from "./ServiceUpdate";
import {useForm} from 'react-hook-form';
import Modal from "react-bootstrap/Modal";
// import {Modal} from 'react-bootstrap/Modal'
import ToggleButton from "../../Components/ToggleButton";


function Serviceparts() {
  const [DeleteDeleteId,setDeleteDeleteId] = useState("")
  const [UpdateCServiceData, setUpdateCServiceData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [title, setTitle] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(getService);
  const [categoryselect, setCategoryselect] = useState("");
  const [category, setCatSelect] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [subCategorySelect, setSubCategorySelect] = useState([]);
  const [subcategory, setSubCatSelect] = useState([]);
  const [alreadyErr, setAlreadyErr] = useState("");

  const [Plan, setPlan] = useState("");
  const [PlanId, setPlanId] = useState("");

const {register, handleSubmit,formState:{errors},reset} =useForm();
const [isOpen, setIsOpen] = useState(false);
const [show, setShow] = useState(false);

  const pagination = true;
//   const paginationPageSize = 10;
//   const rowHeight = 55;

function box() {
  setShow(false);
  cross();
}

  const imageRerander = (props) => {
    let pic = props.data.image;
    console.log(pic,"afdhds")
    return (
      // <img src={props.data.image}/>
      <span className="profle_img_box">
      {pic!=="" ? <img className="profile_img_table" src={pic}/> : ""}
    </span>
    )
  }

  const SrNoRenderer = (props) => {
    console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };


  
//   const PriceNameRenderer = (props) => {
//             console.log(props.data, "iam12333iam12333");
//     // let pricearrname =[];
//     props?.data?.map((items)=>{
//       // pricearrname.push(items.typename)
//           // console.log(items,"items")
//   })
//   return (
//     <>
//        <span>{}</span>
//     </>
//   );
// };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
        onClick={() => {
          setUpdateCServiceData(props.data);
          setShow(true)
        }}
        >
          <MdModeEditOutline
            className="ActionIcon viewicon"
            
          />
        </div>
        <div className="ViewIcon"
         onClick={() => {
          console.log(props, "VIEW");
          setViewuser(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UserViewId"
        alt=""
        src="../../images/view.jpg"
        >
          <AiFillEye
           
          />
        </div>
        <div className="DeleteIcon"
        onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
            
          />
        </div>
      </div>
    );
  };

  // ------Post API-------//

  function addService(postdata) {
   setDisabled(true)
    var data = new FormData();
    data.append("title", postdata.title);
    // data.append("category", category);
    // data.append("subcategory", subcategory);
    data.append("Plans", PlanId);
    data.append("image", postdata.addimage[0]);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/service`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.data.status===false){
          setAlreadyErr(response.data.msg)
          setDisabled(false)
          setIsOpen(true);
         }else{
        setIsOpen(false);
        setAlreadyErr("")
        getService()
        console.log(response.data, "add Category");
        crossForm();
         }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    // getCategory();
    //  getSubCategory();
    //  getPlanService();
     getService();
  }, []);

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    cross();
  }

  function cross() {
    reset();
  }
  // ------GET SERVICE API-------//

  function getService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getservice`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response,"GERSERVICE")
        
        console.log(response.data.data, "getservicescconsole");
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------GET CATEGORY API-------//

//   function getCategory() {
//     var config = {
//       method: "get",
//       url: `${process.env.REACT_APP_BASEURL}/getcategory`,
//       headers: {
//         Authorization: sessionStorage.getItem("token"),
        
//       },
//     };
//     axios(config)
//       .then(function (response) {
//         console.log(response.data.getdata,"catget1")
//         let catArr=[];
//         response.data.getdata.map((item)=>{
//           catArr.push({label:item.title,value:item._id});
//         })
//         setCategoryselect(catArr);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }

// // ------SELECT CHANGE FUNCTION FOR CATEGORY-------//

//  const handleCategory = (e) =>{
// setCatSelect(e.value)
//   }

//   // ------GET SUBCATEGORY API-------//

// function getSubCategory(){
//   var config = {
//     method: "get",
//     url:`${process.env.REACT_APP_BASEURL}/get-sub-category`,
//     headers:{
//     Authorization: sessionStorage.getItem("token"),
//     },
//   };
//   axios(config).then(function(response){
// console.log(response,"response333")
// let subCatArr =[];
// response.data.data.map((item)=>{
//   subCatArr.push({label:item.title,value:item._id})
// })
//   setSubCategorySelect(subCatArr);
//   }).catch(function(error){
//     console.log(error);
//   })

// }

const handleSubCategory=(e)=>{
  // console.log(e.label,"subcat")
  let handArr=[];
  e.forEach((value)=>{
    handArr.push(value.value)
  })
  setSubCatSelect(handArr)
  console.log(handArr,"handArr")

}


// function getPlanService() {
//   var config = {
//     method: "get",
//     url: `${process.env.REACT_APP_BASEURL}/getCommonService`,
//     headers: {
//       Authorization: sessionStorage.getItem("token"),
      
//     },
//   };
//   axios(config)
//     .then(function (response) {
//       console.log(response,"plan")
//       let planarr =[];
//     response.data.data.map((item)=>{
   
//         planarr.push({label: item.planName,value: item._id})
     
   
//     })
//      console.log(planarr,"planarr")
//        setPlan(planarr)
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

const handleChangePlan=(e)=>{
  
  setPlanId(e.value)
  }

  function servicedeleteData(id) {
    var data = JSON.stringify({
      servicecategoryId: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteservice`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getService()
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  // const editdataReloadFunc = () => {
  //   getCategory();
  // };

  
  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeServiceStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  function changeServiceStatus(Id) {
   
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/ServiceStatus?id=${Id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        getService();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "title",
      headerName: "Service",
      width: 200,
      sortable: false,
      filter: false,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      sortable: false,
      filter: false,
      cellRenderer: "imageRender",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      filter: false,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      colId: "params",
      sortable: false,
      filter: false,
      cellRenderer: "childMessageRenderer",
    },

    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Service Element
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                      setAlreadyErr("")
                    }}
                  >
                    Add New Service Element
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Service Element
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addService)}>
                            <div className="modal-body">
                              <div className="row">
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Select Category
                                  </label>
                                  <div className="position-relative">
                                    <Select
                                      options={categoryselect}
                                      onChange={(e) => handleCategory(e)}
                                    />
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div> */}
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Select Sub Category
                                  </label>
                                  <div className="position-relative">
                                    <Select
                                      isMulti
                                      options={subCategorySelect}
                                      onChange={(e) => handleSubCategory(e)}
                                    />
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div> */}
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Plan
                                  </label>
                                  <div className="position-relative">
                                    <Select
                                      options={Plan}
                                      onChange={(e) => handleChangePlan(e)}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Service Element Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      {...register("title", {
                                        onChange: () => setAlreadyErr(""),
                                        required: true, min:1,
                                      })}
                                    />
                                    {errors?.title?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                      {errors?.title?.type === "min" && (
                                      <p>Min one letter is required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Service Element Image<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      className="form-control"
                                      {...register("addimage", {
                                        required: true, 
                                      })}
                                      // onChange={(e) => {
                                      //   setimage(e.target.files[0]);
                                      // }}
                                    />
                                    {errors?.addimage?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <p style={{color:"red"}}>{alreadyErr}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                onClick={() => {
                                  canclefun()
                                  setAlreadyErr("")
                                  }}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                // onClick={addService}
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false }
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <ServiceUpdat
                      updatedData={UpdateCServiceData}
                      onEditDataFunction={getService}
                      onBox={box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this service?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                servicedeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Service Part Details{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Service Part Name : {Viewuser.title}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      <img src={Viewuser.image} width="90px" height="90px"/>{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    // moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                    // PriceNameRenderer:PriceNameRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Serviceparts;
