import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const BrandUpdate = (props) => {
    console.log(props.updatedData?.brands,"update")
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();
    
  const [brands, setBrand] = useState("");
  const [image, setImage] = useState("");
  const [disImage, setDisImage] = useState("");

  function updateBrandData(postdata) {
    
        var data = new FormData();
        data.append("brandsid", props.updatedData?._id);
                data.append(
                  "brands",  postdata.brands === "" || null ? props.updatedData?.brands  :  postdata.brands);
        data.append(
          "image",
          image === "" || null ? props.updatedData?.image : image
        );

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editbrand`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Brand
      </h5>
      <button
        type="button"
        className="btn-close"
        onClick={()=>{
          props.onBox();
        }}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateBrandData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Brand Name<span style={{color:"red"}}>*</span></label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.brands}
              className="form-control"
              {...register("brands", {
                required: true,
                min:1
              })}
            />
            {errors?.brands?.type === "required" && (
              <p>This field is required</p>
            )}
              {errors?.brands?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
      

      </div>
      <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  />
                ) : (
                  renderImages(disImage)
                )}
          </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>{props.onBox()}}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
</form>
  </>)
}

export default BrandUpdate;