import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Privacy Policy Updated.');


function PrivacyPolicy() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [policy, setPolicy] = useState("");
  const [cms, setCms] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log(subPlanHeading,"label")
  let history = useHistory();

  function PrivacyPolicyService() {

    var data = JSON.stringify({
      "CmsId": cms,
      "Privacy_Policy":content,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/UpdateCms`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "9999");
        notify();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const config = {
    readonly: false,
    height: 400,
    forced_root_block : "",
    controls: {
		font: {
			list: {
                '': 'Default',
                'Helvetica,sans-serif': 'Helvetica',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                'Times New Roman,Times,serif': 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
                'Montserrat': 'Montserrat'
			}
		}
	}
  };

  function getCms() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCms`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        
        let Privacy_Policy = response.data.data;
        let privacy = [];
        Privacy_Policy.map((item)=>{
          privacy.push(item.Privacy_Policy);
          setCms(item._id)
        })
        setPolicy(privacy[privacy.length-1]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getCms();
  }, []);

  


  return (
    <>
      <Header />
      <Sidebar />
      <Toaster />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Privacy Policy</h4>
              <form onSubmit={handleSubmit(PrivacyPolicyService)}>
                <div className="branchData">
                  <div className="row">
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label"></label>
                          {/* <input
                          value={planHeading}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setPlanHeading(e.target.value);
                          }}
                        /> */}

                          <input
                            className="form-control"
                            placeholder="Privacy Policy"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                        <JoditEditor
                                ref={editor}
                                value={policy}
                                config={config}
                                tabIndex={1}
                                onBlur={(newContent) =>setContent(newContent)}
                              />
                        </div>
                      </div>
                    </div>
                 
                   
                   
                   
                   
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn CancelBtn me-3"
                        onClick={() => {
                          history.push("./Plan");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn submitBtn me-3"
                        // onClick={PrivacyPolicyService}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
