import React, { useState } from "react";
import axios from "axios";
import "../users/user.css";
import { useForm } from "react-hook-form";

const UpdateUsermsg = (props) => {

  const {register,handleSubmit,formState:{errors},reset} =useForm();

let senderId = props?.updatedData?.senderId
let receiverId = props?.updatedData?.receiverId
let fcmToken = props?.updatedData?.senderId?.fcmtoken
  function updateUSerData(postdata) {
    var data = JSON.stringify({
      'sender_id': senderId,
      'resive_id': receiverId,
      'fcmToken':fcmToken,
      "conversation":{
        "message": postdata.message,
        "type":"resiver"
      } });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Support`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="modal-header" >
        <h5 className="modal-title" id="exampleModalLabel">
          Revert User
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(updateUSerData)}>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Revert Message </label>
            <div className="position-relative">
              <textarea
                type="text"
                style={{height:"200px"}}
                className="form-control"
                {...register("message", {
                  required: true,
                  min:1
                })}
              />
              {errors?.message?.type === "required" && (
                <p>This field is required</p>
              )}
              {errors?.message?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdateUsermsg;
