import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UpdateScout from "./UpdateScout";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "../../Components/ToggleButton";

// ---INDEX NUMBER SHOW IN TABLE FROM SRNORENDERER --//

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

// ---IMAGE  SHOW IN TABLE FROM IMAGERANDER --//

const imageRenderer = (props) => {
  console.log(props, "data");
  let image = props.data?.image;
  return (
    <span className="profle_img_box">
      {
        image ?  <img className="profile_img_table" src={image} /> : ""
      }
    </span>
  );
};

// ---STATE  SHOW IN TABLE FROM STATERANDER --//



function Scout() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateScoutData, setUpdateScoutData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [name, setName] = useState("");
  const [emails, setEmail] = useState([]);
  const [mobile, setMobile] = useState("");
  const [image, setimage] = useState("");
  const [rowData, setRowData] = useState(scoutGet);
  const [mailErrMsg, setmailErrMsg] = useState("");
  const [show, setShow] = useState(false);
  const [phnErr,setphnErr] = useState("");
  const [Image, setImage]=useState("");
  const [disabled, setDisabled] = useState(false);
console.log(mobile,"mobile")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const [isOpen, setIsOpen] = useState(false);

  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 40;

  let history = useHistory();
  let viewData = [];
  console.log(viewData, "46456");

  // ------Post API-------//

  function addScout(postdata) {
    let letter = emails.filter((item)=>{return item===postdata.email})
    let phone = mobile.filter((item)=>{return item==postdata.mobile})
    // e.preventDefault();
    if(letter.length==0){
      if(phone.length===0){
    console.log(emails,"sdaf");
    setDisabled(true);
    var data = new FormData();
    data.append("name", postdata.name);
    data.append("email", postdata.email);
    data.append("mobile", "91"+postdata.mobile);
    data.append("House", postdata.house);
    data.append("Street", postdata.street);
    data.append("State", postdata.state);
    data.append("PinCode", postdata.pincode);
    data.append("adharNo", postdata.adharNo);
    data.append("image", postdata.image[0]);
    data.append("document", image);
    data.append("type", "Scout"); 

    var config = {
      method: "post",
      // url: `${process.env.REACT_APP_BASEURL}/register`,
      url: `${process.env.REACT_APP_BASEURL}/Scout`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "add User");
        setIsOpen(false);
        scoutGet();
        setmailErrMsg("")
        setphnErr("")
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    else{
      setphnErr("Mobile No. already registered")
    }
    }else{
      setmailErrMsg("Email id already registered")
    }
  }

 

  useEffect(() => {
    scoutGet();
  }, []);

  // ------GET SCOUT API-------//

  function scoutGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/GetScout`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "getscout");
        let mail = [];
        response.data.data.map((item)=>{
         return mail.push(item.email);
        })
        let mobiles = []
        response.data.data.map((item)=>{
          return mobiles.push(item.mobile);
         })
        console.log(mail,"mail")
        console.log(mobiles,"mobiles")
        setEmail(mail)
        setMobile(mobiles)
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const editdataReloadFunc = () => {};

  // ----SCOU DELETE API---//

  function scoutDelete(id) {
    var data = JSON.stringify({
      userid: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteScout`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        scoutGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ ACTION SHOW IN TABLE -------//

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateScoutData(props.data);
            setShow(true)
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>
        <div
          className="ViewIcon"
          // onClick={() =>{console.log(props.data,"view");history.push("userView/" + props.data._id)} }
          onClick={() => {
            history.push({
              pathname: "ScoutView",
              state: { details: props.data },
            });
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const StatusRenderer = (props) => {
    return (
      <>
          <ToggleButton
            handleToggle={() => {
              changeStudentStatus(props?.data?._id);
            }}
            status={props?.data?.ScoutStatus}
          />
      </>
    );
  };
  
  function changeStudentStatus(Id) {
  
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Scoutstatus?id=${Id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
  
    axios(config)
      .then(function (response) {
        scoutGet()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function box() {
    setShow(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cross() {
    reset();
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "imageRenderer",
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      sortable: false,
      filter: false,
      cellRenderer: "nameRenderer",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      sortable: false,
      filter: false,
    },
    {
      field: "State",
      headerName: "City",
      width: 150,
      sortable: false,
      filter: false,
    },
    // {
    //   field: "active order",
    //   headerName: "Active order",
    //   width: 120,
    //   sortable: false,
    //   filter: false,
    // },
    // {
    //   field: "total order",
    //   headerName: "Total order",
    //   width: 120,
    //   colId: "params",
    //   // cellRenderer="EmailRender"
    //   sortable: true,
    //   filter: true,
    // },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 140,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      colId: "params",
      sortable:false,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Scouts
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                     setDisabled(false);
                    }}
                  >
                    Add New Scout
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Scout
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addScout)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Scout Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              /> */}
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("name", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.name?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.name?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Scout Email<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      onChange={()=>setmailErrMsg("")}
                                      {...register("email", {
                                        required: true,
                                        pattern:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />
                                    {errors?.email?.type === "pattern" && (
                                      <p>Invalid email address</p>
                                    )}
                                    {errors?.email?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <span style={{color:"red"}}>{mailErrMsg}</span>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Scout Mobile<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                      onBlur={()=>setphnErr("")}
                                      {...register("mobile", {
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: /^[1-9]\d{9}$/i,
                                      })}
                                    />
                                    {errors?.mobile?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.mobile?.type === "pattern" && (
                                      <p>Invalid Number or First Number can't be 0</p>
                                    )}
                                    {errors?.mobile?.type === "minLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                    {errors?.mobile?.type === "maxLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                    <span style={{color:"red"}}>{phnErr}</span>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">House No. and Floor<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={street}
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="text"
                                      {...register("house", {
                                        required: true,
                                        pattern: /^[0-9A-Za-z ]+$/i,
                                        min:1
                                      })}
                                    />
                                    {errors?.house?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.house?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Street<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={street}
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="text"
                                      {...register("street", {
                                        required: true,
                                        pattern: /^[0-9A-Za-z ]+$/i,
                                        min:1
                                      })}
                                    />
                                    {errors?.street?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.street?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label"> City<span style={{color:"red"}}>*</span></label>
                                  {/* <input
                              value={state}
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            /> */}
                                  <input
                                    className="form-control"
                                    type="text"
                                    {...register("state", {
                                      required: true,
                                      min:1
                                    })}
                                  />
                                  {errors?.state?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                  {errors?.state?.type === "required" && (
                                    <p>This field is required</p>
                                  )}{" "}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Pin Code<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={pincode}
                                onChange={(e) => {
                                  setPincode(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                      {...register("pincode", {
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                      })}
                                    />
                                    {errors?.pincode?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.pincode?.type === "maxLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                    {errors?.pincode?.type === "minLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Scout Aadhaar Card No.
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                      {...register("adharNo", {
                                        minLength: 12,
                                        maxLength: 12,
                                        pattern: /^[0-9]{12}$/i,
                                      })}
                                    />
                                    {errors?.adharNo?.type === "pattern" && (
                                      <p>Invalid Character</p>
                                    )}
                                    {errors?.adharNo?.type === "minLength" && (
                                      <p>Only 12 digit number</p>
                                    )}
                                    {errors?.adharNo?.type === "maxLength" && (
                                      <p>Only 12 digit number</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                   Upload Documents
                                  </label>
                                  <div className="position-relative">
                                    <div>
                                    <input
                                        type="file"
                                        name="upload" 
                                        accept=".xlsx,.xls, .doc, .docx,.pdf"
                                        onChange={(e) =>
                                          setimage(e.target.files[0])
                                        }
                                        className="form-control"
                                   
                                      />

                                    </div>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Scout Image<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <div>
                                      <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        {...register("image", {
                                          required: true,
                                        })}
                                        onChange={(e) =>
                                          setImage(e.target.files[0])
                                        }
                                        className="form-control"
                                      />
                                      {errors?.image?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    </div>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  onClick={() => canclefun()}
                                  type="button"
                                  className="btn btn-danger CancelBtn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  // onClick={addScout}
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled = {disabled ? true : false}
                                  // data-bs-dismiss="modal"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} onHide>
              <Modal.Body>
              <div
               
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateScout
                      updatedData={UpdateScoutData}
                      onEditDataFunction={scoutGet}
                      box = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Scout?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                scoutDelete(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Scout Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> User Name : {Viewuser.name}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6> User Email : {Viewuser.email}</h6>
                                  </div>

                                  <div>
                                    {" "}
                                    <h6> User Mobile : {Viewuser.mobile}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body border">
                              <div>
                                <ul className="nav nav-pills" role="tablist">
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#home"
                                      role="tab"
                                    >
                                      {/* <span>Users Details</span> */}
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content pt-3">
                                  <div
                                    className="tab-pane active"
                                    id="home"
                                    role="tabpanel"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    '<span style="padding: 10px; border: 2px solid #444;">no rows to show </span>'
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRenderer: imageRenderer,

                    // statusRenderer: StatusRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Scout;
