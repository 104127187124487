import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateScoutmsg from "./UpdateScoutmsg";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal'

// ---INDEX NUMBER SHOW IN TABLE FROM SRNORENDERER --//

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const NameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.userid?.name}</span>
    </>
  );
};
const UserNameRenderer = (props) => {
  console.log(props.data?.senderId?.name, "sda")
  return (
    <>
      <span>{props.data?.senderId?.name}</span>
    </>
  );
};
const UserRenderer = (props) => {

  return (
    <>
      <span>{props.data?.msg}</span>
    </>
  );
};

// ---COMPONENT NAME --//

function Scoutmsg() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateStudentData, setUpdateCategoriesData] = useState({});
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [rowData, setRowData] = useState(getuserdata);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const {
    formState: { errors },
    reset,
  } = useForm();

  // console.log(search,"search")
  const gridRef = useRef();
  const pagination = true;
  const paginationPageSize = 20;
  const rowHeight = 55;

  let history = useHistory();
  let viewData = [];

  function box() {
    setShow(false);
    reset();
  }

  // ------ADD USER API-------//

  function addusers(postdata) {
    var data = new FormData();
    data.append("type", "customer");
    data.append("sender_id", "62a4578c03d8881923087a52");
    data.append("resive_id", "626259f89d1ec3425360af89");
    data.append("message", postdata.message);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Support`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getuserdata();
        setIsOpen(false);
        reset();
        resetForm();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  // function crossForm() {
  //   setIsOpen(false);
  //   cross();
  // }

  function cross() {
    reset();
  }
  const resetForm = () => {
    setMessage("");
  };
  useEffect(() => {
    getuserdata();
    getviewUser();
  }, []);

  // ------GET USER API-------//

  function getuserdata() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSupport`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "666");
        let message = response.data?.data;
        console.log(message, "message")

        let finalMessage = message.filter((e) => e?.senderid?.type == "Scout")
        let customerBasedData = finalMessage.map((e)=>{
          let arr = e?.conversation, sendermsg = [];

            arr.forEach((e) => {
              if (e.type === "sender") {
                sendermsg.push(e) 
              }
            })
            console.log(sendermsg, "sendermsg")
            let senderId = e?.senderid
            let receiverId = e?.resiveid
            let msg = sendermsg[sendermsg.length - 1]?.message ? sendermsg[sendermsg.length - 1]?.message : ''
            return { msg, senderId, receiverId, data: e };
        })
        console.log(customerBasedData,"finalMessage")
        setRowData(customerBasedData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  console.log(rowData, "shjf")
  // ------ USER VIEW API-------//

  function getviewUser() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSupport`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "view");
        for (var x in response.data.data) {
          viewData.push(response.data.data[x]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ Delete API -------//

  function userdeleteData(index) {
    var data = JSON.stringify({
      userId: index,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteuser`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getuserdata();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ ACTION SHOW IN TABLE -------//

  const ChildMessageRenderer = (props) => {
    console.log(props, "uuuuuu");

    return (
      <div className="iconActionList">
        {/* <div
          className="editIcon"
          onClick={() => {
            console.log(props.data, "uuuuuu");
            setUpdateCategoriesData(props.data);
            setShow(true)
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div> */}
        <button
          className="btn btn-primary"
          onClick={() => {
            console.log(props?.data?.senderId?._id, "ViewIconViewIcon");
            history.push({
              pathname: `message/${props?.data?.senderId?._id}`,
              state: { details: props?.data?.data, detailsone: viewData },
            });
          }}
        >
          Reply
        </button>
      </div>
    );
  };


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      sortable: false,
      cellRenderer: "srNoRenderer",
    },
    // {
    //   field: "name",
    //   headerName: "Name",
    //   width: 140,
    //   sortable: true,
    //   ref: gridRef,
    //   cacheQuickFilter: true,
    //   cellRenderer: "nameRenderer",
    // },
    {
      field: "scoutname",
      headerName: "Scout Name",
      sortable: false,
      width: 180,
      cellRenderer: "userNameRenderer",
      getQuickFilterText: params => {
        return params.data?.senderId?.name;
    }
    },
    {
      field: "Message",
      headerName: "Message",
      sortable: false,
      width: 180,
      cellRenderer: "userRenderer",
      getQuickFilterText: params => {
        return params.data?.msg;
    }
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      sortable: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Scout
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
              </h4>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                <UpdateScoutmsg
                updatedData={UpdateStudentData}
                onEditDataFunction={getuserdata}
                onBox = {box}
              />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Scout?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                userdeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  domLayout="autoHeight"
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    nameRenderer: NameRenderer,
                    userNameRenderer: UserNameRenderer,
                    userRenderer: UserRenderer,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Scoutmsg;
