import React, { useEffect, useState } from "react";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import "./profile.css";
import { BsCameraFill } from "react-icons/bs";
import axios from "axios";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';

function Profile() {
  const notify = () => toast('Profile Updated Successfully.');
  const notifyPassword = () => toast('Password Updated Successfully.');
  let localdata = JSON.parse(sessionStorage.getItem("userData"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register:sign,
    handleSubmit : fill,
    formState: { errors : problems },
    reset : clear,
  } = useForm();

  const [image, setImage] = useState(localdata?.image);
  const [file, setFile] = useState(localdata?.image);
  const [err, setErr] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState();
  const [pic, setPic] = useState(localdata?.image);
  const [adminId,setAdminId] = useState("");
  const [pass, setPass] = useState({
    oldPass : "",
    newPass: ""
  })

  console.log(image)
  function updateProfile(postdata) {
    console.log("hit")
    let formData = new FormData();
    formData.append("name", postdata.fname);
    formData.append("mobile", postdata.mobile);
    formData.append("image", file);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/AdminUpdateProfile`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
        
      },
      data: formData,
    };
    axios(config)
        .then(function (response) {
            console.log(response);
            notify();
            sessionStorage.setItem("userData", JSON.stringify(response.data.data))
        })
        .catch(function (error) {
            console.log(error);
        });
}

function changePassword(postdata) {
 if(postdata.cnewpassword !== postdata.newpassword){
  setErr("New Password and Confirm Password does not matching");
 }else{
  setErr("");
  var data = JSON.stringify({
    "Admin_id": adminId,
    "oldPassword": postdata.password,
    "newPassword": postdata.newpassword,
});
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BASEURL}/ChangePassword`,
    headers: {
      Authorization: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios(config)
      .then(function (response) {
          console.log(response.data.message);
          toast(response.data.message);
          clear();
      })
      .catch(function (error) {
          console.log(error);
      });
 }
 
}

useEffect(()=>{
  getProfile();
},[])


function getProfile(index) {
    
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASEURL}/GetProfile`,
    headers: {
      Authorization: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  axios(config)
    .then(function (response) {
      console.log(response.data.data, "view");
      let data = response.data.data;
      setName(data?.username)
      setEmail(data?.email)
      setNum(data?.mobile)
      // setPic(data?.image);
      setAdminId(data?._id)
    })
    .catch(function (error) {
      console.log(error);
    });
}

  return (
    <>
      <Header />
      <Sidebar />
      <Toaster />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body main_profile">
              <h4 className="card-title">Profile</h4>
              <div className="row">
                <div className="col-lg-4 col-xlg-3 col-md-5">
                  <div className="card profile_card">
                    <div className="card-body">
                      <center className="mt-4">
                        <div className="profile_img">
                          <img
                            src={image ? image : pic}
                            className="rounded-circle"
                            alt="profile"
                          />
                          <div className="camera">
                            <BsCameraFill />
                            <input type='file' 
                            accept="image/png, image/gif, image/jpeg"
                            className="form-control"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                              const fileList = e.target.files;
                               if (!fileList) return;
                              let display = URL.createObjectURL(fileList[0]);
                               setImage(display);
                            }}
                          />
                            
                          </div>
                        </div>
                        <h4 className="card-title mt-2">
                          {name}
                        </h4>
                        {/* <h6 className="card-subtitle">
                          {email}
                        </h6> */}
                      </center>
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div class="card-body">
                      <small className="text-muted">Email address </small>
                      <h6>{email}</h6>
                      <small className="text-muted pt-4 db">Phone</small>
                      <h6>{num}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-xlg-9 col-md-7">
                  <div className="card">
                    <ul
                      className="nav nav-pills custom-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-setting-tab"
                          data-bs-toggle="pill"
                          href="#previous-month"
                          role="tab"
                          aria-controls="pills-setting"
                          aria-selected="false"
                        >
                          Update Profile
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="#change-password-tab"
                          data-bs-toggle="pill"
                          href="#change-password-tab"
                          role="tab"
                          aria-controls="change-password-tab"
                          aria-selected="false"
                        >
                          Change Password
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane show active"
                        id="previous-month"
                        role="tabpanel"
                      >
                        <div className="card-body">
                          <form className="form-horizontal form-material" onSubmit={handleSubmit(updateProfile)}>
                            <div className="mb-3">
                              <label className="col-md-12">Full Name</label>
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  defaultValue={localdata?.username}
                                  placeholder="Full Name"
                                  className="form-control form-control-line"
                                  {...register("fname", {
                                    required: true, min:1,
                                  })}
                                />
                                {errors?.fname?.type === "required" && (
                                  <p>This field is required</p>
                                )}
                                  {errors?.fname?.type === "min" && (
                                  <p>Min one letter is required</p>
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="example-email" className="col-md-12">
                                Email
                              </label>
                              <div className="col-md-12">
                                <input
                                  type="email"
                                  defaultValue={email}
                                  placeholder="Email"
                                  className="form-control form-control-line"
                                  name="example-email"
                                  id="example-email"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="col-md-12">Phone Number</label>
                              <div className="col-md-12">
                                <input
                                className="form-control"
                                defaultValue={localdata?.mobile}
                                type="number"
                                min={1}
                                onWheel={(e) => e.target.blur()}
                                {...register("mobile", {
                                  required: true,
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: /^[1-9]\d{9}$/i,
                                })}
                              />
                              {errors?.mobile?.type === "required" && (
                                <p>This field is required</p>
                              )}
                              {errors?.mobile?.type === "pattern" && (
                                <p>Invalid Number or First Number can't be 0</p>
                              )}
                              {errors?.mobile?.type === "maxLength" && (
                                <p>Only 10 digit number</p>
                              )}
                              {errors?.mobile?.type === "minLength" && (
                                <p>Only 10 digit number</p>
                              )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="col-sm-12">
                                <button type="submit" className="btn btn-success btn_submit">
                                  Update Profile
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="change-password-tab"
                        role="tabpanel"
                        aria-labelledby="change-password-tab"
                      >
                        <div className="card-body">
                          <form className="form-horizontal form-material" onSubmit={fill(changePassword)}>
                            <div className="mb-3">
                              <label className="col-md-12">Old Password</label>
                              <div className="col-md-12">
                                <input
                                  type="password"
                                  placeholder="**********"
                                  className="form-control form-control-line"
                                  {...sign("password", {
                                    required: true,
                                  })}
                                />
                                {problems?.password?.type === "required" && (
                                  <p>This field is required</p>
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="col-md-12">New Password</label>
                              <div className="col-md-12">
                                <input
                                  type="password"
                                  placeholder="**********"
                                  className="form-control form-control-line"
                                  {...sign("newpassword", {
                                    required: true, 
                                    minLength: 8,
                                  })}
                                />
                                {problems?.newpassword?.type === "required" && (
                                  <p>This field is required</p>
                                )}
                                  {problems?.newpassword?.type === "minLength" && (
                                  <p>Min 8 letter is required</p>
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="col-md-12">
                                Confirm Password
                              </label>
                              <div className="col-md-12">
                                <input
                                  type="password"
                                  placeholder="**********"
                                  className="form-control form-control-line"
                                  {...sign("cnewpassword", {
                                    required: true, 
                                  })}
                                />
                                {problems?.cnewpassword?.type === "required" && (
                                  <p>This field is required</p>
                                )}
                              </div>
                              <span style={{color:"red"}}>{err}</span>
                            </div>
                            <div className="mb-3">
                              <div class="col-sm-12">
                                <button type="submit" className="btn btn-success btn_submit">
                                  Change Password
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
