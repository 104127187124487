
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
// import "./Category.css";
import Footer from "../../shared/Footer/footer";

const UpdateInspection = (props) => {
  console.log(props,"props")
  const [PreInspection, setGeneralinformation] = useState("");
  const [R, setCheckR] = useState(props.updatedData?.R);
  const [W, setCheckW] = useState(props.updatedData?.W);
  const [M, setCheckM] = useState(props.updatedData?.M);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  function UpdateInspectionData(postdata) {

    var data = JSON.stringify({
      'id': props.updatedData?._id,
      'Generalinformation': postdata.Generalinformation === '' || null ? props.updatedData?.Generalinformation : postdata.Generalinformation,
      'R': R,
      'W': W,
      'M': M,
     


     });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/EditGeneralinformation`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox()
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //---PLAN GET API--//



  const handleCheckboxR= event =>{
    
    if(event.target.checked == true){
      setCheckR(event.target.value,"8888")
      }else if(event.target.checked == false){
        setCheckR("")
      }
}

const handleCheckboxW= event =>{
  if(event.target.checked == true){
    setCheckW(event.target.value,"8888")
    }else if(event.target.checked == false){
      setCheckW("")
    }
   
}

const handleCheckboxM= event =>{
    console.log(event,"8888") 
    if(event.target.checked == true){
      setCheckM(event.target.value,"8888")
      }else if(event.target.checked == false){
        setCheckM("")
      }
}

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
        Edit PreInspection
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form
      onSubmit={handleSubmit(UpdateInspectionData)}
    >
      <div className="modal-body">
        <div className="row">
      

        

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit PreInspection<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.Generalinformation}
                className="form-control"
                {...register("Generalinformation", {
                  required: true,
                  min:1
                })}
              />
              {errors?.Generalinformation?.type ===
                "required" && (
                <p>This field is required</p>
              )}
              {errors?.Generalinformation?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="row">
                        
                     
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select check box</label>
                          <div className="position-relative">

                          <div className="form-check form-check-inline">
                              <input
                                 defaultChecked={props.updatedData?.R}
                              onChange={handleCheckboxR} value="R" className="form-check-input" type="checkbox" id="inlineCheckbox1"  />
                              <label className="form-check-label" for="inlineCheckbox1">R</label>
                              </div>
                              <div class="form-check form-check-inline">
                              <input 
                                 defaultChecked={props.updatedData?.W}

                              onChange={handleCheckboxW} value="W"
                            //   onChange={(event) => handleCheckboxW(event)}
                              className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                              <label className="form-check-label" for="inlineCheckbox2">W</label>
                              </div>
                              <div class="form-check form-check-inline">
                              <input 
                                 defaultChecked={props.updatedData?.M}

                              onChange={handleCheckboxM}  value="M"  className="form-check-input" type="checkbox" id="inlineCheckbox3"  />
                              <label className="form-check-label" for="inlineCheckbox2">M</label>
                              </div>
      
                            
                          </div>
                        </div>
                       
                      </div>

     
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdateInspection;



