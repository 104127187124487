import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const UpdateJobCard = (props) => {
  console.log(props,"props")
  const [text, settext] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  function updateJob(postdata) {

    var data = JSON.stringify({
      'jobcardid': props.updatedData?._id,
      'text': postdata.text === '' || null ? props.updatedData?.text : postdata.text,
 
     });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editJobcard`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //---PLAN GET API--//





  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Job Card
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(updateJob)}>
      <div className="modal-body">
        <div className="row">
      

        

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit  Job Card<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.text}
                className="form-control"
                {...register("text", {
                  required: true,
                  min:1
                })}
              />
              {errors?.text?.type ===
                "required" && (
                <p>This field is required</p>
              )}
              {errors?.text?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

     
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdateJobCard;
