import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const FuelUpdate = (props) => {
    console.log(props.updatedData?.brands,"update")

    
  const [fuel, setFuel] = useState("");
  const [image, setImage] = useState("");
  const [disImage, setDisImage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  function updateFuel(postdata) {
    
        var data = new FormData();
        data.append("fuelid", props.updatedData?._id);
                data.append(
                  "fuel",  postdata.fuel === "" || null ? props.updatedData?.fuel  :  postdata.fuel);
        data.append(
          "image",
          image === "" || null ? props.updatedData?.image : image
        );

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editfuel`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit fuel
      </h5>
      <button
        type="button"
        className="btn-close"
       onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateFuel)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit fuel<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.fuel}
              className="form-control"
              {...register("fuel", {
                required: true,
                min:1
              })}
            />
            {errors?.fuel?.type === "required" && (
              <p>This field is required</p>
            )}
              {errors?.fuel?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
      

      <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  props.updatedData?.image ?
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  /> : ""
                ) : (
                  renderImages(disImage)
                )}
          </div>
      </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
</form>
  </>)
}

export default FuelUpdate;