import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import "./UserRoleManagement.css";
import Select from "react-select";
import { modules } from './Roles'

const UpdateUserRoleManagement = forwardRef((props, ref) => {
    console.log(props.updatedData, "UpdateUserRoleManagement")
    const updatedroles = props?.updatedData?.roles?.map((item) => {
        const mapdata = []
        modules.forEach((module, index) => {
            if (item === module.value) {
                mapdata.push(module);
            }
        })
        return mapdata
    })
    const flattened = updatedroles?.flatMap(num => num);
    console.log(flattened, "updatedroles");
    const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
    const [data, setdata] = useState(modules);
    const [roleErrorStatus, setRoleErrorStatus] = useState(null)
    useEffect(() => {
        updateStudentData(); //children function of update roles
    }, [props.refresh]);

    useEffect(() => {
        if (selectedOptiongroup.length > 0) {
            setRoleErrorStatus(false)
        }
    }, [selectedOptiongroup])

    function updateStudentData() {
        console.log(props, "props?.updatedData?.roles")
        if (props.refresh != 0) {

            if (props?.updatedData?.roles.length <= 0 && selectedOptiongroup.length === 0) {
                setRoleErrorStatus(true)
                return;
            }

            var data = JSON.stringify({
                adminId: props.updatedData?._id,
                roles: selectedOptiongroup.length === 0 ? props?.updatedData?.roles.join(",") : selectedOptiongroup.join(","),
            });
            console.log(data);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/editadmin`,
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    props.onEditDataFunction();
                    props.handleClose();
                    setRoleErrorStatus(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const handlechange = (event) => {
        console.log(event, "handlechange")
        if (event.length > 0) {
            setRoleErrorStatus(false)
        } else {
            setRoleErrorStatus(true)
        }
        let array = [];
        // let arrayLabel = [];
        event.forEach((option) => {
            array.push(option.value);
        });
        // event.forEach((option) => {
        //     arrayLabel.push(option.label);
        // })
        setselectedOptiongroup(array);
        // setSelectedOptionGroupLabel(arrayLabel);
    };



    return (<>

        <div className="row">
            <div className="col-md-12 mb-3">
                <label className="form-label">Edit Admin User Name </label>
                <div className="position-relative">
                    <input
                        disabled
                        type="text"
                        defaultValue={props.updatedData?.username}
                        key={props.updatedData?.username}
                        className="form-control"
                    />
                    <div
                        className="hint_box"
                        style={{ display: "block" }}
                    ></div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 mb-3">
                <label className="form-label">Edit Email Address </label>
                <div className="position-relative">
                    <input
                        disabled
                        type="text"
                        defaultValue={props.updatedData?.email}
                        key={props.updatedData?.email}
                        className="form-control"
                    />
                    <div
                        className="hint_box"
                        style={{ display: "block" }}
                    ></div>
                </div>
            </div>
        </div>
        {props.updatedData.type !== 'admin' && <div className="row">
            <div className="col-md-12 mb-3">
                <label className="form-label">Edit Admin Roles</label>
                <div className="position-relative">
                    <Select
                        isMulti
                        defaultValue={flattened}
                        key={flattened?.map((item, index) => {
                            return index;
                        })}
                        options={data}
                        onChange={(e) => handlechange(e)}
                    />
                    {roleErrorStatus && <p class="error">This field is required</p>}
                </div>
            </div>
        </div>}


        {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
                
            </h5>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={reset}
            ></button>
        </div>
        <div className="modal-body">
            

        </div>
        <div className="modal-footer">
            <button type="button" onClick={() => { props.onEditDataFunction() }} className="btn  CancelBtn"
                data-bs-dismiss="modal"
                aria-label="Close" >Cancel</button>
            <button
                type="submit"
                onClick={() => {
                    updateStudentData()
                }}
                className="btn submitBtn" data-bs-dismiss="modal" >
                Submit
            </button>
        </div> */}

    </>)
})

export default UpdateUserRoleManagement;