import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import "./MasterPlanUpdate.css";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

// import "./Category.css";
import Footer from "../Footer/footer";

const PlanUpdate = (props) => {
  let location = useLocation();
  console.log(location.state.details, "111111");
  console.log(props, "props");
  const [subCatChang, setSubCatChang] = useState([]);
  const [seubCatId, setSubCatId] = useState([]);
  const [catId, setCaeId] = useState("");
  const [CatSelect, setcatChang] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [services_id, setServiceId] = useState([]);
  const [label, setLabel] = useState("");
  const [planHeading, setPlanHeading] = useState("");
  const [subPlanHeading, setSubPlanHeading] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [time, setTime] = useState("");
  const [month, setMonth] = useState("");
  const [planPrice, setplanPrice] = useState("");
  const [planHeadingPrice, setplanHeadingPrice] = useState("");
  const [subPlanHeadingPrice, setSubPlanHeadingPrice] = useState("");
  const [subPlanPrice, setSubPlanPrice] = useState("");
  const [headingDes, setHeadingDes] = useState("");
  const [textField, setTextField] = useState("");
  const [carData, setCarData] = useState([]);
  const [carId, setCarId] = useState("");
  const [fuel, setFuel] = useState([]);
  const [fuelId, setFuelId] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [image, setImage] = useState("");
  const [SubPlantypename, setSubPlantypename] = useState("");
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [brand, setBrand] = useState("");
  const [brandId, setBrandId] = useState("");
  const [document, setDocument] = useState("");
  const [carValue, setCarValue] = useState();
  const [subCatValue, setSubCatValue] = useState({
    label: location.state.details?.Subcategory?.title,
    value: location.state.details?.Subcategory?._id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // category default value
  let catarr = [];
  catarr.push(location.state.details?.Category);

  //subcategory default value
  let subcatarr = [];
  subcatarr.push(location.state.details?.Subcategory);

  useEffect(() => {
    subcatarr.map((item) => {
      console.log(item, "565");
      return { value: item?._id, label: item?.title };
    });
  }, [subcatarr]);

  let history = useHistory();

  let brandvalur = [];

  if (location?.state?.details?.brand) {
    const propertyNames = Object.values(
      location?.state?.details?.brand?.brands
    );
    console.log(propertyNames, "brandvalue");
    brandvalur?.push(propertyNames);
  }

  let carvalur = [];

  if (location?.state?.details?.cars) {
    const propertyNames = Object.values(location?.state?.details?.cars);
    console.log(propertyNames, "propertyNames");
    carvalur?.push(propertyNames);
  }
  let fuelvalue = [];

  if (location?.state?.details?.fuel) {
    const property = Object.values(location?.state?.details?.fuel);
    console.log(property, "property");
    fuelvalue?.push(property);
  }

  function updatePlan(postdata) {
    // let typeprice = [{price:planPrice, typename:planHeadingPrice}]
    // let servicepackprice = [{price:subPlanPrice, servicename:subPlanHeadingPrice}]
    // let timming =[{hours:time, month:month}]
    let lastplanName =
      location.state.details?.plan[0]?.planName +
      "," +
      location.state.details?.plan[1]?.planName;
    let lastsubName =
      location.state.details?.plan[0]?.subPlanName +
      "," +
      location.state.details?.plan[1]?.subPlanName;
    let lastprice =
      location.state.details?.plan[0]?.typeprice +
      "," +
      location.state.details?.plan[1]?.typeprice;
    let lastheadingprice =
      location.state.details?.plan[0].typename +
      "," +
      location.state.details?.plan[1]?.typename;

    let thisplanName = postdata.planHeading + "," + postdata.subPlanHeading;
    let thissubName = postdata.subPlanName + "," + postdata.subPlanPrice;
    let thisprice = postdata.planPrice + "," + postdata.subplanPrice;
    let thisheadingprice =
      postdata.planHeadingPrice + "," + postdata.subplanHeadingPrice;

    let formData = new FormData();
    formData.append("id", location.state.details?._id);
    formData.append(
      "Category",
      catId.toString() === "" || null
        ? catarr.map((item) => {
            return item._id;
          })
        : catId
    );
    formData.append(
      "Subcategory",
      seubCatId.toString() === "" || null
        ? subcatarr.map((item) => {
            return item?._id;
          })
        : seubCatId
    );
    formData.append(
      "services_id",
      services_id.toString() === "" || null
        ? location.state.details?.services_id?.map((item) => {
            return item._id;
          })
        : services_id
    );
    formData.append("citys", location.state.details?.citys?._id);
    formData.append("brands", location.state.details?.brands?._id);
    formData.append("car", location.state.details?.carsid?._id);
    formData.append(
      "fuel",
      location.state.details?.fuel?.map((item) => {
        return item._id;
      })
    );
    formData.append(
      "label",
      postdata.label === "" || null
        ? location.state.details?.label
        : postdata.label
    );
    formData.append(
      "planName",
      thisplanName === "" || null ? lastplanName : thisplanName
    );
    formData.append(
      "subPlanName",
      planDescription === "" || null ? lastsubName : planDescription
    );
    formData.append(
      "servicepack",
      subPlanHeading === "" || null
        ? location.state.details?.plan[1].planName
        : subPlanHeading
    );
    formData.append(
      "description",
      postdata.shortDescription === "" || null
        ? location.state.details?.description
        : postdata.shortDescription
    );
    formData.append(
      "hours",
      postdata.time === "" || null
        ? location.state.details?.hours
        : postdata.time
    );
    formData.append(
      "month",
      postdata.month === "" || null
        ? location.state.details?.month
        : postdata.month
    );
    formData.append(
      "typeprice",
      thisprice === "" || null ? lastprice : thisprice
    );
    formData.append(
      "typename",
      thisheadingprice === "" || null ? lastheadingprice : thisheadingprice
    );
    formData.append(
      "servicename",
      postdata.subPlanHeadingPrice === "" || null
        ? location.state.details?.servicename
        : postdata.subPlanHeadingPrice
    );
    // formData.append(
    //   "servicepackprice",
    //   subPlanPrice === "" || null
    //     ? location.state.details?.servicepackprice
    //     : subPlanPrice
    // );
    formData.append(
      "heading",
      postdata.headingDes === "" || null
        ? location.state.details?.heading
        : postdata.headingDes
    );
    formData.append(
      "textField",
      postdata.textField === "" || null
        ? location.state.details?.textField
        : postdata.textField
    );
    formData.append("image", image === "" || null ? "" : image);
    formData.append("imageData", image ? "" : location.state.details?.image);
    formData.append("document", document === "" || null ? "" : document);
    formData.append(
      "docData",
      document ? "" : location.state.details?.document
    );

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/ApprovelPLanUpdate`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        history.push("/plan");
        props.onEditDataFunction();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getCategory();
    getService();
    fuelGet();
    getCity();
    brandGet();
  }, []);

  function getCategory() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "catget");
        let arrCat = [];
        response.data.getdata.map((catName) => {
          arrCat.push({ label: catName.title, value: catName._id });
        });

        setcatChang(arrCat);
        // setCategorySelect(response.data.getdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCity = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    console.log(e.value, "element");
    setCityId(e.value);
  };

  const handleChangeBrand = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    console.log(e.value, "element");
    setBrandId(e.value);
    getCars(e.value);
  };

  const handleChangeCategory = (e) => {
    setSubCatValue({ value: "", label: "" });
    setSubCatId("");
    console.log(e.value, "element");
    setCaeId(e.value);
    subCategoryGet(e.value);
  };
  function subCategoryGet(id) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/subcategory?categoryid=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "subcategory");
        let arrSubCat = [];
        response.data.subcategory.map((catSubName) => {
          arrSubCat.push({ label: catSubName.title, value: catSubName._id });
        });
        setSubCatChang(arrSubCat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeSubCategory = (e) => {
    // let subarrSelect = [];
    // e.forEach((item) => {
    //   subarrSelect.push(item.value);
    // });
    setSubCatValue(e);
    setSubCatId(e.value);
  };

  // ------GET SERVICE API-------//

  function getService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getservice`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "GERSERVICE");
        let arrServicePart = [];
        response.data.data.map((item) => {
          if(item.status === true)
          arrServicePart.push({ label: item.title, value: item._id });
        });

        setServiceData(arrServicePart);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleChangeService = (e) => {
    let serviceArrSelect = [];
    e.forEach((item) => {
      serviceArrSelect.push(item.value);
    });
    setServiceId(serviceArrSelect);
    console.log(serviceArrSelect, "aaaaa");
    //  setServiceId(e.value)
  };

  function brandGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBrand`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "brand");

        let brand = response.data.data;
        console.log(brand, "brands");
        let arrBrand = [];
        response.data.data.map((brandName) => {
          arrBrand.push({ label: brandName.brands, value: brandName._id });
        });
        setBrand(arrBrand);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "city");
        let city = response.data.data;
        console.log(city, "cities");
        let arrCity = [];
        response.data.data.map((catName) => {
          arrCity.push({ label: catName.city, value: catName._id });
        });
        setCity(arrCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function fuelGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/Getfuel`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "brand");

        let arrFuel = [];
        response.data.data.map((item) => {
          // console.log(item)
          arrFuel.push({ label: item.fuel, value: item._id });
        });
        console.log(arrFuel, "arrfuel");
        setFuel(arrFuel);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCars(id) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getbrandcar?brandid=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "carget");
        let arrcar = [];
        response.data.data.map((item, index) => {
          console.log(item.cars, "item");
          arrcar.push({ label: item.cars, value: item._id });
        });
        //  console.log(arrcar,"arrcar")
        setCarData(arrcar);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCar = (e) => {
    setCarId(e.value);
  };
  const handleFuel = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    let fuelSelect = [];
    e.forEach((value) => {
      fuelSelect.push(value.value);
    });
    setFuelId(fuelSelect);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="row">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Plan
              </h5>

              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <form onSubmit={handleSubmit(updatePlan)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Service Pack Name<span style={{ color: "red" }}>
                        *
                      </span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        defaultValue={location.state.details?.plan[0].planName}
                        className="form-control"
                        {...register("planHeading", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.planHeading?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.planHeading?.type === "min" && (
                        <p>Min one letter is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Pack Name 2nd Part<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="position-relative">
                      <textarea
                        defaultValue={location.state.details?.plan[1]?.planName}
                        className="form-control"
                        {...register("subPlanHeading", {
                          required: true,
                          min: 1,
                        })}
                      />

                      {errors?.subPlanHeading?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.subPlanHeading?.type === "min" && (
                        <p>Minimum one character is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Description<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        defaultValue={
                          location.state.details?.plan[0].subPlanName
                        }
                        className="form-control"
                        {...register("subPlanName", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.subPlanName?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.subPlanName?.type === "min" && (
                        <p>Min one letter is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Full Description<span style={{ color: "red" }}>
                        *
                      </span>{" "}
                    </label>
                    <div className="position-relative">
                      <textarea
                        style={{ height: "100px" }}
                        defaultValue={location.state.details?.description}
                        className="form-control"
                        {...register("shortDescription", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.shortDescription?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.shortDescription?.type === "min" && (
                        <p>Min one letter is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      City<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        defaultValue={location.state.details?.citys?.city}
                        readOnly
                        className="form-control"
                        classNamePrefix="select"
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Brand<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        defaultValue={location.state.details?.brands?.brands}
                        readOnly
                        className="form-control"
                        classNamePrefix="select"
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Car<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <input
                      defaultValue={location.state.details?.car?.cars}
                      readOnly
                      className="form-control"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Fuel<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <input
                      defaultValue={location.state.details?.fuel?.map(
                        (item) => {
                          console.log(item, "565");
                          return item.fuel;
                        }
                      )}
                      key={location.state.details?.fuel?.map((item) => {
                        return item._id;
                      })}
                      readOnly
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Main Category<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <Select
                        defaultValue={catarr.map((item) => {
                          console.log(item, "565");
                          return { value: item?._id, label: item?.title };
                        })}
                        key={catarr.map((item) => {
                          return item?._id;
                        })}
                        options={CatSelect}
                        onChange={(e) => {
                          handleChangeCategory(e);
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Sub-Category<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="position-relative">
                      <Select
                        value={subCatValue}
                        key={subcatarr.map((item) => {
                          return item?._id;
                        })}
                        options={subCatChang}
                        // onChange={(e) => handleChangePlan(e)}

                        onChange={(e) => {
                          // e.persist = () => {};
                          handleChangeSubCategory(e);
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Service Elements<span style={{ color: "red" }}>
                        *
                      </span>{" "}
                    </label>
                    <Select
                      defaultValue={location.state.details?.services_id?.map(
                        (item) => {
                          console.log(item, "55");
                          return { value: item?._id, label: item?.title };
                        }
                      )}
                      key={location.state.details?.services_id?.map((item) => {
                        return item?._id;
                      })}
                      isMulti
                      options={serviceData}
                      onChange={(e) => handleChangeService(e)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Service Pack Highlight
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        defaultValue={location.state.details?.label}
                        className="form-control"
                        {...register("label")}
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Price 1<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        defaultValue={location.state.details?.plan[0].typeprice}
                        className="form-control"
                        {...register("planPrice", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.planPrice?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.planPrice?.type === "min" && (
                        <p>Minimum one number is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label"> Price 1 Description </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        defaultValue={location.state.details?.plan[0].typename}
                        className="form-control"
                        {...register("planHeadingPrice", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.planHeadingPrice?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.planHeadingPrice?.type === "min" && (
                        <p>Minimum one number is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 mb-3">
                  <label className="form-label">
                     Plan Sub Heading Price
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      defaultValue={location.state.details?.servicename}
                      className="form-control"
                      onChange={(e) => {
                        setSubPlanHeadingPrice(e.target.value);
                      }}
                    />
                    <div
                      className="hint_box"
                      style={{ display: "block" }}
                    ></div>
                  </div>
                </div> */}

                  <div className="col-md-6 mb-3">
                    <label className="form-label"> Price 2</label>
                    <div className="position-relative">
                      <input
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        defaultValue={
                          location.state.details?.plan[1]?.typeprice
                        }
                        className="form-control"
                        {...register("subplanPrice")}
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label"> Price 2 Description</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        min={1}
                        defaultValue={location.state.details?.plan[1]?.typename}
                        className="form-control"
                        {...register("subplanHeadingPrice")}
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Time Required<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        defaultValue={location.state.details?.hours}
                        className="form-control"
                        {...register("time", {
                          required: true,
                        })}
                      />
                      {errors?.time?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label"> Frequency (Months) </label>
                    <div className="position-relative">
                      <input
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        defaultValue={location.state.details?.month}
                        className="form-control"
                        {...register("month")}
                      />
                      {errors?.month?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                  <label className="form-label"> Short Description </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      defaultValue={location.state.details?.description}
                      className="form-control"
                      onChange={(e) => {
                        setShortDescription(e.target.value);
                      }}
                    />
                    <div
                      className="hint_box"
                      style={{ display: "block" }}
                    ></div>
                  </div>
                </div> */}
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      T&C Heading<span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        defaultValue={location.state.details?.heading}
                        className="form-control"
                        {...register("headingDes", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.headingDes?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.headingDes?.type === "min" && (
                        <p>Minimum one number is required</p>
                      )}

                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Terms & Conditions<span style={{ color: "red" }}>
                        *
                      </span>{" "}
                    </label>
                    <div className="position-relative">
                      <textarea
                        style={{ height: "100px" }}
                        defaultValue={location.state.details?.textField}
                        className="form-control"
                        {...register("textField", {
                          required: true,
                          min: 1,
                        })}
                      />
                      {errors?.textField?.type === "required" && (
                        <p>This field is required</p>
                      )}
                      {errors?.textField?.type === "min" && (
                        <p>Minimum one number is required</p>
                      )}
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {" "}
                      Upload Service Pack Icon
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                      />
                      <img
                        src={
                          location.state?.details?.image
                            ? location.state.details?.image
                            : location.state.details?.imageData
                        }
                        width="50px"
                      />
                      <div
                        className="hint_box"
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Upload Documents</label>
                    <div className="position-relative">
                      <div>
                        <input
                          type="file"
                          name="upload"
                          accept=".xlsx,.xls, .doc, .docx,.pdf"
                          onChange={(e) => setDocument(e.target.files[0])}
                          className="form-control"
                        />
                        {location.state.details?.document ||
                        location.state.details?.docData ? (
                          <a
                            href={
                              location.state.details?.document
                                ? location.state.details?.document
                                : location.state.details?.docData
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            <img
                              src="images/pdf.png"
                              alt="pdfIcon"
                              width="40px"
                              height="40px"
                            />{" "}
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger CancelBtn"
                  onClick={() => {
                    history.push("./Plan");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn submitBtn"
                  // data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanUpdate;
