import React, { useState } from "react";
import axios from "axios";
import "./Feedback.css";
import Footer from "../Footer/footer";

const UpdateFeedback = (props) => {
    
  const [title, setTitle] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [url, setUrl] = useState("");
  const [disImage, setDisImage] = useState("");


  
  function updateFeedbackData() {
    console.log(props.updatedData,"123456789")
    var data = JSON.stringify({
      "id": props.updatedData?._id,
      "heading": title === "" || null ? props.updatedData?.heading  : title,
     
        "Disperctions": discreption === "" || null ? props.updatedData?.Disperctions  : discreption,
      
        "url":
        url === "" || null ? props.updatedData?.url : url 
      });
  
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editTraningdata`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Feedback
      </h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Title </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.heading}
              className="form-control"
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Description </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.Disperctions}
              className="form-control"
              onChange={(e) => {
                setDiscreption(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
       
      <div className="col-md-12 mb-3">
          <label className="form-label">Edit Url </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.url}
              className="form-control"
              onChange={(e) => {
                setUrl(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>


      </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" data-bs-dismiss="modal"
      >Cancel</button>
      <button
        type="submit"
        onClick={updateFeedbackData}
        className="btn submitBtn" data-bs-dismiss="modal">
        Submit
      </button>
    </div>

  </>)
}

export default UpdateFeedback;