
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";

// import "./Category.css";
import Footer from "../../shared/Footer/footer";

const UpdateInspectionSubCat = (props) => {
  console.log(props,"props")
  const [Inspection, setInspectionid] = useState("");
  const [G, setCheckG] = useState(props.updatedData?.G);
  const [A, setCheckA] = useState(props.updatedData?.A);
  const [P, setCheckP] = useState(props.updatedData?.P);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  
  function InspectionSubCategory() {}
   

  function updateInspectioncaty(postdata) {

    var data = JSON.stringify({
      'subcatid': props.updatedData?._id,
      'InspectionSubCategory': postdata.inspectionSubcategory === '' || null ? props.updatedData?.InspectionCategory : postdata.inspectionSubcategory,
      'G': G,
      'A': A,
      'P': P,
     


     });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/InspectionSubCategoryedit`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //---PLAN GET API--//



  const handleCheckboxG= event =>{
    console.log(event,"event")
    if(event.target.checked == true){
      setCheckG(event.target.value, "8888");
      }else if(event.target.checked == false){
        setCheckG("")
      }
}

const handleCheckboxA= event =>{
  if(event.target.checked == true){
    setCheckA(event.target.value, "8888");
  }else{
    setCheckA("")
  }
}

const handleCheckboxP= event =>{
  if(event.target.checked == true){
    setCheckP(event.target.value, "8888");
  }else{
    setCheckP("")
  }
}



  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Inspection Subcategory
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form
      onSubmit={handleSubmit(updateInspectioncaty)}
    >
      <div className="modal-body">
        <div className="row">
      

        

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Inspection Subcategory<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.InspectionSubCategory}
                className="form-control"
                {...register("inspectionSubcategory", {
                  required: true,
                  min:1
                })}
                
              />
              {errors?.inspectionSubcategory?.type ===
                "required" && (
                <p>This field is required</p>
              )}
              {errors?.inspectionSubcategory?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="row">
                        
                     
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select check box</label>
                          <div className="position-relative">

                          <div className="form-check form-check-inline">
                              <input
                                 defaultChecked={props.updatedData?.G}
                              onChange={handleCheckboxG} value="G" className="form-check-input" type="checkbox" id="inlineCheckbox1" />
                              <label className="form-check-label" for="inlineCheckbox1">G</label>
                              </div>
                              <div class="form-check form-check-inline">
                              <input 
                                 defaultChecked={props.updatedData?.A}

                              onChange={handleCheckboxA} value="A" className="form-check-input" type="checkbox" id="inlineCheckbox2"/>
                              <label className="form-check-label" for="inlineCheckbox2">A</label>
                              </div>
                              <div class="form-check form-check-inline">
                              <input 
                                 defaultChecked={props.updatedData?.P}

                              onChange={handleCheckboxP} value="P" className="form-check-input" type="checkbox" id="inlineCheckbox3"/>
                              <label className="form-check-label" for="inlineCheckbox2">P</label>
                              </div>
      
                            
                          </div>
                        </div>
                       
                      </div>

     
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};
export default UpdateInspectionSubCat;
