import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import "./PaymentDetails.css";

// ---INDEX NUMBER SHOW IN TABLE FROM SRNORENDERER --//

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const OrderRenderer = (props) => {
  console.log(props, "data");
  let data = props.data?.order_id;
  return (
    <span className="profle_img_box">
     {data}
    </span>
  );
};

const EmailRenderer = (props) => {
    console.log(props, "data");
    let data = props.data?.email;
    return (
      <span className="profle_img_box">
      {data}
      </span>
    );
  };

  const InvoiceRenderer = (props) => {
    console.log(props, "data");
    let data = props.data?.invoice_id;
    return (
      <span className="profle_img_box">
      {data}
      </span>
    );
  };

  const ContactRenderer = (props) => {
    console.log(props, "data");
    let data = props.data?.contact;
    return (
      <span className="profle_img_box">
      {data}
      </span>
    );
  };

  const AmountRenderer = (props) => {
    console.log(props, "data");
    let data = props.data?.amount;
    let length = data?.toString()?.length-2
    let amount = data?.toString()?.slice(0,length)
    let endAmount = data?.toString()?.slice(length)
    console.log(length, "length");
    return (
      <span className="profle_img_box">
      {amount}.{endAmount}
      </span>
    );
  };

// ---STATE  SHOW IN TABLE FROM STATERANDER --//

function PaymentDetails() {
  const [rowData, setRowData] = useState(PaymentDetails);

  const pagination = true;
  const paginationPageSize = 10;
   const rowHeight = 42;

  useEffect(() => {
    PaymentDetails();
  }, []);

  // ------GET SCOUT API-------//

  function PaymentDetails() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getPayment`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Payment Details");
        let details = response.data.data;
        let PaymentDetails = [];
        details?.map((item)=>{
          if(item?.status != "failed")
            PaymentDetails.push(item)
        })
        setRowData(PaymentDetails);
        console.log(PaymentDetails, "Payment");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "Order ID",
      headerName: "Order ID",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "orderRenderer",
      getQuickFilterText: params => {
        return params.data?.order_id;
    }
    },
    {
      field: "Email",
      headerName: "Email",
      width: 140,
      sortable: false,
      filter: false,
      cellRenderer: "emailRenderer",
      getQuickFilterText: params => {
        return params.data?.email;
    }
    },
    {
      field: "id",
      headerName: "Payment Id",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "id",
      getQuickFilterText: params => {
        return params.data?.contact;
    }
    },
    {
        field: "Amount",
        headerName: "Amount",
        width: 150,
        sortable: false,
        filter: false,
        cellRenderer: "amountRenderer",
      },
    {
      field: "Contact No.",
      headerName: "Contact No.",
      sortable: false,
      width: 140,
      cellRenderer: "contactRenderer",
    },
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Payment Details
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
              </h4>
              <div
                className="ag-theme-alpine cts_datatable CityPaymentTable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    '<span style="padding: 10px; border: 2px solid #444;">no rows to show </span>'
                  }
                  frameworkComponents={{
                    srNoRenderer: SrNoRenderer,
                    orderRenderer:OrderRenderer,
                    emailRenderer:EmailRenderer,
                    invoiceRenderer:InvoiceRenderer,
                    contactRenderer:ContactRenderer,
                    amountRenderer:AmountRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PaymentDetails;
