import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import ToggleButton from "../../Components/ToggleButton";


function Cars() {
  const [DeleteDeleteId,setDeleteDeleteId] = useState("");

 
  const [rowData, setRowData] = useState(getCars);



  console.log(rowData,"rowData")
  
  const pagination = true;
  let history = useHistory();


  const imageRerander = (props) => {
    return (
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }

  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const brandRenderer = (props) => {
    return (
      <>
        <span>{props.data.brand?.brands}</span>
      </>
    );
  };

  const fuelRenderer = (props) => {
    return (
      <>
        <span>{props.data.fuel[0]?.fuel}</span>
      </>
    );
  };

  const cityRenderer = (props) => {
    return (
      <>
        <span>{props.data?.city?.city}</span>
      </>
    );
  };

  const carCategoryRenderer = (props) => {
    return (
      <>
        <span>{props.data?.carCategory?.carCategory}</span>
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
         onClick={() => {console.log(props.data,"update");
         history.push({
           pathname: "UpdateCars",
           state: { details: props.data, },
         });
       }}
        >
          <MdModeEditOutline
            className="ActionIcon viewicon"
           
          />
        </div>
        {/* <div className="ViewIcon"
           onClick={() => {console.log(props.data,"update");
           history.push({
             pathname: "ViewCar",
             state: { details: props.data, },
           });
         }}
        >
          <AiFillEye
         
          />
        </div> */}
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };


  
  


  

  useEffect(() => {
     getCars();
   
  }, []);

  function getCars() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCar`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
     console.log(response.data,"carget")
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function CardeleteData(index) {
    var data = JSON.stringify({
      carsid: index,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletecar`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getCars();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeCarStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  function changeCarStatus(){}

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "cars",
      headerName: "Cars",
      width: 190,
      sortable: false,
      filter: false,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 190,
      sortable: false,
      filter: false,
      cellRenderer: "brandRenderer",
    },
    {
      field: "fuel",
      headerName: "Fuel",
      width: 190,
      sortable: false,
      filter: false,
      cellRenderer: "fuelRenderer",
    },
    {
      field: "car category",
      headerName: "Car Category",
      width: 190,
      sortable: false,
      filter: false,
      cellRenderer: "carCategoryRenderer",
      getQuickFilterText: (params) => {
        return params.data?.carCategory?.carCategory;
      },
    },
    {
      field: "city",
      headerName: "City",
      width: 190,
      sortable: false,
      filter: false,
      cellRenderer: "cityRenderer",
      getQuickFilterText: (params) => {
        return params.data?.city?.city;
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 190,
      sortable: false,
      filter: false,
      cellRenderer: "imageRender",
    },
    // { field: "discreption", headerName: "Discreption",
    // width:140,
    // sortable:false,
    // filter:false },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "statusRenderer",
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      colId: "params",
      sortable: true,
      filter: false,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };


 
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Car Master
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => history.push("./AddCar")}
                  >
                    Add New Cars
                  </button>
                </div>
              </h4>

              <div
                className="modal fade"
                id="UpdateStudentsData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  {/* <div className="modal-content">
                    <UpdateCars
                      updatedData={editCar}
                      // onEditDataFunction={editdataReloadFunc}
                    />
                  </div> */}
                </div>
              </div>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Car?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                CardeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    // moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                    brandRenderer : brandRenderer,
                    fuelRenderer:fuelRenderer,
                    cityRenderer:cityRenderer,
                    carCategoryRenderer:carCategoryRenderer
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cars;
