import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UpdateWorkshop from "./UpdateWorkshop";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

// ---INDEX NUMBER SHOW IN TABLE FROM SRNORENDERER --//

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

// ---IMAGE  SHOW IN TABLE FROM IMAGERANDER --//

const imageRenderer = (props) => {
  console.log(props, "data");
  let image = props.data?.image;
  return (
    <span className="profle_img_box">
      {
        image ?  <img className="profile_img_table" src={image} /> : ""
      }
    </span>
  );
};


const CityRenderer = (props) => {
  console.log( props.data?.city, "data");
  let city = props.data?.city?.city;
  return (
    <span className="profle_img_box">
     {city}
    </span>
  );
};
// ---STATE  SHOW IN TABLE FROM STATERANDER --//

const StatusRenderer = (props) => {
  return (
    <>
      {props?.data.status === true ? (
        <button
          className="btn btn-success btn-sm"
          // onClick={() => {
          //   changeEventStatus(props?.data._id);
          // }}
        >
          Verified
        </button>
      ) : (
        <button
          className="btn btn-danger btn-sm"
          // onClick={() => {
          //   changeEventStatus(props?.data._id);
          // }}
        >
          Not Verified
        </button>
      )}
    </>
  );
};

function Workshop() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateWorkshopData, setUpdateWorkshopData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [image, setImage] = useState("");
  const [rowData, setRowData] = useState(WorkshopGet);
  const [type, setType] = useState("")
  const [cityId, setCityId] = useState("")
  const [city, setCity] = useState("");
  const [show, setShow] = useState(false);
  const [typeErr, setTypeErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [disabled, setDisabled] = useState(false);

  const workshopType = [
    { value: 0, label: "Multi-Brand" },
    { value: 1, label: "Company-Owned" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const [isOpen, setIsOpen] = useState(false);

  const pagination = true;
  const paginationPageSize = 20;
  const rowHeight = 55;

  let history = useHistory();
  let viewData = [];
  console.log(viewData, "46456");

  // ------Post API-------//

  function addWorkshop(postdata) {
    if((cityId==""||null) || (type ==""||null)){
      if(cityId==""||null){
        setCityErr("This field is required")
      }
      if(type ==""||null){
        setTypeErr("This field is required")
      }
    }else{
    // e.preventDefault();
    setDisabled(true)
    var data = new FormData();
    data.append("name", postdata.name);
    data.append("Address", postdata.address);
    data.append("type", type);
    data.append("city", cityId.toString());
    data.append("image", postdata.image[0]);

    var config = {
      method: "post",
      // url: `${process.env.REACT_APP_BASEURL}/register`,
      url: `${process.env.REACT_APP_BASEURL}/addworkshop`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "add User");
        setIsOpen(false);
        WorkshopGet();
        reset();
        setCityId("");
        setType("")
        resetForm();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  const resetForm = () => {
    setImage("");
  };

  const handleChangeCity = (e) => {
    console.log(e.label, "element");
    setCityId(e.value);
    setCityErr("")
  }

  useEffect(() => {
    WorkshopGet();
    getCity();
  }, []);

  // ------GET Workshop API-------//

  function WorkshopGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/GetWorkshop`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "getuser");
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const editdataReloadFunc = () => {};

  // ----SCOU DELETE API---//

  function WorkshopDelete(id) {
    

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteworkshop?id=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        WorkshopGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
        let city = response.data.data;
        console.log(city,"cities");
        let arrCity = [];
        response.data.data.map((catName) => {
          arrCity.push({ label: catName.city, value: catName._id });
        });
        setCity(arrCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // ------ ACTION SHOW IN TABLE -------//

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateWorkshopData(props.data);
            setShow(true)
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>
        <div
          className="ViewIcon"
          // onClick={() =>{console.log(props.data,"view");history.push("userView/" + props.data._id)} }
          onClick={() => {
            history.push({
              pathname: "WorkshopView",
              state: { details: props.data },
            });
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cross() {
    reset();
  }

  function box() {
    setShow(false);
    cross();
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "imageRenderer",
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      sortable: false,
      filter: false,
      cellRenderer: "nameRenderer",
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "cityRenderer",
    },
    // {
    //   field: "active order",
    //   headerName: "Active order",
    //   width: 120,
    //   sortable: false,
    //   filter: false,
    // },
    // {
    //   field: "total order",
    //   headerName: "Total order",
    //   width: 120,
    //   colId: "params",
    //   // cellRenderer="EmailRender"
    //   sortable: true,
    //   filter: true,
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      colId: "params",
      sortable: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Workshops
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                    }}
                  >
                    Add New Workshop
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Workshop
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addWorkshop)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Workshop Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              /> */}
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("name", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.name?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.name?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Workshop Email
                                  </label>
                                  <div className="position-relative">


                                    <input
                                      className="form-control"
                                      {...register("email", {
                                        required: true,
                                        pattern:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />
                                    {errors?.email?.type === "pattern" && (
                                      <p>Invalid email address</p>
                                    )}
                                    {errors?.email?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                  </div>
                                </div> */}
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Workshop Mobile
                                  </label>
                                  <div className="position-relative">

                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                       onWheel={(e) => e.target.blur()}
                                      {...register("mobile", {
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                      })}
                                    />
                                    {errors?.mobile?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.mobile?.type === "minLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                    {errors?.mobile?.type === "maxLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Workshop Address<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={street}
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      type="text"
                                      {...register("address", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.address?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.address?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Workshop City<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={street}
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                }}
                              /> */}
                        <Select
                          // isMulti
                          options={city}
                          onChange={(e) => handleChangeCity(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{cityErr}</span>
                                    
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label"> State</label>
                                  
                                  <input
                                    className="form-control"
                                    type="text"
                                    {...register("state", {
                                      required: true,
                                      pattern: /^[A-Za-z ]+$/i,
                                      min:1
                                    })}
                                  />
                                  {errors?.state?.type === "pattern" && (
                                    <p>Alphabet only</p>
                                  )}
                                  {errors?.state?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                  {errors?.state?.type === "required" && (
                                    <p>This field is required</p>
                                  )}{" "}
                                </div> */}
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">PinCode</label>
                                  <div className="position-relative">

                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                       onWheel={(e) => e.target.blur()}
                                      {...register("pincode", {
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                      })}
                                    />
                                    {errors?.pincode?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.pincode?.type === "maxLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                    {errors?.pincode?.type === "minLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3">
                        <label className="form-label">Workshop Type<span style={{color:"red"}}>*</span></label>
                        <Select
                          // isMulti
                          options={workshopType}
                          className="basic-multi-select"
                          onChange={(e)=>{
                            setType(e.label)
                            setTypeErr("")
                          }}
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{typeErr}</span>
                    </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Workshop Image<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <div>
                                      <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        // onChange={(e) =>
                                        //   setImage(e.target.files[0])
                                        // }
                                        className="form-control"
                                        {...register("image",{required:true})}
                                      />
                                       {errors?.image?.type === "required" && (
                                          <p>This field is required</p>
                                        )}
                                    </div>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  onClick={() => canclefun()}
                                  type="button"
                                  className="btn btn-danger CancelBtn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  // onClick={addWorkshop}
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled = {disabled ? true : false}
                                  // data-bs-dismiss="modal"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateWorkshop
                      updatedData={UpdateWorkshopData}
                      onEditDataFunction={WorkshopGet}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Workshop?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                WorkshopDelete(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Workshop Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> User Name : {Viewuser.name}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6> User Email : {Viewuser.email}</h6>
                                  </div>

                                  <div>
                                    {" "}
                                    <h6> User Mobile : {Viewuser.mobile}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body border">
                              <div>
                                <ul className="nav nav-pills" role="tablist">
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#home"
                                      role="tab"
                                    >
                                      {/* <span>Users Details</span> */}
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content pt-3">
                                  <div
                                    className="tab-pane active"
                                    id="home"
                                    role="tabpanel"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    '<span style="padding: 10px; border: 2px solid #444;">no rows to show </span>'
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRenderer: imageRenderer,
                    cityRenderer:CityRenderer,
                    // statusRenderer: StatusRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Workshop;
