
import React, {useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateCarCategory from './UpdateCarCategory'

import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import ToggleButton from "../../Components/ToggleButton";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

function CarCategory() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [DeleteDeleteId,setDeleteDeleteId] = useState("")
  const [UpdateCarCategoryData, setUpdateCarCategoryData] = useState({});
  const [viewCategory, setViewCategory] = useState([]);
  const [CarCategoryData, setCarCategoryData] = useState("");
  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState(carCategoryGet);
  const [disabled, setDisabled] = useState(false);
  const [carCategoryErrorMessage, setCarCategoryErrorMessage] = useState("");
  const pagination = true;
//   const paginationPageSize = 10;
//   const rowHeight = 55;

 


  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  

  const ImageRender = (props) => {
    return (
      // <img src={props.data.image}/>
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
        onClick={() => {
          setUpdateCarCategoryData(props.data);
          setShow(true)
      }}
        >
          <MdModeEditOutline
        
            className="ActionIcon viewicon"
            
          />
        </div>
        <div className="ViewIcon"
         onClick={() => {
          setViewCategory(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UserViewId"
        >
          <AiFillEye
           
            alt=""
            src="../../images/view.jpg"
          />
        </div>
        <div className="DeleteIcon"
        onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteCarCategoryData"
        >
          <AiFillDelete
            className="ActionIcon"
            
          />
        </div>
      </div>
    );
  };

  function box() {
    setShow(false);
    cross();
  }
  

  // ------Post API-------//

  function addCarCategory(postdata) {
    setDisabled(true)
    var data = new FormData();
  
    data.append("carCategory", postdata.CarCategoryData);
    data.append("image", image);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addcarCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response,"response")
        if(response.data.status === false){
          setCarCategoryErrorMessage(response.data.message);
          setDisabled(false)
        }else{
          setCarCategoryErrorMessage("")
       carCategoryGet();
       setIsOpen(false);
       reset();
       resetForm();
        }
      //  console.log(e.target.files[0],"files");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const resetForm = () => {
		setCarCategoryData('');
		setImage('');
    image="";
	  };
  useEffect(() => {
    carCategoryGet()
  }, []);

  function carCategoryGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCarCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {  
        console.log(response,"12233")      
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  function CarCategoryDelete(id) {
    var data = JSON.stringify({
        carCategoryid: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteCarCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        carCategoryGet()
      })
      .catch(function (error) {
        console.log(error);
      });
  }
function crossForm() {
  setIsOpen(false);
  cross();
}
function cross() {
  reset();
}
 function canclefun() {
   setIsOpen(false);
   cancelForm();
 }

 function cancelForm() {
   reset();
 }

  const editdataReloadFunc = () => {
    carCategoryGet()
  };

  
  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeCategoryStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "carCategory",
      headerName: "Car Category",
      width: 200,
      sortable: false,
      filter: false,
    },
    // {
    //   field: "image",
    //   headerName: "Image",
    //   width: 250,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "imageRender",
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   cellRenderer: "statusRenderer",
    //   sortable: false,
    //   filter: false,
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      colId: "params",
      cellRenderer: "childMessageRenderer",
      sortable: true,
      filter: true,
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };



  function changeCategoryStatus(){}
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Car Category
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => { 
                      setCarCategoryErrorMessage("")
                      setIsOpen(true)
                        setDisabled(false)
                    }}
                  >
                    Add New Car Category
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Car Category
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addCarCategory)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Car Category Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      // onChange={(e) => {
                                      //   setCarCategoryData(e.target.value);
                                      // }}
                                      {...register("CarCategoryData", {
                                        onChange: () => setCarCategoryErrorMessage(""),
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.CarCategoryData?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                      {errors?.CarCategoryData?.type === "min" && (
                                        <p>Minimum One Letter Required</p>
                                      )}
                                      <p style={{color:"red"}}>{carCategoryErrorMessage}</p>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                            <label className="form-label">image</label>
                            <div className="position-relative">
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setImage(e.target.files[0]);
                                 }}
                              />
                            </div>
                          </div> */}
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                onClick={() => canclefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateCarCategory
                      updatedData={UpdateCarCategoryData}
                      onEditDataFunction={carCategoryGet}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteCarCategoryData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this car category?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                CarCategoryDelete(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Car Category Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Car Category : {viewCategory.carCategory}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> Category image : {ViewFuel.image}</h6> */}
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: ImageRender,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CarCategory;


