import React from "react";

function Footer() {
  return (
    <footer class="footer text-center">
    Copyright © 2022 City of cars. All rights reserved. Developed by <a href="https://appicsoftwares.com/" target="_blank">Appic Softwares</a>
</footer>
  );
}

export default Footer;