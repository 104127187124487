import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../loader.js";
import { AiFillAppstore } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { FaList } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { FiList } from "react-icons/fi";
import { VscServerProcess } from "react-icons/vsc";

import { FaCity } from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { GiFuelTank } from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";

import { FcInspection } from "react-icons/fc";
import { MdOutlineSettingsSuggest } from "react-icons/md";

import { FaBorderAll } from "react-icons/fa";
import { modules } from "../userRolemanagement/Roles.js";
import { useContext } from "react";
import GlobalContext from "../store/global-context";

import "./Sidebar.css";

function Sidebar() {
  let localdata = JSON.parse(sessionStorage.getItem("userData"));
  const [roles, setRoles] = useState([]);
  const [adminType, setAdminType] = useState("");
  const [classShow,setClass] = useState( {name:"",
    boolean:false});
  useEffect(() => {
    const timer = setTimeout(() => {
      setRoles(localdata?.roles);
      setAdminType(localdata?.type);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const updatedroles = roles?.map((item) => {
    const mapdata = [];
    modules.forEach((module, index) => {
      if (item === module.value) {
        mapdata.push(module);
      }
    });
    return mapdata;
  });
  const flattened = updatedroles?.flatMap((num) => num);
  console.log(flattened, "flattened");
  const ctx = useContext(GlobalContext);
  console.log(ctx.show, "showhide");
  return (
    <aside className={`left-sidebar ${ctx.show ? "show" : ""} col-md-4`}>
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <h5>Menu</h5>
            {flattened?.map((moduleItem, moduleIndex) => {
              return (
                <>
                  {!moduleItem.children && (
                    <li key={moduleIndex} className="sidebar-item">
                      <NavLink
                        className="sidebar-link waves-effect waves-dark sidebar-link"
                        to={moduleItem.link}
                        aria-expanded="false"
                      >
                        {moduleItem.icon}
                        <span className="hide-menu">{moduleItem.label}</span>
                      </NavLink>
                    </li>
                  )}
                  {moduleItem.children && (
                    <li className="sidebar-item">
                      <div class="dropdown">
                        <a
                          class="btn btn-ligh dropdown-toggle sidebar-link"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {moduleItem.icon}
                          {moduleItem.label}
                        </a>

                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {moduleItem.children.map((item, index) => {
                            return (
                              <li key={index} className="sidebar-item">
                                <NavLink
                                  className="sidebar-link waves-effect waves-dark sidebar-link"
                                  to={item.link}
                                  aria-expanded="false"
                                >
                                  {item.icon}
                                  <span className="hide-menu">
                                    {item.label}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  )}
                </>
              );
            })}
            {adminType === "admin" && (
              <>
                {modules?.map((moduleItem, moduleIndex) => {
                  return (
                    <>
                      {!moduleItem.children && (
                        <li key={moduleIndex} className="sidebar-item">
                          <NavLink
                            className="sidebar-link waves-effect waves-dark sidebar-link"
                            to={moduleItem.link}
                            aria-expanded="false"
                          >
                            {moduleItem.icon}
                            <span className="hide-menu">
                              {moduleItem.label}
                            </span>
                          </NavLink>
                        </li>
                      )}
                      {moduleItem.children && (
                        <li className={`sidebar-item ${classShow.boolean==true && classShow.name==moduleItem.label ? "show" : ""}`}>
                          <div class="dropdown">
                            <a
                              class="btn btn-ligh dropdown-toggle sidebar-link"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {moduleItem.icon}
                              {moduleItem.label}
                            </a>

                            <ul
                              className={`dropdown-menu`}
                              aria-labelledby="dropdownMenuLink"
                            >
                              {moduleItem.children.map((item, index) => {
                                console.log(moduleItem, classShow,"sbkch")
                                return (
                                  <li key={index} className="sidebar-item">
                                    <NavLink
                                      className="sidebar-link waves-effect waves-dark sidebar-link"
                                      to={item.link}
                                      aria-expanded="false"
                                      onClick={()=>{
                                      setClass({
                                        name:moduleItem.label,
                                        boolean:true
                                      })}}
                                    >
                                      {item.icon}
                                      <span className="hide-menu">
                                        {item.label}
                                      </span>
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}

                <li class="sidebar-item">
                  <NavLink
                    className="sidebar-link waves-effect waves-dark sidebar-link"
                    to="/userrole"
                    aria-expanded="false"
                  >
                    <i class="mdi mdi-adjust"></i>
                    <FaUsers />
                    <span class="hide-menu">Role Management</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>

      {/* <div className="sidebar-footer">
        <div className="link" style={{ height: "58px" }} title="Settings"></div>
      </div> */}
    </aside>
  );
}

export default Sidebar;
