import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateBlog from "./UpdateBlog";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete,AiFillEye } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Input from "../../Components/Input";
import { useForm } from "react-hook-form";
// import Modal from "react-bootstrap/Modal";
import Modal from 'react-bootstrap/Modal'
import ToggleButton from "../../Components/ToggleButton";


function Blog() {
  const [DeleteDeleteId,setDeleteDeleteId] = useState("")
  const [UpdateBlogData, setUpdateBlogData] = useState([]);
  const [Viewuser, setViewuser] = useState([]);
  const [title, setTitle] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [subDiscreption, setsubDiscreption] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(getBlog);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 55;

  const {register,handleSubmit,formState:{errors},reset} =useForm();

  const [isOpen, setIsOpen] =useState(false);


  const MoodRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <stong>{props.data.Blog}</stong>
      </span>
    );
  };
  const imageRerander = (props) => {
    return (
      // <img src={props.data.image}/>
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }

  

  const SrNoRenderer = (props) => {
    console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
         onClick={() => {
          setUpdateBlogData(props.data);
          setShow(true);
        }}
        >
          <MdModeEditOutline
            className="ActionIcon viewicon"
           
          />
        </div>
        <div className="ViewIcon">
          <AiFillEye
            onClick={() => {
              console.log(props, "propstyty");
              setViewuser(props.data);
            }}
            data-bs-toggle="modal"
            data-bs-target="#UserViewId"
            alt=""
            src="../../images/view.jpg"
          />
        </div>
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };

  function box() {
    setShow(false);
    cross();
  }

  // ------Post API-------//

  function addBlog(postdata) {
   setDisabled(true);
    var data = new FormData();
    data.append("blogsheading", postdata.title);
    data.append("Discreption", postdata.discreption);
    data.append("image", postdata.image[0]);
    data.append("SubDiscreption", postdata.subdiscreption);
    data.append("createDate", new Date());

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/AddBlogs`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
        
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getBlog();  
        crossForm();
        resetForm();      
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cross() {
    reset();
  }

  const resetForm = () => {
	
    setTitle('');
    setDiscreption('');
    setimage('');
    setimage('');
	  };


    // axios.get(`${process.env.REACT_APP_BASEURL}/getBlog`).then(function(response){
    //   console.log(response,"morning")
    // })

    // useEffect(() => {
   
    //   res()
    // }, []);

//  axios.get('http://54.221.3.19:/admin/getBlog', {
//  headers: {
//         Authorization: sessionStorage.getItem("token"),
//  }
// }).then(function(response){
//   setRowData(response.data.getdata);
// }) .catch(function (error) {
//          console.log(error);
//  });;


// res.data.headers[Authorization]; // "test-value"


  useEffect(() => {
   
     getBlog();
  }, []);

  function getBlog() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBlogs`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response,"catblog")
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function BlogdeleteData(index) {
    var data = JSON.stringify({
      _id: index,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/DeleteBlogs`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
         getBlog();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const editdataReloadFunc = () => {
    // getBlog();
  };

  
  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeBlogStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  function changeBlogStatus(blogId) {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Blogsstatus?id=${blogId}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        getBlog();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      Srno: true,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "blogsheading",
      headerName: "Blog",
      width: 200,
      sortable: false,
      filter: false,
    },
    // { field: "parentsid", headerName: "Blog",
    // width:160,
    // sortable:false,
    // filter:false },
    {
      field: "Discreption",
      headerName: "Description",
      width: 160,
      sortable: false,
      filter: false,
    },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      sortable: false,
      filter: false,
      cellRenderer: "imageRender",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      colId: "params",
      sortable: true,
      filter: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Blog
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                    }}
                  >
                    Add New Blog
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Blog
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addBlog)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Blog Title<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <Input style="form-control"
                              onChange={(e) => {
                                console.log(e,"thisisevent")
                                setTitle(e?.target?.value);
                              }}/> */}

                                    {/* <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      {...register("title", {
                                        required: true,
                                        pattern: /^[0-9A-Za-z ]+$/i,
                                        min:1
                                      })}
                                    />
                                    {errors?.title?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.title?.type === "pattern" && (
                                      <p>Invalid Character</p>
                                    )}
                                    {errors?.title?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                     Content<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <textarea
                                type="text"
                                className="form-control"
                                value={discreption}
                                onChange={(e) => {
                                  setDiscreption(e.target.value);
                                }}
                              /> */}
                                    <textarea
                                      className="form-control p-2"
                                      {...register("subdiscreption", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.subdiscreption?.type ===
                                      "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.subdiscreption?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                  Description<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <textarea
                                type="text"
                                className="form-control"
                                value={discreption}
                                onChange={(e) => {
                                  setDiscreption(e.target.value);
                                }}
                              /> */}
                                    <textarea
                                      className="form-control p-2"
                                      {...register("discreption", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.discreption?.type ===
                                      "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.discreption?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Image<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      className="form-control"
                                      {...register("image",{required:true})}         
                                    />
                                     {errors?.image?.type === "required" && (
                                        <p>This field is required</p>
                                      )}

                                    {/* <input 
                              type="file"
                              className="form-control"
                              {...register("addimage",{
                                required:true
                              })}
                              />
                              {errors?.addimage?.type ==="required"&&(
                                <p>This field is required</p>
                              )} */}

                                    {/* <input  className="form-control" type="file"
                                  {...register("addimage",{minLength:[0]})} />
                                 {errors?.addimage?.type === "minLength" && (<p>Only 1 digit number</p>)}  */}

                                    {/* <input 
                                  type="file"
                                  name="image"
                              className="form-control"
                              {...register("addimage,",{
                                required: true,
                              })}
                              /> */}
                                    {/* {errors?.image?.type ==="required"&&(
                                <p>This field is required</p>
                              )}  */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                                onClick={() => canclefun()}
                              >
                                Cancel
                              </button>
                              <button
                                // onClick={addBlog}
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateBlog
                      updatedData={UpdateBlogData}
                      onEditDataFunction={getBlog}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Blog?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                BlogdeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Blog Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Blog Title : {Viewuser.blogsheading}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Blog Content :{" "}
                                      {Viewuser.SubDiscreption}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Blog Description :{" "}
                                      {Viewuser.Discreption}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      <img src={Viewuser.image} width="200px"/>{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  // defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
