import React, { useState, useRef } from "react";
import axios from "axios";
import "./Faq.css";
import JoditEditor from "jodit-react";
import { useForm } from "react-hook-form";

const UpdateFaq = (props) => {
    
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [policy, setPolicy] = useState("");

  const {register,handleSubmit,formState:{errors},reset} =useForm();

  const [title, setTitle] = useState("");

  const config = {
    readonly: false,
    height: 400,
    forced_root_block : ""
  };
  
  function updateFaqData(postdata) {
    console.log(props.updatedData,"123456789");

      var data = JSON.stringify({
        id: props.updatedData?._id,
        question:postdata.title === "" || null ? props.updatedData?.question  : postdata.title,
        answer:postdata.content === "" || null ? props.updatedData?.answer  : postdata.content,
      });
  
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editFaq`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  
  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Faq
      </h5>
      <button
        type="button"
        className="btn-close"
        onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateFaqData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Question<span style={{color:"red"}}>*</span> : </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.question}
              className="form-control"
              {...register("title", {
                required: true,
                min:1
              })}
            />
            {errors?.title?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.title?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Answer<span style={{color:"red"}}>*</span> : </label>
          <div className="position-relative">
          <textarea
          defaultValue={props?.updatedData?.answer}
                                      className="form-control p-2"
                                      {...register("content", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.content?.type ===
                                      "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.content?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
          
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
    </form>
  </>)
}

export default UpdateFaq;