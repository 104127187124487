

import React, { useEffect, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { useLocation } from "react-router-dom";

function SubCategoryView(props) {
    let location = useLocation();
    // const [planViewPrice, setPlanViewPrice] = useState("");

   console.log(location.state.details?.parentsid?.title,"arrPrice")
   let arrPrice=[];
let arrName=[];
//    location.state?.details?.plan_id?.map((item)=>{
//        arrName.push(item.planName)
// item.typeprice.map((items)=>{
//     arrPrice.push(items.price)

// })
//     // arrPrice.push(item.price)

//    }
   
//    )


  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div class="row">
            
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div>
                    <ul class="nav nav-pills" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          // href="#home"
                          role="tab"
                        >
                          <span>Subcategory Details</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#profile"
                          role="tab"
                        >
                         
                        </a>
                      </li>
                    </ul>

                    <div class="tab-content pt-3">
                      <div class="tab-pane active" id="home" role="tabpanel">
                        <table class="table table-bordered table_fix_width">
                          <tbody style={{borderWidth:"1px"}}>
                           
                            {/* <tr>
                              <th>Address</th>
                              <td>11/61, Madhyam Marg, Kaveri Path, Mansarovar Sector 1, Mansarovar, Jaipur, Rajasthan 302020</td>
                            </tr> */}
                            <tr>
                              <th>Subcategory Name</th>
                              <td>{location.state.details?.title}</td>
                            </tr>
                            <tr>
                              <th>Category Name</th>
                             <td> {location.state.details?.parentsid?.title}</td>
                            </tr>
                            {/* <tr>
                              <th>Plan Name</th>
                              {/* <td>{arrName}</td> */}
                            {/* </tr>
                            <tr>
                                
                                
                              <th>Plan Price</th> */}
                              {/* <td>{arrPrice}</td> */}
                            {/* </tr> */}

                            <tr>
                              <th>Description </th>
                              <td>
                             {location.state.details?.discreption}

                              </td>
                            </tr>
                          </tbody>
                        </table>
                        
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        
        </div>
      </div>
    </>
  );
}
export default SubCategoryView;
