import React, { useEffect, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { useLocation } from "react-router-dom";
import "./Userview.css";
import Avatar, { genConfig } from "react-nice-avatar";
import axios from "axios";

function Userview(props) {

  let location = useLocation();
  console.log(location.state, "adsd");

  const [car, setCar] = useState([]);
  let viewData = []
  let cars = []

  useEffect(() => {
    getviewUser();
  }, [])


  console.log(car, "car")

  function getviewUser() {

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/viewUser?userid=${location.state.details._id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "view");
        for (var x in response.data.data) {
          viewData.push(response.data.data[x]);
        }
        setCar(response.data.data[1].allcars)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="profile_box">
                    <div className="imageProfile">
                      <h4>
                        {location?.state?.details?.image ? (
                          <img
                            src={location?.state?.details?.image}
                            alt="Profile_Image"
                          />
                        ) : (
                          <Avatar style={{ width: "8rem", height: "8rem" }} />
                        )}
                      </h4>
                    </div>
                    <div class="profile_box_body ">
                      <div className="displeProfile">
                        <div className="ContentProfile">
                          <h4>{location?.state?.details?.name}</h4>
                          <h4 className="pt-1">
                            {location?.state?.details?.mobile}
                          </h4>
                          <a href="javascript:void(0);">
                            <i class="mdi mdi-email-outline"></i>{" "}
                            <a href="javascript:void(0);">
                              <i class="mdi mdi-phone"></i>
                              <h4>{location?.state?.details?.email}</h4>
                            </a>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div>
                    <ul class="nav nav-pills" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          // href="#home"
                          role="tab"
                        >
                          <span>User Details</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#profile"
                          role="tab"
                        ></a>
                      </li>
                    </ul>

                    <div class="tab-content pt-3">
                      <div class="tab-pane active" id="home" role="tabpanel">
                        <table class="table table-bordered table_fix_width">
                          <tbody style={{ borderWidth: "1px" }}>
                            {/* <tr>
                              <th>Address</th>
                              <td>11/61, Madhyam Marg, Kaveri Path, Mansarovar Sector 1, Mansarovar, Jaipur, Rajasthan 302020</td>
                            </tr> */}
                            <tr>
                              <th>Address</th>
                              <td>
                              {location?.state?.details?.House} {location?.state?.details?.Street}  {location?.state?.details?.city?.city} {location?.state?.details?.State}
                                <p className="mb-0" style={{ color: "#000" }}>
                                  Pin {location?.state?.details?.PinCode}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <th>Car's Owned</th>
                              <td>

                                {car.map((e,index)=>{
                                  return (
                                    <pre style={{color:"black", display:"flex", float:"left", fontFamily:`"Poppins", sans-serif`}}>{`  ${index+1}`}. {e?.car?.cars} - {e?.fuel?.fuel} </pre>
                                  )
                                })} 

                              </td>
                            </tr>
                            <tr>
                              <th>Date</th>
                              <td>{location?.state?.details?.createdAt.slice(0,10)}</td>
                            </tr>
                            {/* <tr>
                              <th>Address</th>
                              <td>{location?.state?.details?.Address}</td>
                            </tr> */}
                            {/*<tr>
                              <th>Brand</th>
                              <td></td>
                            </tr>
                             <tr>
                              <th>Cars</th>
                              <td>
                                <button type="button" className="btn submitBtn">
                                  {viewCar}
                                </button>
                              </td>
                            </tr>

                            <tr>
                              <th>Fuel </th>
                              <td>
                                <button type="button" className="btn submitBtn">
                                  {viewFuel}
                                </button>
                              </td>
                            </tr> */}
                            {location.state.details?.document ? (
                              <tr>
                                <th>Document</th>
                                <td>
                                  <a href={location.state.details?.document}>
                                    <img
                                      src="images/pdf.png"
                                      alt="pdf"
                                      width="50px"
                                      height="50px"
                                    />
                                  </a>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12"></div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div>
                    {/* <ul class="nav nav-pills" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          // href="#home"
                          role="tab"
                        >
                          <span>User History Details</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#profile"
                          role="tab"
                        >
                        </a>
                      </li>
                    </ul> */}

                    {/* <div class="tab-content pt-3">
                      <div class="tab-pane active" id="home" role="tabpanel">
                        <table class="table table-bordered table_fix_width">
                          <tbody>
                           
                      <tr>
                              <th>Brand</th>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Cars</th>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Fuel</th>
                              <td></td>
                            
                            </tr>
                            <tr>
                              <th>Category</th>
                              <td>
                             
                              </td>
                            </tr>
                            <tr>
                              <th>Address 2</th>
                              <td>
                              
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        
                      </div>
                     
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Userview;
