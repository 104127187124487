import React, {useState, useEffect} from "react";
import './messaging.css';
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { MdSend } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";

function Messaging(props) {
	let param = useParams();
	console.log(props,"props")
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
  const [date, setDate] = useState([]);
let senderId = props?.updatedData?.senderId
let receiverId = props?.updatedData?.receiverId
	let location = useLocation();
	console.log(location.state.details?.senderid,"messaging")
	let chatApp = location.state.details.conversation
	let fcmtoken = location.state.details?.senderid?.fcmtoken
	
	console.log(fcmtoken,"fcmtoken")
	function updateUSerData() {
		if(!message){
			return
		}
		var data = JSON.stringify({
		  'sender_id': param.id,
		  'resive_id': receiverId,
		  "fcmToken":fcmtoken,
		  "conversation":{
			"message": message,
			"type":"resiver"
		  } });
		var config = {
		  method: "post",
		  url: `${process.env.REACT_APP_BASEURL}/Support`,
		  headers: {
			Authorization: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		  },
		  data: data,
		};
	
		axios(config)
		  .then(function (response) {
			setMessage("");
			getuserdata();
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  }

useEffect(()=>{
	getuserdata();
},[])
	  function getuserdata() {
		console.log(param,"param")
		var config = {
		  method: "get",
		  url: `${process.env.REACT_APP_BASEURL}/getuserSupport?Sender_id=${param.id}`,
		  headers: {
			Authorization: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		  },
		};
		axios(config)
		  .then(function (response) {
			console.log(response, "666");
			let message = response.data?.data;
			console.log(message,"message")
			
		let finalMessage = message.map((e)=>{
			setName(e?.senderid?.name)
			  let arr = e?.conversation
			  console.log(arr,"sendermsg")
			  return arr;
			})
			setDate(finalMessage);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  }
	  let data = date.map((e)=>{return e});
	  console.log(data,"finalMessage");
	return (
		<>
		 <Header />
      <Sidebar />
		<div className="page-wrapper">
			<div className="container-fluid min_height">
				<div className="card">
					<div className="card-body">
						<h4 className="card-title">Clients
							<div className="float-end btns_head">
								{/* <button className="btn btn-theme btn-sm">Create Groups</button>
							<button className="btn btn-theme btn-sm">Add New Resident</button> */}
							</div>
						</h4>
						<div>
							<section className="chat_box_section">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12 chat_front_user_box d-none">
											<div className="chat_front_user_main">
												<div className="chat_front_user_body">
													<h1 className="text-center mb-4">Welcome, User Name</h1>
													<div className="userprofile_box">
	
														<small><i className="fas fa-circle"></i></small>
													</div>
												</div>
												<div className="chat_front_user_footer text-center">
													<p>You are signed in as testuser@gmail.com</p>
													<p>Try <a href="javascript:void(0);">switching accounts</a> if you do not see your contacts or conversation history. <br /><a href="javascript:void(0);" class="learn_more">Learn More</a></p>
												</div>
											</div>
										</div>
										<div className="col-md-12 chat_content_box">
											<div className="mobile_back d-none">
												<button className="btn chatback_btn"><i className="fas fa-long-arrow-alt-left"></i></button>
											</div>
											<div className="user_box online">
												<div className="user_box_body">
													<h4>{name}</h4>
													<small><i className="fas fa-circle"></i> Online</small>
												</div>
											</div>
											<div className="userchat_body">
                                          {
											date[0]?.map((item)=>{
											  return (	item.type=="sender" ? 
												<div className="chat_message chat_left">

													<p>
														{item.message}
													</p>
												</div> : 
												<div className="chat_message chat_right">
													<p>
														{item.message}
													</p>

												</div> 
											
											)})
										  }											
											</div>
											<div className="chatfooter_submit">
												<div className="input-group user_submit_chat">
													<input type="text" className="form-control" placeholder="Type a message" value={message} onChange={(e) => {
                  setMessage(e.target.value);
                }} />
													<div className="input-group-append">
														<MdSend onClick={updateUSerData}/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>

					</div>
				</div>
			</div>
		</div>
		</>
	);
}

export default Messaging;