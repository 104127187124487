import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import UpdateCity from "./Updatcitys"
import "./city.css";
import ToggleButton from "../../Components/ToggleButton";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";


function City() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [DeleteDeleteId,setDeleteDeleteId] = useState("");
  const[UpdateCityData,setUpdateCityData] = useState("");
  const [Viewuser, setViewuser] = useState([]);
  const [city, setcity] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(getCity);
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [alreadyErr, setAlreadyErr] = useState("");
  const pagination = true;

  const paginationPageSize = 10;
  const rowHeight = 42;

  
  const imageRerander = (props) => {
    console.log(props.data.image,'errrr')
    return (
    //  <img src={props.data.image}/>
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }

  const SrNoRenderer = (props) => {
   
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
          onClick={() => {
            console.log(props.data,"props.data")
            setUpdateCityData(props.data);
            setShow(true);
          }}
        >
          <MdModeEditOutline
            className="ActionIcon viewicon"
          
          />
        </div>
        <div className="ViewIcon"
         onClick={() => {
          console.log(props, "propstyty");
          setViewuser(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UserViewId"
        alt=""
        src="../../images/view.jpg"
        >
          <AiFillEye
           
          />
        </div>
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };
  

  // ------Post API-------//

  function addCity(postdata) {
   setDisabled(true)
    var data = new FormData();
    data.append("city", postdata.city);
    data.append("image", postdata.addimage[0]);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/City`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
      
         if(response.data.status===false){
          setAlreadyErr(response.data.message)
          setDisabled(false)
          setIsOpen(true);
         }else{
          getCity();
          setAlreadyErr("");
          setIsOpen(false);
           reset();
         }
        console.log(response,"747892305749");
        
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getCity();
  }, []);

  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
     
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function CitydeleteData(id) {
    var data = new FormData();
      data.append("citiesId", id);
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
       getCity();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
   return (
    <ToggleButton
    handleToggle={() => {
      changeCityStatus(props?.data?._id);
    }}
    status={props?.data?.status}
  />
		);
  }

  function changeCityStatus(Id) {
   
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/citystatus?id=${Id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        getCity();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      Srno: true,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
      sortable: false,
      filter: false,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      sortable: false,
      filter: false,
      cellRenderer: "imageRender",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      filter: false,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      colId: "params",
      cellRenderer: "childMessageRenderer",
      sortable: true,
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };
function crossForm() {
  setIsOpen(false);
  cross();
}
function cross() {
  reset();
}
function canclefun() {
  setIsOpen(false);
  cancelForm();
}
function cancelForm(){
  reset();
}

function box() {
  setShow(false);
  cross();
}
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                City Details
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                      setAlreadyErr("")
                    }}
                  >
                    Add New City
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add City
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addCity)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    City Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("city", {
                                        onChange: () => setAlreadyErr(""),
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.city?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.city?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">image<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      {...register("addimage",{required:true})}
                                      className="form-control"
                                      // onChange={(e) => {
                                      //   setimage(e.target.files[0]);
                                      // }}
                                     
                                    />
                                    {errors?.addimage?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <p style={{color:"red"}}>{alreadyErr}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="modal-footer">
                              <button
                              onClick={() => {
                                canclefun()
                                setAlreadyErr("")
                                }}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} onHide>
                  <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateCity
                      updatedData={UpdateCityData}
                      onEditDataFunction={getCity}
                      box={box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this City?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                CitydeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        City Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> City : {Viewuser.city}</h6>
                                  </div>
                                  <div>
                                    <img
                                      src={Viewuser.image}
                                      width="70px"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default City;
