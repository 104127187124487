import React, { useState, useEffect, useRef} from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdataSubCategory from "./UpdataSubCategory";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// import Modal from "react-bootstrap/Modal";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "../../Components/ToggleButton";




// console.log(rowData,"rowData")

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};
 const MoodRenderer = (props) => {
  console.log(props.data?.parentsid?.title,"category")
    return (
      <span className="profle_img_box">
        <stong>{props.data?.parentsid?.title}</stong>
      </span>
    );
  };

function Subcategory() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateSubCategoryData, setUpdateSubCategoryData] = useState({});
  const [viewCategory, setViewCategory] = useState([]);
  const [title, setTitle] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [rowData, setRowData] = useState(subCategoryGet);
  const [catId, setCaeId] = useState([]);
  const [CatSelect, setcatChang] = useState([]);
  const [Plan, setPlan] = useState([]);
  const [PlanId, setPlanId] = useState([]);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [alreadyErr, setAlreadyErr] = useState("");
console.log(PlanId, "PlanId");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);

  const pagination = true;
  //   const paginationPageSize = 10;
  //   const rowHeight = 55;

  let history = useHistory();

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            console.log(props, "99999");
            setUpdateSubCategoryData(props.data);
            setShow(true)
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>
        <div
          className="ViewIcon"
          onClick={() => {
            history.push({
              pathname: "SubCategoryView",
              state: { details: props.data },
            });
          }}
        >
          <AiFillEye />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCarCategoryData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function box() {
    setShow(false);
    cross();
  }

  // ------Post API-------//

  function addSubCategory(postdata) {
    if(catId==""||null){
      setErr("This field is required")
    }else{
      setDisabled(true)
      var data = JSON.stringify({
        title: postdata.title,
        parentsid: catId,
        discreption: postdata.discreption,
        plan_id: PlanId,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/add-sub-category`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          console.log(response, "9999");
          if(response.data.status===false){
            setAlreadyErr(response.data.msg)
            setDisabled(false)
            setIsOpen(true);
           }else{
            subCategoryGet();
          setIsOpen(false);
          setAlreadyErr("")
          reset();
          setCaeId("");
           }
         
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cross() {
    reset();
  }

  useEffect(() => {
    subCategoryGet();
    getCategory();
    getPlanService();
  }, []);

  function subCategoryGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/get-sub-category`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "subcategory");
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCategory() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "catget");
        let arrCat = [];
        response.data.getdata.map((catName) => {
          arrCat.push({ label: catName.title, value: catName._id });
        });

        setcatChang(arrCat);
        // setCategorySelect(response.data.getdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCategory = (e) => {
    console.log(e.value, "element");
    setCaeId(e.value);
    setErr("");
  };

  //---PLAN GET API--//

  function getPlanService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCommonService`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "plan");
        let planarr = [];
        response.data.data.map((item) => {
          planarr.push({ label: item.planName, value: item._id });
        });
        console.log(planarr, "planarr");
        setPlan(planarr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangePlan = (e) => {
    let selsctArrChange = [];
    e.forEach((item) => {
      selsctArrChange.push(item.value);
    });
    setPlanId(selsctArrChange);
  };

  function subCategorydeleteData(id) {
    var data = JSON.stringify({
      categoryid: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteSubCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        subCategoryGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const editdataReloadFunc = () => {
    subCategoryGet();
  };

  const StatusRenderer = (props) => {
    console.log(props, "fghijok");
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeCategoryStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  };

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "title",
      headerName: "Sub Category",
      width: 160,
      sortable: false,
      filter: false,
    },
    {
      field: "parentsid",
      headerName: "Category",
      width: 160,
      sortable: false,
      filter: false,
      cellRenderer: "moodRenderer",
    },
    {
      field: "discreption",
      headerName: "Description",
      width: 140,
      sortable: false,
      filter: false,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 200,
    //   sortable: false,
    //   filter: false,
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 220,
      colId: "params",
      sortable: true,
      filter: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };


  function changeCategoryStatus() {}
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Sub Category
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                      setAlreadyErr("")
                    }}
                  >
                    Add New Subcategory
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Sub Category
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addSubCategory)}>
                            <div className="modal-body">
                              <div className="row">
                              <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Category<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <Select
                                      // isMulti
                                      options={CatSelect}
                                      onChange={(e) => handleChangeCategory(e)}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                    <span style={{color:"red"}}>{err}</span>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Sub Category Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      {...register("title", {
                                        onChange: () => setAlreadyErr(""),
                                        required: true, 
                                        min:1

                                      })}
                                    />
                                    {errors?.title?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.title?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                               
                                {/* <div className="col-md-12 mb-3">
                              <label className="form-label">Select Plan</label>
                              <div className="position-relative">
                                <Select
                                  isMulti
                                  options={Plan}
                                  onChange={(e) => handleChangePlan(e)}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div> */}

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Description<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <textarea
                                type="text"
                                className="form-control"
                                value={discreption}
                                onChange={(e) => {
                                  setDiscreption(e.target.value);
                                 }}
                              /> */}

                                    <textarea
                                      className="form-control p-2"
                                      {...register("discreption", {
                                        required: true, min:1
                                      })}
                                    />
                                    {errors?.discreption?.type ===
                                      "required" && (
                                      <p>This field is required</p>
                                    )}
                                      {errors?.discreption?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <p style={{color:"red"}}>{alreadyErr}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                                onClick={() => {canclefun()
                                  setAlreadyErr("")
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                // onClick={addSubCategory}
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdataSubCategory
                      updatedData={UpdateSubCategoryData}
                      onEditDataFunction={subCategoryGet}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteCarCategoryData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this car category?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                subCategorydeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Users Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Sub Category : {viewCategory.title}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> Category image : {ViewFuel.image}</h6> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  //  rowData={rowData}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    moodRenderer:MoodRenderer,
                    // imageRender:ImageRender
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subcategory;
