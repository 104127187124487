

import React, {useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { useHistory } from "react-router-dom";
// import UpdateInspectionCat from "./UpdateInspection";
import UpdateJobCard from "./UpdateJobCard";
import ToggleButton from "../../Components/ToggleButton";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";



// console.log(rowData,"rowData")

  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };


function JobCard() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
const [text, setText] =useState("")
const [rowData, setRowData] = useState(JobCardGet);
const [isOpen, setIsOpen] = useState(false);
const [DeleteDeleteId,setDeleteDeleteId] = useState("")
const [show, setShow] = useState(false);
const [inspectionCategoryData, setInspectionCategoryData] = useState({});
const [disabled, setDisabled] = useState(false);


  const pagination = true;
 const paginationPageSize = 10;
const rowHeight = 55;

let history = useHistory();

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
        onClick={() => {
          console.log(props,"99999")
          setInspectionCategoryData(props.data);
          setShow(true)
        }}
        >
          <MdModeEditOutline
        
            className="ActionIcon viewicon"
            
          />
        </div>
        {/* <div className="ViewIcon"
           onClick={() => {
            history.push({
              pathname: "SubCategoryView",
              state: { details: props.data},
            });
          }}
        >
          <AiFillEye
          
          />
        </div> */}
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteCarCategoryData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };

  function box() {
    setShow(false);
    cross();
  }

  // ------Post API-------//

  function addJobCard(postdata) {

    // var data = new FormData();
  
    // data.append("subcategory", subcategory);
    // data.append("image", image);
    setDisabled(true)
    var data = JSON.stringify({
      "text": postdata.text,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/jobcardController`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response,"9999")
         JobCardGet();
         setIsOpen(false);
         reset();
        setText("");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }
  function cross() {
    reset();
  }
 function canclefun() {
   setIsOpen(false);
   cancelForm();
 }

 function cancelForm() {
   reset();
 }

  useEffect(() => {
    JobCardGet();
  }, []);

  function JobCardGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getjobcard`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {   
          console.log(response,"getjobcard")     
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function JobCardDeleteData(id) {
    var data = JSON.stringify({
      jobcardid: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletejobcard`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        JobCardGet();      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const StatusRenderer = (props) => {
    console.log(props,"props")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeJobcardStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  };

  
  function changeJobcardStatus(jobId) {
    var data = JSON.stringify({
      jobcardid: jobId,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/jobcardStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "98989")
        JobCardGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function changeCategoryStatus(){}

   const [gridApi, setGridApi] = useState(null);
   const [gridColumnApi, setGridColumnApi] = useState(null);

   const [columnDefs, setColumnDefs] = useState([
     // simple column, easy to understand
     {
       field: "SrNo",
       headerName: "Serial No.",
       Srno: true,
       width: 90,
       sortable: false,
       filter: false,
       cellRenderer: "srNoRenderer",
     },
     {
       field: "text",
       headerName: "Parts",
       width: 250,
       Srno: true,
       sortable: false,
       filter: false,
     },
     {
       field: "status",
       headerName: "Status",
       width: 250,
       Srno: true,
       sortable: false,
       filter: false,
       cellRenderer: "statusRenderer",
     },
     {
       field: "Action",
       headerName: "Action",
       width: 320,
       colId: "params",
       sortable: true,
       filter: true,
       cellRenderer: "childMessageRenderer",
     },
     // the grid works with embedded fields
   ]);

   function onGridReady(params) {
     setGridApi(params.api);
     setGridColumnApi(params.columnApi);
   }

   const onFilterTextChange = (e) => {
     console.log(e.target.value);
     gridApi.setQuickFilter(e.target.value);
   };
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Job Card
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                   // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => { 
                      setIsOpen(true)
                      setDisabled(false)
                    }}
                  >
                    Add New Job Card
                  </button>
                </div>
              </h4>
              <div>
              <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Job Card
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => crossForm()}
                          ></button>
                        </div>
                        <form
                          onSubmit={handleSubmit(addJobCard)}
                        >
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Name<span style={{color:"red"}}>*</span></label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                // value={text}
                                // onChange={(e) => {
                                //   setText(e.target.value);
                                // }}
                                {...register("text", {
                                  required: true,
                                  min:1
                                })}
                              />
                              {errors?.text?.type ===
                                "required" && (
                                <p>This field is required</p>
                              )}
                              {errors?.text?.type === "min" && (
                                <p>Minimum One Letter Required</p>
                              )}
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                         onClick={() => canclefun()}
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          // onClick={addJobCard}
                          type="submit"
                          className="btn submitBtn"
                          disabled = {disabled ? true : false}
                        >
                          Submit
                        </button>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
                </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateJobCard
                      updatedData={inspectionCategoryData}
                      onEditDataFunction={JobCardGet}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteCarCategoryData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Job Card?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                JobCardDeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Users Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    {/* <h6> Sub Category  : {viewCategory.title}</h6> */}
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> Category image : {ViewFuel.image}</h6> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                   paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    // imageRender:ImageRender
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobCard;


