import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Updateusers from "./UpdateUser";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "../../Components/ToggleButton";
import Select from "react-select";
// ---INDEX NUMBER SHOW IN TABLE FROM SRNORENDERER --//

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

// ---INDEX NUMBER SHOW IN TABLE FROM IMAGERANDER --//

const ImageRander = (props) => {
  console.log(props, "data");

  return (
    <span className="profle_img_box">
      <img className="profile_img_table" src={props.data?.image} />
    </span>
  );
};

const MoodRenderer = (props) => {
  console.log(props, "iam12333iam12333");
  return (
    <>
      <span>{props.data?.city?.city}</span>
    </>
  );
};

// ---INDEX NUMBER SHOW IN TABLE FROM STATUSRENDERER --//

// ---COMPONENT NAME --//

function User() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateStudentData, setUpdateCategoriesData] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [address,setAddress]=useState("");
  const [pincode, setPinCode] = useState("");
  const [image, setimage] = useState("");
  const [state, setState] = useState("");
  const [date, setDate] = useState("");
  const [rowData, setRowData] = useState(getuserdata);

  const [fcmNotification, setFcmNotification] = useState([]);

  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [id,setId] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({mode:'onChange'});

  const {
    register: notify,
    handleSubmit: notifySubmit,
    formState: { errors: notifyError },
    reset: notifyReset,
  } = useForm({mode:'onChange'});

  // console.log(search,"search")
  const gridRef = useRef();
  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 42;

  let history = useHistory();
  let viewData = [];

  // ------ADD USER API-------//

  function addusers(postdata) {
    setDisabled(true);
    var data = new FormData();
    data.append("type", "customer");
    data.append("name", postdata.name);
    data.append("email", postdata.email);
    data.append("mobile", "91" + postdata.mobile);
    data.append("city", city);
    data.append("date", postdata.date);
    data.append("State", postdata.state);
    data.append("PinCode", postdata.pincode);
    data.append("image", postdata.image[0]);
    data.append("document", image);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/user`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response,"aakash")
        getuserdata();
        setIsOpen(false);
        reset();
        resetForm();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function func() {
    setOpen(false);
    notifyReset();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cancel() {
    setOpen(false);
    cross();
  }

  function box() {
    setShow(false);
    cross();
  }

  function cross() {
    notifyReset();
  }
  const resetForm = () => {
    setEmail("");

    setimage("");
  };
  useEffect(() => {
    getuserdata();
    getCity();
  }, []);

  // ------POST Notification API-------//

  function notification(postdata) {
   console.log("sdahj")
    var data = JSON.stringify({
      "title": postdata.notificationTitle,
      "Discription":postdata.notificationDescription,
      "fcmToken":fcmNotification,
 
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Notification`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
        
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getuserdata();  
        notifyReset();
        setOpen(false);     
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------GET USER API-------//

  function getuserdata() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getuser`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "777");
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ USER VIEW API-------//

  function getviewUser(ids) {
   
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/viewUser?userid=${ids}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "view");
        for (var x in response.data.data) {
          viewData.push(response.data.data[x]);
        }
        console.log(viewData,"sdasd")
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ Delete API -------//

  function userdeleteData(index) {
    var data = JSON.stringify({
      userId: index,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteuser`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getuserdata();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ------ ACTION SHOW IN TABLE -------//

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            console.log(props.data, "uuuuuu");
            setUpdateCategoriesData(props.data);
            setShow(true)
          }}
          
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>
        <div
          className="ViewIcon"
          onClick={() => {
            console.log(viewData, "viewData");
            getviewUser(props.data._id);
            history.push({
              pathname:`userView`,
              state: { details: props.data, detailsone: viewData },
            });
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const StatusRenderer = (props) => {
    return (
      <>
          <ToggleButton
            handleToggle={() => {
              changeStudentStatus(props?.data?._id);
            }}
            status={props?.data?.status}
          />
      </>
    );
  };

  function changeStudentStatus(userId) {
    var data = JSON.stringify({
      userid: userId,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/UserStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getuserdata();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleSearch = (e) => {
    console.log(e, "e");
    setSearch(e.target.value);
  };


  const getSelectedRowData = (e) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let fcmUser = [];
    let selectedData = selectedNodes.map(node => {
      console.log(node?.data?.fcmtoken,"node")
      fcmUser.push(node.data?.fcmtoken);
      setFcmNotification(fcmUser)
    });
    // alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
    setOpen(true)
    console.log(fcmUser,"fcmUser")
    return selectedData;
  };
  console.log(fcmNotification,"fcmNotification")
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      sortable: false,
      cellRenderer: "srNoRenderer",
      checkboxSelection: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      sortable: true,
      ref: gridRef,
      cacheQuickFilter: true,
    },
    { 
      field: "mobile",
     headerName: "Mobile",
      sortable: false, 
      width: 180
     },

   
     
    { 
      field: "city", 
    headerName: "City", 
    width: 200,
     sortable: false,
     cellRenderer: "MoodRenderer",
      getQuickFilterText: (params) => {
        return params.data?.second?.citys?.city;
      },
     },
     
    {
      field: "StatusActive",
      headerName: "Status",
      sortable: false,
      width: 140,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      sortable: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
   
  ]);
 

  function onGridReady(params){
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    
  }

  const onFilterTextChange = (e) =>{
console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  }


  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === 'string') {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
  
    return (valueA > valueB? 1 : (valueA < valueB ? -1 : 0));
  };

  const [cityData, setCityData] = useState([])

  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
        let cityArr = []
        response.data.data?.map((item)=>{
          if(item.status === true){
          cityArr.push({
            label: item?.city,
            value:item?._id
          })
        }
        })
        setCityData(cityArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCity=(e)=>{
    console.log(e,"eeeeeee")
    setCity(e.value)
  }

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Users
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                    setDisabled(false)
                    }}
                  >
                    Add New User
                  </button>
                  <button 
                   onClick={(e) => {
                    getSelectedRowData(e)
                  }}
                   className="btn btn-theme btn-sm"
                    >Send Notifications</button>
                </div>
              </h4>
                <Modal show={isOpen} hide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add User
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addusers)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    User Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              /> */}
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("name", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.name?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.name?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    User Email
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      // value={email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                      {...register("email", {
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />
                                    {errors?.email?.type === "pattern" && (
                                      <p>invalid email address</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    User Mobile No<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              /> */}
                                    {/* <input  className="form-control" type="number"
                                 {...register("mobile",{minLength:10,maxLength:10})} />
                                  {errors?.mobile?.type === "minLength" && (<p>Only 10 digit number</p>)}
                                  {errors?.mobile?.type === "maxLength" && (<p>Only 10 digit number</p>)} */}

                                    <input
                                      className="form-control"
                                      type="number"
                                      placeholder="+91"
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                      {...register("mobile", {
                                         required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: /^[1-9]\d{9}$/i,
                                      })}
                                    />
                                    {errors?.mobile?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.mobile?.type === "pattern" && (
                                      <p>Invalid Number or First Number can't be 0</p>
                                    )}
                                    {errors?.mobile?.type === "maxLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                    {errors?.mobile?.type === "minLength" && (
                                      <p>Only 10 digit number</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label"> DOB<span style={{color:"red"}}>*</span></label>
                                  {/* <input
                              value={date}
                              type="Date"
                              className="form-control"
                              onChange={(e) => {
                                setDate(e.target.value);
                              }}
                            />{" "} */}
                                  <input
                                    className="form-control"
                                    type="date"
                                    {...register("date", { required: false })}
                                  />
                                  {errors?.date?.type === "required" && (
                                    <p>This field is required</p>
                                  )}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Street<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                }}
                              /> */}
                                    <input
                                      className="form-control"
                                     
                                      {...register("street", {
                                        // required: true,
                                        pattern: /^[0-9A-Za-z ]+$/i,
                                        min:1
                                      })}
                                    />
                                    {errors?.Street?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.Street?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">City<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                     {/* <input
                                type="text"
                                className="form-control"
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                }}
                              /> */}
                              <Select
                          // isMulti
                          options={cityData}
                          onChange={(e) => handleChangeCity(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                                    
                                  </div>

                                </div> 
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">State<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                     {/* <input
                                type="text"
                                className="form-control"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value);
                                }}
                              />  */}

                                    <input
                                      className="form-control"
                                      {...register("state", {
                                        //required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.state?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.state?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Pin Code<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                     {/* <input
                                type="text"
                                className="form-control"
                                value={pincode}
                                onChange={(e) => {
                                  setPinCode(e.target.value);
                                }}
                              />  */}

                                    <input
                                      type="number"
                                      className="form-control"
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                      {...register("pincode", {
                                       // required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                      })}
                                    />
                                    {errors?.pincode?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.pincode?.type === "maxLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                    {errors?.pincode?.type === "minLength" && (
                                      <p>Only 6 digit number</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                   Upload Documents
                                  </label>
                                  <div className="position-relative">
                                    <div>
                                    <input
                                        type="file"
                                        name="upload" 
                                        accept=".xlsx,.xls, .doc, .docx,.pdf"
                                        onChange={(e) =>
                                          setimage(e.target.files[0])
                                        }
                                        className="form-control"
                                   
                                      />

                                    </div>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    User Image<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <div>
                                    <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        {...register("image",{required:false})}
                                        className="form-control"
                                   
                                      />
                                       {errors?.image?.type === "required" && (
                                          <p>This field is required</p>
                                        )}
                                    </div>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  onClick={() => canclefun()}
                                  type="button"
                                  className="btn btn-danger CancelBtn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  // onClick={addusers}

                                  type="submit"
                                  className="btn submitBtn"
                                  disabled = {disabled ? true : false}
                                  // data-bs-dismiss="modal"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
               </Modal.Body>
                </Modal>

      



                <Modal show={open} hide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Notification
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => cancel()}
                            ></button>
                          </div>
                          <form onSubmit={notifySubmit(notification)}>
                            <div className="modal-body">
                              <div className="row">

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Title</label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                }}
                              /> */}
                                    <input
                                      className="form-control"
                                      {...notify("notificationTitle", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {notifyError?.notificationTitle?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {notifyError?.notificationTitle?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Description</label>
                                  <div className="position-relative">
                                    {/* <input
                                type="text"
                                className="form-control"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value);
                                }}
                              /> */}

                                    <textarea
                                      className="form-control"
                                      {...notify("notificationDescription", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {notifyError?.notificationDescription?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {notifyError?.notificationDescription?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  onClick={() => func()}
                                  type="button"
                                  className="btn btn-danger CancelBtn"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                
              <Modal show={show} hide>
                  <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <Updateusers
                      updatedData={UpdateStudentData}
                      onEditDataFunction={getuserdata}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this User?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                userdeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable CityUserTable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  rowSelection={'multiple'}
                  paginationPageSize={paginationPageSize}
                  suppressRowClickSelection={true}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    imageRander: ImageRander,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    MoodRenderer: MoodRenderer,

                    // statusRenderer: StatusRenderer,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
