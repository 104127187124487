import React, { useState ,useRef} from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const UpdateScout = (props) => {
    
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobileNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const[street, setStreet] = useState("");
  const [pincode, setPincode] = useState("");
  const [disImage, setDisImage] = useState("");
  const [image, setImage] = useState("");
  const [dispdf, setDispdf] = useState("");
  const [pdf, setpdf] = useState("");

  const [date, setDate] = useState("");
  const form = useRef(null);
  let updatedpdf = props.updatedData?.document;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });

  const Iconpdf = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDispdf(display);
      setpdf(event.target.files[0]);
    }
  }
    const renderpdf = (image) => {
      return (
        <img src="images/pdf.png" alt="pdfIcon" style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
        }}/> 
      );
    };

  function updateScoutData(postdata) {
   
    var data = new FormData();
    data.append("userid", props.updatedData?._id);
    data.append(
      "name",  postdata.name === "" || null ? props.updatedData?.name  :  postdata.name);
      data.append(
        "email", postdata.email === "" || null ? props.updatedData?.email  : postdata.email);
        data.append(
          "mobile", postdata.mobile === "" || null ? props.updatedData?.mobile  : "91"+postdata.mobile);
          data.append("house", postdata.house === "" || null ? props.updatedData?.House : postdata.house);
          data.append("Street", postdata.street === "" || null ? props.updatedData?.Street : postdata.street);
    data.append("State", postdata.state === "" || null ? props.updatedData?.State : postdata.state);
    data.append("PinCode", postdata.pincode === "" || null ? props.updatedData?.PinCode : postdata.pincode);
    data.append("adharNo", postdata.adharNo === "" || null ? props.updatedData?.PinCode : postdata.adharNo);      
    data.append(
      "image",
      image === "" || null ? props.updatedData?.image : image
    );
    data.append(
      "document",
      pdf === "" || null ? props.updatedData?.document : pdf
    );

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/UpdateScout`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        props.box();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  const handleclick = () => {
    form.current.reset(); //this will reset all the inputs in the form
  }
  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Scout
      </h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={()=>{props.box()}}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateScoutData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Name<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.name}
              className="form-control"
              {...register("name", {
                required: true,
                min:1
              })}
            />
            {errors?.name?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            {errors?.name?.type === "required" && (
              <p>This field is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Email Address<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.email}
              className="form-control"
              {...register("email", {
                required: true,
                pattern:
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors?.email?.type === "pattern" && (
              <p>Invalid email address</p>
            )}
            {errors?.email?.type === "required" && (
              <p>This field is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Mobile no<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="number"
              defaultValue={props.updatedData?.mobile}
              className="form-control"
              min={1}
              onWheel={(e) => e.target.blur()}
              {...register("mobile", {
                required: true,
                minLength: 10,
                pattern: /^[1-9]\d{9}$/i,
              })}
            />
            {errors?.mobile?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.mobile?.type === "pattern" && (
            <p>Invalid Number or First Number can't be 0</p>
             )}
            {errors?.mobile?.type === "minLength" && (
              <p>Only 10 digit number</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit House No. and Floor<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.House}
              className="form-control"
              {...register("house", {
                required: true,
                pattern: /^[0-9A-Za-z ]+$/i,
                min:1
              })}
            />
            {errors?.house?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.house?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Street<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.Street}
              className="form-control"
              {...register("street", {
                required: true,
                pattern: /^[0-9A-Za-z ]+$/i,
                min:1
              })}
            />
            {errors?.street?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.street?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit City<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.State}
              className="form-control"
              {...register("state", {
                required: true,
                min:1
              })}
            />
            {errors?.state?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
            {errors?.state?.type === "required" && (
              <p>This field is required</p>
            )}{" "}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Pin Code<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="number"
              defaultValue={props.updatedData?.PinCode}
              className="form-control"
              min={1}
              onWheel={(e) => e.target.blur()}
               {...register("pincode", {
                 required: true,
                 minLength: 6,
                 maxLength: 6,
               })}
             />
             {errors?.pincode?.type === "required" && (
               <p>This field is required</p>
             )}
             {errors?.pincode?.type === "maxLength" && (
               <p>Only 6 digit number</p>
             )}
             {errors?.pincode?.type === "minLength" && (
               <p>Only 6 digit number</p>
             )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
        <label className="form-label">
             Scout Aadhar Card
           </label>
           <div className="position-relative">
            <input
            defaultValue={props.updatedData?.adharNo}
               className="form-control"
              type="text"
               min={1}    
               {...register("adharNo", {
                minLength: 12,
                maxLength: 12,
                pattern: /^[0-9]{12}$/i,
              })}
            />
            {errors?.adharNo?.type === "pattern" && (
              <p>Invalid Character</p>
            )}
            {errors?.adharNo?.type === "minLength" && (
              <p>Only 12 digit number</p>
            )}
            {errors?.adharNo?.type === "maxLength" && (
              <p>Only 12 digit number</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit State<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.State}
              className="form-control"
              onChange={(e) => {
                setState(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit City </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.city}
              className="form-control"
              onChange={(e) => {
                setCity(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit PinCode </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.PinCode}
              className="form-control"
              onChange={(e) => {
                setPinCode(e.target.value)
              }}
            />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div> */}

      <div className="row">
      <div className="col-md-12 mb-3">
        <label className="form-label d-block">Edit Upload Documents</label>
        <input type="file"  name="upload" className="form-control" accept=".xlsx,.xls, .doc, .docx,.pdf" onChange={Iconpdf} />
        {!dispdf ? (
          updatedpdf ? 
          <a href={updatedpdf} target="_blank" rel="noreferrer"> <img src="images/pdf.png" alt="pdfIcon" style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
          }}/> </a>
         : "" ) 
        : (
          renderpdf(dispdf)
        )}
      </div>
    </div>
  
      <div className="row">
      <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  props.updatedData?.image ?
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  /> : ""
                ) : (
                  renderImages(disImage)
                )}
          </div>
        </div>
     
    </div>
 
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>{props.box()}}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
    </form>
  </>)
}

export default UpdateScout;