import React, { useState } from "react";
import axios from "axios";
import "./blog.css";
import Footer from "../Footer/footer";
import { useForm } from "react-hook-form";

const UpdateBlog = (props) => {
    
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [disImage, setDisImage] = useState("");
  const [SubDiscreption,setSubDiscreption] =useState("")

  const {register,handleSubmit,formState:{errors},reset} =useForm();
  
  function updateBlogData(postdata) {
    console.log(props.updatedData,"123456789")
    var data = new FormData();
    data.append("id", props.updatedData?._id);
    data.append(
      "blogsheading", postdata.title === "" || null ? props.updatedData?.blogsheading  : postdata.title);

      data.append(
        "SubDiscreption", postdata.subdiscreption === "" || null ? props.updatedData?.SubDiscreption  : postdata.subdiscreption);
     
     data.append(
        "Discreption", postdata.discreption === "" || null ? props.updatedData?.Discreption  : postdata.discreption);
      data.append(
        "image",
        image === "" || null ? props.updatedData?.image : image
      );
      data.append("createDate", null);
  
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editBlogs`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };
  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Blog
      </h5>
      <button
        type="button"
        className="btn-close"
        onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateBlogData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Blog<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.blogsheading}
              className="form-control"
              {...register("title", {
                required: true,
                pattern: /^[0-9A-Za-z ]+$/i,
                min:1
              })}
            />
            {errors?.title?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.title?.type === "pattern" && (
              <p>Invalid Character</p>
            )}
            {errors?.title?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}

            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Blog Content<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <textarea
              defaultValue={props.updatedData?.SubDiscreption}
              className="form-control"
              style={{height:"100px"}}
              {...register("subdiscreption", {
                required: true,
                min:1
              })}
            />
            {errors?.subdiscreption?.type ===
              "required" && (
              <p>This field is required</p>
            )}
            {errors?.subdiscreption?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Blog Description<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <textarea
              defaultValue={props.updatedData?.Discreption}
              className="form-control"
              style={{height:"100px"}}
              {...register("discreption", {
                required: true,
                min:1
              })}
            />
            {errors?.discreption?.type ===
              "required" && (
              <p>This field is required</p>
            )}
            {errors?.discreption?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
       

        <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  />
                ) : (
                  renderImages(disImage)
                )}
          </div>
      </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
    </form>
  </>)
}

export default UpdateBlog;