import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";

const UpdateWorkshop = (props) => {
   console.log(props.updatedData,"data")
   const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  let cityarr = []
  if (props.updatedData?.city) {
    const cityNames = props.updatedData?.city;
    console.log(cityNames, "cityNames");
    cityarr?.push(cityNames);
  }
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setmobileNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const[street, setStreet] = useState("");
  const [pincode, setPincode] = useState("");
  const [disImage, setDisImage] = useState("");
  const [image, setImage] = useState("");
  const [cityId, setCityId] = useState("")
  const [type, setType] = useState("")

  const workshopType = [
    { value: "Multi-Brand", label: "Multi-Brand" },
    { value: "Company-Owned", label: "Company-Owned" },
  ];
  const [date, setDate] = useState("");

let multiType = [{value:0 , label:"Multi-Brand"},{value:1,label:"Company-Owned"}];

  
  function updateWorkshopData(postdata) {
    var data = new FormData();
    data.append(
      "name", postdata.name === "" || null ? props.updatedData?.name  : postdata.name);
      data.append(
        "Address", postdata.address === "" || null ? props.updatedData?.address  : postdata.address);
        data.append(
          "type", type === "" || null ? props.updatedData?.type  : type);
          data.append("city", cityId.toString() === "" || null
          ? cityarr?.map((item) => {
              return item[0];
            })
          : cityId
      );
    data.append(
      "image",
      image === "" || null ? props.updatedData?.image : image
    );

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editWorkshop?id=${props.updatedData?._id}`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        props.onBox()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };


  useEffect(()=>{
    getCity();
  },[])

  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
        let city = response.data.data;
        console.log(city,"cities");
        let arrCity = [];
        response.data.data.map((catName) => {
          arrCity.push({ label: catName.city, value: catName._id });
        });
        setCity(arrCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCity = (e) => {
    console.log(e.value, "element");
    setCityId(e.value);
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Workshop
      </h5>
      <button
        type="button"
        className="btn-close"
       onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateWorkshopData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Name<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.name}
              className="form-control"
              {...register("name", {
                required: true,
                min:1
              })}
            />
            {errors?.name?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            {errors?.name?.type === "required" && (
              <p>This field is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Address<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.Address}
              className="form-control"
              {...register("address", {
                required: true,
                min:1
              })}
            />
            {errors?.address?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.address?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit City<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
          <Select
                      defaultValue={cityarr?.map((item) => {
                        console.log(item, "565");
                        return { value: item._id, label: item.city };
                      })}
                      key={cityarr.map((item) => {
                        return item[1];
                      })}
                      options={city}
                      onChange={(e) => {
                        handleChangeCity(e);
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-12 mb-3">
                        <label className="form-label">Edit Type<span style={{color:"red"}}>*</span></label>
                        <Select
                          // isMulti
                          defaultValue={multiType.map(item => {
                           if(props.updatedData?.type == "Multi-Brand"&& item.label == "Multi-Brand")
                           {
                            return item;
                           }
                           else if(props.updatedData?.type == "Company-Owned"&& item.label == "Company-Owned"){
                            return item;
                           }
                          })}
                          key={multiType.map(item => {
                            if(props.updatedData?.type == "Multi-Brand"&& item.label == "Multi-Brand")
                            {
                             return item.value;
                            }
                            else if(props.updatedData?.type == "Company-Owned"&& item.label == "Company-Owned"){
                             return item.value;
                            }
                           })}
                          options={multiType}
                          className="basic-multi-select"
                          onChange={(e)=>{
                            setType(e.label)
                          }}
                          classNamePrefix="select"
                        />
                    </div>
      </div>
      
      <div className="row">
      <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  props.updatedData?.image ?
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  /> : ""
                ) : (
                  renderImages(disImage)
                )}
          </div>
        </div>
     
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
</form>
  </>)
}

export default UpdateWorkshop;