import React, { useMemo, useState, useRef, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./UserRoleManagement.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Select from "react-select";
import Sidebar from "../Sidebar/sidebar";
import UpdateUserRoleManagement from "./UpdateUserRoleManagement";
import Footer from "../Footer/footer";
import { AiFillEye } from "react-icons/ai";
import ToggleButton from "../../Components/ToggleButton";
import ModalP from "../../Components/modal/ModalP";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";

import { modules } from './Roles'

function UserRoleManagement() {

    const [show, setShow] = useState(false);
    // const childRef = useRef();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [refresh, doRefresh] = useState(0);
    const [addButtonStatus, setAddButtonStatus] = useState(null);
    const [UpdateStudentData, setUpdateCategoriesData] = useState({});
    const [DeleteDeleteId, setDeleteDeleteId] = useState("");
    const [Viewuser, setViewuser] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [data, setdata] = useState(modules);
    const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
    // const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);
    const [pvisibility, setpvisibility] = useState(false)
    const [cpvisibility, setcpvisibility] = useState(false)
    const pagination = true;
    const paginationPageSize = 100;
    const rowHeight = 42;
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({ mode: "onBlur" });

    const [isOpen, setIsOpen] = useState(false);

    const MoodRenderer = (props) => {
        return (
            <span className="profle_img_box">
                <stong>{props.data}</stong>
            </span>
        );
    };

    function somefun() {
        setAddButtonStatus(false)
        setRoleErrorStatus(false)
        setIsEmailExist(false)
        setIsOpen(false);
        cancelForm();
    }

    function fun() {
        setAddButtonStatus(false)

        setRoleErrorStatus(false)
        setIsEmailExist(false)
        setIsOpen(false);
        cross();
    }

    function cancelForm() {
        reset();
    }

    function cross() {
        reset();
    }

    const SrNoRenderer = (props) => {
        return (
            <>
                <span>{props.node.rowIndex + 1}</span>
            </>
        );
    };

    const RolesRenderer = (props) => {
        return (
            <>
                {props.data?.type === 'master admin' && props.data?.roles?.length === 0 && <span> Access of All Modules</span>}
                {props.data?.roles?.length > 0 && <span> {props.data?.roles.map(item => {
                    if (item.includes('_')) {
                        const newItem = item.split("_").join(" ");
                        return newItem;
                    }
                    return item;
                }).join(", ")}</span>}
            </>
        );
    };

    const srnoValueGetter = (params) => {
        return (params.node.rowIndex + 1)
    }

    const ChildMessageRenderer = (props) => {
        return (
            <div className="iconActionList">
                <div
                    className="editIcon"
                    onClick={() => {
                        handleShow();
                        doRefresh(0)
                        setUpdateCategoriesData(props.data);
                    }}
                >
                    <MdModeEditOutline className="ActionIcon viewicon" />
                </div>

                <div
                    className="ViewIcon"
                    onClick={() => {
                        setViewuser(props.data);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#UserViewId"
                >
                    <AiFillEye alt="" src="../../images/view.jpg" />
                </div>
            </div>
        );
    };

    const editdataReloadFunc = () => {
        getSubadmins();
    };

    // ------Post API-------//
    const [roleErrorStatus, setRoleErrorStatus] = useState(null)
    const [isEmailExist, setIsEmailExist] = useState(null);
    const [roleErrorMessage, setRoleErrorMessage] = useState(null)
    function addNewRole(postdata) {

        if (selectedOptiongroup.length === 0) {
            setAddButtonStatus(true)
            setRoleErrorStatus(true)
            return;
        }
        setRoleErrorStatus(false)


        var data = JSON.stringify({
            username: postdata.name,
            email: postdata.email,
            roles: selectedOptiongroup.join(","),
            password: postdata.businessconfirmPassword
        });

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/AddAdmin`,
            headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

                if (response.data.status) {
                    reset();
                    setIsEmailExist(false)
                    setIsOpen(false);
                    getSubadmins();
                } else {
                    setIsOpen(true);
                    setIsEmailExist(true);
                    setRoleErrorMessage(response.data.message)
                }
            })
            .catch(function (error) {
                setAddButtonStatus(false);
                console.log(error);
            });
        setAddButtonStatus(false)
    }

    useEffect(() => {
        getSubadmins();
    }, []);

    useEffect(() => {
        if (selectedOptiongroup.length > 0) {
            setRoleErrorStatus(false)
        }
    }, [selectedOptiongroup])

    function getSubadmins() {
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_BASEURL}/getAdmin`,
            headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        };
        axios(config)
            .then(function (response) {
                console.log(response.data.adminData, "getadmin")
                setRowData(response.data.adminData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ------ Delete API -------//

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortingoder: "desc",
            sortable: true,
            resizable: true,
            filter: true
        };
    }, []);

    const StatusRenderer = (props) => {
        console.log(props, "StatusRenderer")
        let status = props?.data?.status ? "deactivate" : "activate";
        let message = "Are you sure you want to " + status + " this user?";
        return (
            <>
                <ModalP status={props?.data?.status}
                    onClick={() => { changeStudentStatus(props?.data?._id); }}
                    message={message}
                    isDisabled={props.data.type === 'admin' ? true : false}
                />
            </>
        );
    };

    function changeStudentStatus(adminId) {
        var data = JSON.stringify({
            adminId: adminId,
        });

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/changestatus`,
            headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                getSubadmins();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const customLowerCaseComparator = (valueA, valueB) => {
        if (typeof valueA === 'string') {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }

        return (valueA > valueB ? 1 : (valueA < valueB ? -1 : 0));
    };



    const handlechange = (event) => {
        console.log(event, "handlechange")
        let array = [];
        // let arrayLabel = [];
        event.forEach((option) => {
            array.push(option.value);
        });
        if (event.length > 0) {
            setAddButtonStatus(false)
        }
        // event.forEach((option) => {
        //     arrayLabel.push(option.label);
        // })
        setselectedOptiongroup(array);
        // setSelectedOptionGroupLabel(arrayLabel);
    };

    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="container-fluid ">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                Role Management
                                <div className="float-end btns_head">
                                    <button
                                        className="btn btn-theme btn-sm"
                                        onClick={() => { setIsOpen(true); setAddButtonStatus(false) }}
                                    >
                                        Add Role
                                    </button>
                                </div>
                            </h4>

                            <div>
                                <Modal show={isOpen} onHide>
                                    <Modal.Body>
                                        {/* <div
                                            tabIndex="-1"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                        > */}
                                        <form onSubmit={handleSubmit(addNewRole)} >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">
                                                            Assign Role
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => fun()}
                                                        ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12 mb-3">
                                                                <label className="form-label">User Name</label>
                                                                <div className="position-relative">
                                                                    <input className="form-control"
                                                                        {...register("name", { required: true, min: 1 })} />
                                                                    {errors?.name?.type === "required" && (<p class="error">This field is required</p>)}
                                                                    {errors?.name?.type === "min" && (<p class="error">This field is required</p>)}
                                                                    <div
                                                                        className="hint_box"
                                                                        style={{ display: "block" }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <label className="form-label">Email</label>
                                                                <div className="position-relative">
                                                                    <input className="form-control"
                                                                        {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                                                                    {errors?.email?.type === "pattern" && (<p class="error">Invalid email address</p>)}
                                                                    {errors?.email?.type === "required" && (<p class="error">This field is required</p>)}
                                                                    {isEmailExist && <p style={{ color: 'red' }}>{roleErrorMessage}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <label className="form-label">Role</label>
                                                                <Select
                                                                    isMulti
                                                                    options={data}
                                                                    key={data}
                                                                    onChange={(e) => handlechange(e)}
                                                                />
                                                                {roleErrorStatus && <p class="error">This field is required</p>}
                                                            </div>
                                                            <div className="col-md-12 mb-4">
                                                                <label className="form-label">Password</label>
                                                                <div className="position-relative">
                                                                    <input className="form-control"
                                                                        type={pvisibility ? 'text' : 'password'}
                                                                        autoComplete="new-password"
                                                                        id="businessPassword"
                                                                        {...register("businessPassword", {
                                                                            required: "This field is required",
                                                                            minLength: {
                                                                                value: 6,
                                                                                message: "Please enter minimum 6 characters"
                                                                            }
                                                                        })}
                                                                    />
                                                                    {pvisibility ? <div>
                                                                        <img style={{ 'width': '23px', 'position': 'absolute', 'top': '9px', 'right': '12px' }} src="images/eye.png" onClick={() => { setpvisibility(false) }} />
                                                                    </div> : <div>
                                                                        <img style={{ 'width': '23px', 'position': 'absolute', 'top': '9px', 'right': '12px' }} src="images/invisible.png" onClick={() => { setpvisibility(true) }} />
                                                                    </div>}
                                                                    {errors.businessPassword && <p role="alert" class="error">{errors.businessPassword.message}</p>}

                                                                    {/* {errors?.businessPassword?.type === "minLength" && (<p class="error">Please enter minimum 6 characters</p>)} */}
                                                                    {/* {errors?.businessPassword?.type === "required" && (<p className="error">This field is required</p>)} */}

                                                                    <div
                                                                        style={{ display: "block" }}
                                                                    >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mb-4">
                                                                <label className="form-label">Confirm Password</label>
                                                                <div className="position-relative">
                                                                    <input className="form-control"
                                                                        type={cpvisibility ? 'text' : 'password'}
                                                                        name="businessconfirmpassword"
                                                                        autoComplete="new-password"
                                                                        {...register("businessconfirmPassword", { required: true, validate: (value) => value === watch('businessPassword') || "wrong" })}
                                                                    />
                                                                    {cpvisibility ? <div>
                                                                        <img style={{ 'width': '23px', 'position': 'absolute', 'top': '9px', 'right': '12px', }} src="images/eye.png" onClick={() => { setcpvisibility(false) }} />
                                                                    </div> : <div>
                                                                        <img style={{ 'width': '23px', 'position': 'absolute', 'top': '9px', 'right': '12px', }} src="images/invisible.png" onClick={() => { setcpvisibility(true) }} />
                                                                    </div>}
                                                                    {errors?.businessconfirmPassword?.type === "required" && (<p className="error">This field is required</p>)}
                                                                    {errors?.businessconfirmPassword?.type === "validate" && (<p className="error">Password do not match</p>)}
                                                                    <div
                                                                        style={{ display: "block" }}
                                                                    >
                                                                    </div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button onClick={() => somefun()}
                                                            type="button"
                                                            className="btn btn-danger CancelBtn"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            disabled={addButtonStatus}
                                                            type="submit"
                                                            className="btn submitBtn"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {/* </div> */}
                                    </Modal.Body>
                                </Modal>
                            </div>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Admin Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <UpdateUserRoleManagement
                                        refresh={refresh}
                                        handleClose={handleClose}
                                        updatedData={UpdateStudentData}
                                        onEditDataFunction={editdataReloadFunc}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-danger CancelBtn" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button className="btn submitBtn" onClick={() => {
                                        doRefresh(prev => prev + 1);
                                    }}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <div
                                className="modal fade"
                                id="UserViewId"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">
                                                Admin Details{" "}
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-body border">
                                                            <div className="profile_box">
                                                                <div className="profile_box_body">
                                                                    <div>
                                                                        <h6> Name : {Viewuser.username}</h6>
                                                                    </div>
                                                                    <div>
                                                                        <h6> Email : {Viewuser.email}</h6>
                                                                    </div>
                                                                    <div>
                                                                        {Viewuser?.type === 'master admin' && Viewuser?.roles?.length === 0 && <h6> Roles : Access of All Modules</h6>}
                                                                        {Viewuser?.roles?.length > 0 && <h6> Roles : {Viewuser?.roles.map(item => {
                                                                            if (item.includes('_')) {
                                                                                const newItem = item.split("_").join(" ");
                                                                                return newItem;
                                                                            }
                                                                            return item;
                                                                        }).join(", ")}</h6>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-danger CancelBtn"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{ width: "100%" }}
                                className="ag-theme-alpine tableFix"
                            >
                                <AgGridReact
                                    rowHeight={rowHeight}
                                    rowData={rowData}
                                    defaultColDef={defaultColDef}
                                    frameworkComponents={{
                                        childMessageRenderer: ChildMessageRenderer,
                                        moodRenderer: MoodRenderer,
                                        srNoRenderer: SrNoRenderer,
                                        statusRenderer: StatusRenderer,
                                        rolesRenderer: RolesRenderer,
                                    }}
                                >
                                    <AgGridColumn
                                        width={90}
                                        field="SrNo"
                                        //Srno={true}
                                        sortable={false}
                                        filter={false}
                                        cellRenderer="srNoRenderer"
                                    ></AgGridColumn>
                                    <AgGridColumn
                                        width={100}
                                        field="Action"
                                        cellRenderer="childMessageRenderer"
                                        colId="params"
                                        sortable={false}
                                        filter={false}
                                    ></AgGridColumn>
                                    <AgGridColumn
                                        width={200}
                                        field="username"
                                        sortable={true}
                                        comparator={customLowerCaseComparator}
                                        filter={true}
                                    ></AgGridColumn>
                                    <AgGridColumn
                                        width={250}
                                        field="email"
                                        sortable={true}
                                        comparator={customLowerCaseComparator}
                                        filter={true}
                                    ></AgGridColumn>
                                    <AgGridColumn
                                        width={200}
                                        field="roles"
                                        cellRenderer="rolesRenderer"
                                        sortable={true}
                                        // comparator={customLowerCaseComparator}
                                        filter={true}
                                    ></AgGridColumn>
                                    <AgGridColumn
                                        cellRenderer="statusRenderer"
                                        field="status"
                                        sortable={true}
                                    ></AgGridColumn>
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default UserRoleManagement;
