import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";
import "./Order.css";
import ToggleButton from "../../Components/ToggleButton";
// import 'moment' from moment;
import moment from "moment";

import { useHistory, useParams } from "react-router-dom";

function Order() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateBrand, setUpdateBrand] = useState({});
  const [brandView, setViewBrand] = useState([]);
  const [brands, setBrands] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(ordersGet);
  const [orderId, setOrderId] = useState("");
  const [fcm, setFcm] = useState("");
  const [scoutFcm, setScoutFcm] = useState("");
  const [url, setUrl] = useState(true);
  // const [scoutData, setScoutData] = useState([]);

  let scoutData = [];
  const pagination = true;
  const paginationPageSize = 10;
  //   const rowHeight = 55;

  let history = useHistory();

  const NameRenderer = (props) => {
    let name = props?.data?.orderData;
    let nameData = [];
    name.map((e) => (nameData = e));
    return (
      <span>
        <stong>{nameData?.bookingdata?.name}</stong>
      </span>
    );
  };

  const contactRenderer = (props) => {
    let contact = props?.data?.orderData;
    let contactData = [];
    contact.map((e) => (contactData = e));
    return (
      <span>
        <stong>{contactData?.bookingdata?.contect}</stong>
      </span>
    );
  };
  const DateRenderer = (props) => {
    let Date = props?.data?.orderData;
    let DateData = [];
    Date.map((e) => (DateData = e));
    return (
      // <img src={props.data.image}/>
      <span>
        {/* <stong>{props.data.bookingdata.date.toString()()}</stong> */}
        {moment.utc(DateData?.bookingdata?.date).format("MM/DD/YYYY")}
      </span>
    );
  };

  const CityRenderer = (props) => {
      return <span>{props.data?.city?.city}</span>;
  };

  const ScoutRenderer = (props) => {
    return props.data?.Scout_id == null ? "N/A" : <span>{props.data?.Scout_id?.name}</span>;
  };

  const PaymentRenderer = (props) => {
    console.log(props,"propspayment")
    return <span>{props.data?.adminStatus === true && props.data?.paymentStatus === "paid" ? "Deposited" : props.data?.paymentStatus}</span>;
  };

  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props?.node?.rowIndex + 1}</span>
      </>
    );
  };

      //    <div className="iconActionList">
      //   <button
      //    className="btn btn-theme btn-primary"
      //       style={{fontSize:"12px"}}
      //     onClick={() => {
      //       history.push({
      //         pathname: "OrderAssign",
      //         state: { details: props?.data },
      //       });
      //     }}
      //   >
      //      <AiOutlineCheck className="ActionIcon viewicon" /> 
      //     Assign
      //   </button>

      //    <div
      //     className="ViewIcon twoBtn"
      //     onClick={() => {
      //       history.push({
      //         pathname: `OrderView/${props.data._id}`,
      //         state: { details: props?.data, detailone: scoutData },
      //       });
      //     }}
      //   >
      //     <AiFillEye />
      //   </div> 

      //    <div className="DeleteIcon"
      //   onClick={() => {
      //     setDeleteDeleteId(props.data._id);
      //   }}
      //   data-bs-toggle="modal"
      //   data-bs-target="#DeleteStudentData"
      //   >
      //     <AiFillDelete
      //       className="ActionIcon"
            
      //     />
      //   </div> 
      //  </div>

  const ChildMessageRenderer = (props) => {
    return (
        <button
         className="btn btn-theme btn-primary"
            style={{fontSize:"12px"}}
          onClick={() => {
            history.push({
              pathname: "OrderAssign",
              state: { details: props?.data },
            });
          }}
          disabled={props.data?.paymentStatus=="Failed" ? true : false}
        >
          {/* <AiOutlineCheck className="ActionIcon viewicon" /> */}
          Assign
        </button>
    );
  };

  const goView = (props) => {
    history.push({
      pathname: `OrderView/${props.data._id}`,
      state: { details: props?.data, detailone: scoutData },
    });
  };

  // ------Post API-------//

  useEffect(() => {
    ordersGet();
    // getOrderDetails();
  }, []);

  function ordersGet() {
    var data = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getorders`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/Json",
      },
    };
    axios(data)
      .then(function (response) {
        console.log(response.data.data, "9080989");
        let orderData = response.data.data;
        setRowData(orderData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let params = useParams();
  console.log(params);

  const getOrderDetails = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getEditorder?_id=${params.id}`,
      method: "GET",
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => {
        let orderData = res.data;
        console.log("============>>" + JSON.stringify(orderData));
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const StatusRenderer = (props) => {
    return (
      <>
        {props?.data?.orderdeActive == true ? (
          <button
            className="btn btn-theme btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#AddCarsAll"
            style={{fontSize:"12px"}}
            onClick={() => {
              setUrl(false);
              setOrderId(props.data?._id);
              setFcm(props?.data?.orderData[0]?.user?.fcmtoken);
              setScoutFcm(props?.data?.Scout_id?.fcmtoken);
            }}
            disabled={props.data?.paymentStatus=="Failed" ? true : false}
          >
            Cancel
          </button>
        ) : (
          <button className="btn btn-theme btn-primary" disabled style={{fontSize:"12px"}}>
            {" "}
            Cancelled{" "}
          </button>
        )}
      </>
    );
  };

  // const StatusRenderer = (props) => {
  //   console.log(props?.data?.Scout_id?.fcmtoken, "fghijok")
  //   return (
  //     <>
  //       <ToggleButton
  //         handleToggle={() => {
  //           changeCategoryStatus(props?.data?._id, props?.data?.orderData[0]?.user?.fcmtoken, props?.data?.Scout_id?.fcmtoken);
  //         }}
  //         status={props?.data?.orderdeActive}
  //       />
  //     </>
  //   );
  // }

  function changeCategoryStatus(id, fcmToken, fcmtoken) {
    var data = JSON.stringify({
      fcmToken: fcmToken ? fcmToken : fcmtoken,
      fcmtoken: fcmtoken ? fcmtoken : fcmToken,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/orderdeActive?orderid=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        ordersGet();
        setUrl(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Sr. No.",
      Srno: true,
      width: 0,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "orderid",
      headerName: "Order Id",
      width: 180,
      sortable: false,
      filter: true,
    },
    {
      field: "name",
      headerName: "User Name",
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "NameRenderer",
      getQuickFilterText: (params) => {
        console.log(params.data?.orderData[0]?.bookingdata?.contect, "params");
        return params.data?.orderData[0]?.bookingdata?.name;
      },
    },
    {
      field: "contect",
      headerName: "Contact",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "contactRenderer",
      getQuickFilterText: (params) => {
        return params.data?.orderData[0]?.bookingdata?.contect;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "DateRenderer",
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "CityRenderer",
    },
    {
      field: "name",
      headerName: "Scout",
      width: 100,
      // colId:"params",
      sortable: true,
      filter: true,
      cellRenderer: "ScoutRenderer",
      getQuickFilterText: (params) => {
        return params.data?.Scout_id?.name;
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment_Status",
      width: 100,
      // colId:"params",
      sortable: true,
      filter: true,
      cellRenderer:"PaymentRenderer"
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "statusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      cellRenderer: "childMessageRenderer",
      colId: "params",
      sortable: true,
    },
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }
  const onFilterTextChange = (e) => {
    console.log(e, "filter");
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Order
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                {/* <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                  >
                    Add New Brand
                  </button>
                  
                </div> */}
              </h4>
              <div>
                <div
                  className="modal fade"
                  id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Brand
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Brand Name</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                value={brands}
                                onChange={(e) => {
                                  setBrands(e.target.value);
                                }}
                              />
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">image</label>
                            <div className="position-relative">
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setimage(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          // onClick={addBrand}
                          type="button"
                          className="btn submitBtn"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateStudentsData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    {/* <BrandUpdate
                      updatedData={UpdateBrand}
                      //   onEditDataFunction={CategoryEdit}
                    /> */}
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable CityOrderTable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  onRowClicked={(e) => {
                    if(url==true){
                      goView(e)
                     }
                  }}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    // moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    // imageRender: imageRerander,
                    NameRenderer: NameRenderer,
                    contactRenderer: contactRenderer,
                    DateRenderer: DateRenderer,
                    ScoutRenderer: ScoutRenderer,
                    PaymentRenderer:PaymentRenderer,
                    CityRenderer: CityRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade DeletePopup"
        id="AddCarsAll"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <p>Are you sure you want to cancel this order?</p>
                    <button
                      type="button"
                      className="btn btn-danger CancelBtn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        changeCategoryStatus(orderId, fcm, scoutFcm);
                      }}
                      className="btn submitBtn"
                      data-bs-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Order;
