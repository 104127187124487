import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

function Addplan() {
  const [CatSelect, setcatChang] = useState("");
  const [catId, setCaeId] = useState("");
  const [subCatChang, setSubCatChang] = useState([]);
  const [seubCatId, setSubCatId] = useState([]);
  const [label, setLabel] = useState("");
  const [planHeading, setPlanHeading] = useState("");
  const [planPrice, setplanPrice] = useState("");
  const [planHeadingPrice, setplanHeadingPrice] = useState("");
  const [subPlanHeading, setSubPlanHeading] = useState("");
  const [subPlanHeadingPrice, setSubPlanHeadingPrice] = useState("");
  const [subPlanPrice, setSubPlanPrice] = useState("");
  const [time, setTime] = useState("");
  const [month, setMonth] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [headingDes, setHeadingDes] = useState("");
  const [textField, setTextField] = useState("");
  const [serviceData, setServiceData] = useState("");
  const [services_id, setServiceId] = useState("");
  const [carData, setCarData] = useState([]);
  const [carId, setCarId] = useState("");
  const [fuel, setFuel] = useState([]);
  const [fuelId, setFuelId] = useState("");
  const [subplanPrice, setsubplanPrice] = useState("");
  const [subplanHeadingPrice, setsubplanHeadingPrice] = useState("");
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [brand, setBrand] = useState("");
  const [brandId, setBrandId] = useState("");
  const [image, setImage] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [carErr, setCarErr] = useState("");
  const [brandErr, setBrandErr] = useState("");
  const [catErr, setCatErr] = useState("");
  const [subCatErr, setSubCatErr] = useState("");
  const [serviceErr, setServiceErr] = useState("");
  const [fuelErr, setFuelErr] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [carValue, setCarValue] = useState();
  const [subCatValue, setSubCatValue] = useState();
  const planType = [
    { value: 0, label: "Normal" },
    { value: 1, label: "Master" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log(subPlanHeading,"label")
  let history = useHistory();

  function addPlanService(postdata) {
    if (
      cityId == "" ||
      null ||
      brandId == "" ||
      null ||
      carId == "" ||
      null ||
      fuelId == [] ||
      null ||
      catId == "" ||
      null ||
      (seubCatId == "" || null) | (services_id == [] || null)
    ) {
      if (cityId == "" || null) {
        setCityErr("This field is required");
      }
      if (brandId == "" || null) {
        setBrandErr("This field is required");
      }
      if (carId == "" || null) {
        setCarErr("This field is required");
      }
      if (fuelId == [] || null) {
        setFuelErr("This field is required");
      }
      if (catId == "" || null) {
        setCatErr("This field is required");
      }
      if (seubCatId == "" || null) {
        setSubCatErr("This field is required");
      }
      if (services_id == [] || null) {
        setServiceErr("This field is required");
      }
    } else {
      setDisabled(true);
      let lastplanName = postdata.planHeading + "," + postdata.subPlanHeading;
      let lastsubName = postdata.subPlanName + "," + "";
      let lastprice = postdata.planPrice + "," + postdata.subplanPrice;
      let lastheadingprice =
        postdata.planHeadingPrice + "," + postdata.subplanHeadingPrice;
      let formData = new FormData();
      formData.append("Category", catId.toString());
      formData.append("Subcategory", seubCatId);
      formData.append("services_id", services_id);
      formData.append("label", postdata.label);
      formData.append("car", carId);
      formData.append("fuel", fuelId);
      formData.append("image", postdata.image[0]);
      formData.append("servicename", postdata.subPlanHeadingPrice);
      formData.append("hours", postdata.time);
      formData.append("month", postdata.month);
      formData.append("description", postdata.shortDescription);
      formData.append("heading", postdata.headingDes);
      formData.append("textField", postdata.textField);
      formData.append("planName", lastplanName);
      formData.append("subPlanName", lastsubName);
      formData.append("typeprice", lastprice);
      formData.append("typename", lastheadingprice);
      formData.append("citys", cityId.toString());
      formData.append("brands", brandId);
      formData.append("document", image ? image : "");

      console.log(JSON.stringify(postdata.image[0]), "changeimage");

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/CommonService`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          console.log(response, "9999");
          history.push("/Plan");
          setCityErr("");
          setBrandErr("");
          setCarErr("");
          setFuelErr("");
          setCatErr("");
          setSubCatErr("");
          setServiceErr("");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const config = {
    readonly: false,
    height: 400,
  };

  useEffect(() => {
    getCategory();
    getService();
    getCars();
    fuelGet();
    getCity();
    brandGet();
  }, []);

  function brandGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBrand`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "brand");

        let brand = response.data.data;
        console.log(brand, "brands");
        let arrBrand = [];
        response.data.data.map((brandName) => {
          arrBrand.push({ label: brandName.brands, value: brandName._id });
        });
        setBrand(arrBrand);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "city");
        let city = response.data.data;
        console.log(city, "cities");
        let arrCity = [];
        response.data.data.map((catName) => {
          if(catName.status===true)
          arrCity.push({ label: catName.city, value: catName._id });
        });
        setCity(arrCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCategory() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "catget");
        let arrCat = [];
        response.data.getdata.map((catName) => {
          arrCat.push({ label: catName.title, value: catName._id });
        });

        setcatChang(arrCat);
        // setCategorySelect(response.data.getdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCategory = (e) => {
    setSubCatValue({ value: "", label: "" });
    setSubCatId("");
    console.log(e, "category");
    console.log(e.label, "element");
    setCaeId(e.value);
    subCategoryGet(e.value);
    setCatErr("");
  };

  const handleChangeCity = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    console.log(e.label, "element");
    setCityId(e.value);
    setCityErr("");
    if (brandId !== "" && fuelId !== "") {
      getCars(e.value, brandId, fuelId, "city");
    }
  };

  function subCategoryGet(id) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/subcategory?categoryid=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "subcategory");

        let arrSubCat = [];
        response.data.subcategory.map((catSubName) => {
          arrSubCat.push({ label: catSubName.title, value: catSubName._id });
        });
        setSubCatChang(arrSubCat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeSubCategory = (e) => {
    // let subarrSelect = [];
    // e.forEach((value) => {
    //   subarrSelect.push(value.value);
    // });
    setSubCatValue(e);
    setSubCatId(e.value);
    setSubCatErr("");
  };

  // ------GET SERVICE API-------//

  function getService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getservice`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "GERSERVICE");
        let arrServicePart = [];
        response.data.data.map((item) => {
          if(item.status === true)
          arrServicePart.push({ label: item.title, value: item._id });
        });

        setServiceData(arrServicePart);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleChangeService = (e) => {
    let serviceArrSelect = [];
    e.forEach((value) => {
      serviceArrSelect.push(value.value);
    });
    setServiceId(serviceArrSelect);
    setServiceErr("");
    console.log(serviceArrSelect, "aaaaa");
    //  setServiceId(e.value)
  };

  function fuelGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/Getfuel`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Getfuel");

        let arrFuel = [];
        response.data.data.map((item) => {
          // console.log(item)
          arrFuel.push({ label: item.fuel, value: item._id });
        });
        console.log(arrFuel, "arrfuel");
        setFuel(arrFuel);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCars(city, brand, fuel, type) {
    console.log(city, brand, fuel, type, "get cars function params");
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/car_listing?cityid=${
        type == "city" ? city : cityId
      }&brand_id=${type == "brand" ? brand : brandId}&fuel_id=${
        type == "fuel" ? fuel : fuelId
      }`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "carget");
        let arrcar = [];
        response.data.data.map((item, index) => {
          arrcar.push({ label: item.cars, value: item._id });
        });
        setCarData(arrcar);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCar = (e) => {
    console.log(e, "ecar");
    setCarValue(e);
    setCarId(e.value);
    setCarErr("");
  };

  const handleChangeBrand = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    setBrandId(e.value);
    setBrandErr("");
    if (cityId !== "" && fuelId !== "") {
      getCars(cityId, e.value, fuelId, "brand");
    }
  };

  const handleFuel = (e) => {
    setCarValue({ value: "", label: "" });
    setCarId("");
    let fuelSelect = [];
    // e.forEach((value) => {
    //   fuelSelect.push(value.value);
    // });
    fuelSelect.push(e.value);
    setFuelId(fuelSelect);

    setFuelErr("");
    if (cityId !== "" && brandId !== "") {
      getCars(cityId, brandId, e.value, "fuel");
    }
  };

  console.log(carData, "carDatacarData");

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Plan</h4>
              <form onSubmit={handleSubmit(addPlanService)}>
                <div className="branchData">
                  <div className="row">
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Service Pack Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                          value={planHeading}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setPlanHeading(e.target.value);
                          }}
                        /> */}

                          <input
                            className="form-control"
                            {...register("planHeading", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.planHeading?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.planHeading?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {" "}
                            Pack Name 2nd Part
                            <span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          {/* <input
                          value={subPlanHeading}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setSubPlanHeading(e.target.value);
                          }}
                        /> */}

                          <input
                            className="form-control"
                            {...register("subPlanHeading", {
                              required: true,
                              min: 1,
                            })}
                          />

                          {errors?.subPlanHeading?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.subPlanHeading?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>

                          <input
                            className="form-control"
                            {...register("subPlanName", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.subPlanName?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.subPlanName?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Full Description
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            style={{ height: "100px" }}
                            className="form-control"
                            {...register("shortDescription", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.shortDescription?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.shortDescription?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select City<span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <Select
                          // isMulti
                          options={city}
                          onChange={(e) => handleChangeCity(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{cityErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Brand<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          options={brand}
                          onChange={(e) => handleChangeBrand(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{brandErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Fuel<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          // isMulti
                          options={fuel}
                          onChange={(e) => handleFuel(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{fuelErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Car<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          // defaultValue={carData.map((item) => {
                          //   if (item.value == carValue.value) {
                          //     return { value: item.value, label: item.label };
                          //   }
                          // })}
                          value={carValue}
                          options={carData}
                          onChange={(e) => handleChangeCar(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{carErr}</span>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Main Category
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <Select
                          // isMulti
                          options={CatSelect}
                          onChange={(e) => handleChangeCategory(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{catErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Sub Category
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <Select
                          // isMulti
                          value={subCatValue}
                          options={subCatChang}
                          onChange={(e) => handleChangeSubCategory(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{subCatErr}</span>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Service Elements
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <Select
                          isMulti
                          options={serviceData}
                          onChange={(e) => handleChangeService(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{ color: "red" }}>{serviceErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {" "}
                            Service Pack Highlight
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                          value={label}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setLabel(e.target.value);
                          }}
                        /> */}

                          <input
                            className="form-control"
                            {...register("label")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Price 1<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            min={1}
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            {...register("planPrice", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.planPrice?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.planPrice?.type === "min" && (
                            <p>Minimum one number is required</p>
                          )}

                          {/* <input
                            className="form-control"
                            {...register("planPrice", {
                              required: true,
                            })}
                          />

                          {errors?.planPrice?.type === "required" && (
                            <p>This field is required</p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {" "}
                            Price 1 Description
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <input
                            className="form-control"
                            {...register("planHeadingPrice", {
                              required: true,
                            })}
                          />

                          {errors?.planHeadingPrice?.type === "required" && (
                            <p>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*  <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Sub Plan Description<span style={{color:"red"}}>*</span></label>
                         

                          <input
                            type="text"
                            className="form-control"
                            {...register("subPlanPrice", {
                              required: true,
                              min:1,
                            })}
                          />
                          {errors?.subPlanPrice?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.subPlanPrice?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                          </div>   */}
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Price 2</label>
                          <input
                            type="number"
                            min={1}
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            {...register("subplanPrice")}
                          />

                          {/* <input
                            className="form-control"
                            {...register("planPrice", {
                              required: true,
                            })}
                          />

                          {errors?.planPrice?.type === "required" && (
                            <p>This field is required</p>
                          )} */}
                        </div>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {" "}
                            Price 2 Description
                          </label>
                          <input
                            type="text"
                            min={1}
                            className="form-control"
                            {...register("subplanHeadingPrice")}
                          />

                          {/* <input
                            className="form-control"
                            {...register("planHeadingPrice", {
                              required: true,
                            })}
                          />

                          {errors?.planHeadingPrice?.type === "required" && (
                            <p>This field is required</p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Time Required<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                          value={time}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setTime(e.target.value);
                          }}
                        /> */}

                          <input
                            type="number"
                            min="1"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            {...register("time", {
                              required: true,
                            })}
                          />
                          {errors?.time?.type === "required" && (
                            <p>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Frequency (Months)
                          </label>
                          {/* <input
                          value={month}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setMonth(e.target.value);
                          }}
                        /> */}
                          <input
                            type="number"
                            min="1"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            {...register("month")}
                          />
                          {errors?.month?.type === "required" && (
                            <p>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            T&C Heading<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                          value={headingDes}
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setHeadingDes(e.target.value);
                          }}
                        /> */}
                          <input
                            className="form-control"
                            {...register("headingDes", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.headingDes?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.headingDes?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Terms & Conditions
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <textarea
                            style={{ height: "100px" }}
                            className="form-control"
                            {...register("textField", {
                              required: true,
                              min: 1,
                            })}
                          />
                          {errors?.textField?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.textField?.type === "min" && (
                            <p>Min one letter is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Upload Service Pack Icon
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            className="form-control"
                            {...register("image", {
                              required: true,
                            })}
                          />
                          {errors?.image?.type === "required" && (
                            <p>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Upload Documents</label>
                          <div className="position-relative">
                            <div>
                              <input
                                type="file"
                                name="upload"
                                accept=".xlsx,.xls, .doc, .docx,.pdf"
                                onChange={(e) => setImage(e.target.files[0])}
                                className="form-control"
                              />
                            </div>
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn CancelBtn me-3"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn submitBtn me-3"
                        disabled={disabled ? true : false}
                        // onClick={addPlanService}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Addplan;
