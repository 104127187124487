
import React, {useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import Select from "react-select";
import { useHistory } from "react-router-dom";
// import UpdateInspectionCat from "./UpdateInspection";
import ToggleButton from "../../Components/ToggleButton";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";




// console.log(rowData,"rowData")

  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const ImageRender = (props) => {
      console.log(props.data.image,'propsimage')
      const regex = "/.(jpg|jpeg|png|gif)$/i"
    return (
      <>
      {props.data?.image ?
        <span><img src={props.data?.image} alt="icon" width="25px" height="25px"/> </span> 
        :<video controls width="250"><source src={props.data.image} type="video/mp4"></source></video> }
        </>
    );
  };

  const StatusRenderer = (props) => {
    console.log(props.data.type,'propstype')
  return (
    <>
    {props.data.type}
      </>
  );
};


function BannerCategory() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
const [image, setImage] = useState("")
const [rowData, setRowData] = useState(BannerGet);
const [DeleteDeleteId,setDeleteDeleteId] = useState("")
const [inspectionCategoryData, setInspectionCategoryData] = useState({});
const [isOpen, setIsOpen] = useState(false);
const [type, setType] = useState("");
const [err, setErr] = useState("");
const [disabled, setDisabled] = useState(false);
  const pagination = true;
 const paginationPageSize = 10;
  const rowHeight = 55;

let history = useHistory();

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        {/* <div className="editIcon"
        onClick={() => {
          console.log(props,"99999")
          setInspectionCategoryData(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UpdateStudentsData"
        >
          <MdModeEditOutline
        
            className="ActionIcon viewicon"
            
          />
        </div> */}
        {/* <div className="ViewIcon"
           onClick={() => {
            history.push({
              pathname: "SubCategoryView",
              state: { details: props.data},
            });
          }}
        >
          <AiFillEye
          
          />
        </div> */}
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteCarCategoryData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };

  const categoryType = [
    { value: 0, label: "Category" },
    { value: 1, label: "Plan" },
    { value: 2, label: "SubCategory" },
  ];

  // ------Post API-------//

  function BannerCategory(postdata) {
    console.log(type,"type")
    if(type==""||null){
      setErr("This field is required")
    }else{
      setDisabled(true)
    var data = new FormData();
    data.append("type", type);
    data.append("image",postdata.image[0]);

  
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/CategoryBanner`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response,"9999")
        BannerGet();
        setIsOpen(false);
        setType("")
         reset();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }
  function cross() {
    reset();
  }
 function canclefun() {
   setIsOpen(false);
   cancelForm();
 }

 function cancelForm() {
   reset();
 }

  useEffect(() => {
    BannerGet();
  }, []);

  function BannerGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBanner`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {   
          console.log(response,"BannerGet")     
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function JobCardDeleteData(id) {
    var data = JSON.stringify({
      id: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteBanner`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        BannerGet();      })
      .catch(function (error) {
        console.log(error);
      });
  }



  // const StatusRenderer = (props) => {
  //   console.log(props,"props")
  //   return (
  //     <>
  //       <ToggleButton
  //         handleToggle={() => {
  //           changeJobcardStatus(props?.data?._id);
  //         }}
  //         status={props?.data?.status}
  //       />
  //     </>
  //   );
  // };

  
  function changeJobcardStatus(jobId) {
    var data = JSON.stringify({
      jobcardid: jobId,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/jobcardStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "98989")
        BannerGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function changeCategoryStatus(){}
  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Banner Master
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                   // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setIsOpen(true)
                      setDisabled(false)
                    }}
                  >
                    Add New Banner Category Image
                  </button>
                </div>
              </h4>
              <div>
              <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Banner Master
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => crossForm()}
                          ></button>
                        </div>
                        <form
                          onSubmit={handleSubmit(BannerCategory)}
                        >
                      <div className="modal-body">
                        <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="form-label"> Banner<span style={{color:"red"}}>*</span> </label>
                            <div className="position-relative">
                            <Select
                          // isMulti
                          options={categoryType}
                          className="basic-multi-select"
                          onChange={(e)=>{
                            setType(e.label)
                            setErr("")
                          }}
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{err}</span>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Banner Image<span style={{color:"red"}}>*</span></label>
                            <div className="position-relative">
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                className="form-control"
                                {...register("image",{required:true})}
                                 
                                />
                                 {errors?.image?.type === "required" && (
                                    <p>This field is required</p>
                                  )}

                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                         onClick={() => canclefun()}
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn submitBtn"
                          disabled = {disabled ? true : false}
                        >
                          Submit
                        </button>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
                </Modal.Body>
                </Modal>
              </div>
              <div
                className="modal fade"
                id="UpdateStudentsData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    {/* <UpdateJobCard
                      updatedData={inspectionCategoryData}
                       onEditDataFunction={JobCardGet}
                    /> */}
                  </div>
                </div>
              </div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCarCategoryData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Banner
                              Category?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                JobCardDeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Users Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    {/* <h6> Sub Category  : {viewCategory.title}</h6> */}
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> Category image : {ViewFuel.image}</h6> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable text-center"
                style={{ height: 645 }}
              >
                <AgGridReact
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  rowData={rowData}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: ImageRender,
                  }}
                >
                  <AgGridColumn
                    field="Serial No."
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={540}
                    field="image"
                    //  headerName="Parts"

                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="imageRender"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="Catgory"
                    Srno={true}
                    sortable={false}
                    filter={false}
                     cellRenderer="statusRenderer"
                  ></AgGridColumn>

                  <AgGridColumn
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerCategory;


