
import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { useLocation } from "react-router-dom";
import "./PlanView.css";
import { useHistory } from "react-router-dom";


function MasterPlanView(props) {
    let location = useLocation();
    let history = useHistory();

console.log(location.state.details, "111111");

let catarr = [];
catarr.push(location.state.details?.Category);

//subcategory default value
let subcatarr = [];
subcatarr.push(location.state.details?.Subcategory);
  
  let arrServiceParts=[];
  let arrCategory=[];
  let arrSubCategory=[];



   location.state?.details?.services_id.map((item)=>{
    arrServiceParts.push(item?.title)
   }
   
   )

   catarr.map((item)=>{
    arrCategory.push(item?.title)
   }
   
   )

   subcatarr.map((item)=>{
    arrSubCategory.push(item?.title)
   }
   
   )

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <ul className="nav nav-pills" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          // href="#home"
                          role="tab"
                        >
                          <span>Plan Details</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#profile"
                          role="tab"
                        ></a>
                      </li>
                    </ul>

                    <div className="tab-content pt-3">
                      <div
                        className="tab-pane active"
                        id="home"
                        role="tabpanel"
                      >
                        <table className="table table-bordered table_fix_width">
                          <tbody>
                            <tr>
                              <th>Service Pack Name</th>
                              <td>{location.state?.details?.plan[0].planName}</td>
                            </tr>
                            <tr>
                              <th>Pack Name 2nd Part</th>
                              <td>{location.state?.details?.plan[1].planName}</td>
                            </tr>
                            <tr>
                              <th>Description</th>
                              <td>{location.state?.details?.plan[0].subPlanName}</td>
                            </tr>
                            <tr>
                              <th>Full Description</th>
                              <td>{location.state?.details?.description}</td>
                            </tr>
                            <tr>
                              <th>City</th>
                              <td>{location?.state?.details?.citys?.city}</td>
                            </tr>
                            <tr>
                              <th>Main Category</th>
                              <td>{arrCategory}</td>
                            </tr>
                            <tr>
                              <th>Sub-Category</th>
                              <td>{arrSubCategory?.join(",  ")}</td>
                            </tr>
                            <tr>
                              <th>Service Elements </th>
                              <td>{arrServiceParts.join(", ")}</td>
                            </tr>
                            <tr>
                              <th>Service Pack Highlight </th>
                              <td>{location.state?.details?.label}</td>
                            </tr>

                            <tr>
                              <th>Price 1 </th>
                              <td>{location.state.details?.plan[0].typeprice}</td>
                            </tr>

                            <tr>
                              <th> Price 1 Description</th>
                              <td>{location.state.details?.plan[0].typename}</td>
                            </tr>

                            <tr>
                              <th>Price 2 </th>
                              <td>{location.state.details?.plan[1]?.typeprice}</td>
                            </tr>

                            <tr>
                              <th>Price 2 Description</th>
                              <td>{ location.state.details?.plan[1]?.typename}</td>
                            </tr>

                            <tr>
                              <th>Time Required</th>
                              <td>{location.state?.details?.hours}</td>
                            </tr>

                            <tr>
                              <th>  Frequency (Months)  </th>
                              <td>{location.state?.details?.month}</td>
                            </tr>

                           
                            <tr>
                              <th>T&C Heading </th>
                              <td>{location.state?.details?.heading}</td>
                            </tr>
                            <tr>
                              <th>Terms & Conditions </th>
                              <td>{location.state?.details?.textField}</td>
                            </tr>

                            <tr>
                              <th>Service Pack Icon</th>
                              <td><img src={location.state?.details?.image} width="50px"/></td>
                            </tr>

                            <tr>
                              <th>Documents</th>
                              <td><img src={location.state.details?.document} width="50px"/></td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger CancelBtn"
                    onClick={() => {
                      history.push("./Plan");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MasterPlanView;
