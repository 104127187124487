import React, { useState,useRef } from "react";
import axios from "axios";
import "./user.css";
import { useForm } from "react-hook-form";
import { BiStreetView } from "react-icons/bi";
import Select from "react-select";
import { useEffect } from "react";
const Updateusers = (props) => {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobileNo] = useState("");
  const [Street, setStreet] = useState("");
  const [city, setCity] = useState("");
const [State, setState] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [disImage, setDisImage] = useState("");
  const [image, setImage] = useState("");
  const [dispdf, setDispdf] = useState("");
  const [pdf, setpdf] = useState("");
  
 
 console.log( props.updatedData,"document")

  let updatedImage = props.updatedData?.image;
  let updatedpdf = props.updatedData?.document;
  const form = useRef(null);
  function updateUSerData(postdata) {
    console.log(postdata,"sjdja");
     var data = new FormData();
     data.append("userId", props.updatedData?._id);
     data.append("name", postdata.name === "" || null ? props.updatedData?.name : postdata.name);
     data.append( "email", postdata.email === "" || null ? props.updatedData?.email : postdata.email );
     data.append("mobile",  postdata.mobile === "" || null ? props.updatedData?.mobile : "91"+postdata.mobile);
     data.append("State",postdata.state === "" || null ? props.updatedData?.State : postdata.state);
      data.append("city", city === "" || null ? props.updatedData?.city?._id : city);
      data.append("Street", postdata.Street === "" || null ? props.updatedData?.Street : postdata.Street);
     data.append("PinCode",  postdata.pincode === "" || null ? props.updatedData?.PinCode : postdata.pincode);
     data.append("image",image === "" || null ? props.updatedData?.image : image);
     data.append("document",pdf === "" || null ? props.updatedData?.document : pdf);
   var config = {
     method: "post",
     url: `${process.env.REACT_APP_BASEURL}/UpateUser`,
     headers: {
       Authorization: sessionStorage.getItem("token"),
       "Content-Type": "application/json",
     },
     data: data,
   };

   axios(config)
     .then(function (response) {
       props.onEditDataFunction();
       props.onBox();
       reset();
     })
     .catch(function (error) {
       console.log(error);
     });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({mode:'onChange'});


  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const Iconpdf = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDispdf(display);
      setpdf(event.target.files[0]);
    }
  }
    const renderpdf = (image) => {
      return (
        <img src="images/pdf.png" alt="pdfIcon" style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
        }}/> 
      );
    };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  const handleclick = () => {
    form.current.reset(); //this will reset all the inputs in the form
  }
  const [cityData, setCityData] = useState([])
  function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
        let cityArr = []
        response.data.data?.map((item)=>{
          if(item.status === true){
          cityArr.push({
            label: item?.city,
            value:item?._id
          })
        }
        })
        setCityData(cityArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCity=(e)=>{
    console.log(e,"eeeeeee")
    setCity(e.value)
  }

  useEffect(()=>{
    getCity();
  },[])

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit User
        </h5>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={()=>{
            props.onBox()
          reset()
        }}
        ></button>
      </div>
      
      <form onSubmit={handleSubmit(updateUSerData)}>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Name<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.name}
                className="form-control"
                {...register("name", {
                  required: true,
                  min:1
                })}
              />
              {errors?.name?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              {errors?.name?.type === "required" && (
                <p>This field is required</p>
              )}
              <div className="hint_box" style={{ color:"red" }}></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Email Address </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.email}
                className="form-control"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                {...register("email", {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors?.email?.type === "pattern" && (
                <p>invalid email address</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Mobile No.<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="number"
                defaultValue={props.updatedData?.mobile}
                className="form-control"
                min={1}
                onWheel={(e) => e.target.blur()}
                {...register("mobile", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^[1-9]\d{9}$/i,
                })}
              />
              {errors?.mobile?.type === "required" && (
                <p>This field is required</p>
              )}
              {errors?.mobile?.type === "pattern" && (
                <p>Invalid Number or First Number can't be 0</p>
              )}
              {errors?.mobile?.type === "minLength" && (
                <p>Only 10 digit number</p>
              )}
              {errors?.mobile?.type === "maxLength" && (
                <p>Only 10 digit number</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit City<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
            <Select
            defaultValue={{label:props.updatedData?.city?.city, value:props.updatedData?.city?._id}}
                          // isMulti
                          options={cityData}
                          onChange={(e) => handleChangeCity(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>



      
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Street<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.Street}
                className="form-control"
                {...register("Street", {
                //  required: true,
                  pattern: /^[0-9A-Za-z ]+$/i,
                  min:1
                })}
              />
              {errors?.Street?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              {errors?.Street?.type === "required" && (
                <p>This field is required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>

        <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit State<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.State}
              className="form-control"
              {...register("state", {
                // required: true,
                min:1
              })}
            />
            {errors?.state?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            {errors?.state?.type === "required" && (
              <p>This field is required</p>
            )}
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
      </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Pin Code<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="number"
                defaultValue={props.updatedData?.PinCode}
                className="form-control"
                min={1}
                onWheel={(e) => e.target.blur()}
                {...register("pincode", {
               //  required: true,
                 minLength: 6,
                 maxLength: 6,
               })}
             />
             {errors?.pincode?.type === "required" && (
               <p>This field is required</p>
             )}
             {errors?.pincode?.type === "maxLength" && (
               <p>Only 6 digit number</p>
             )}
             {errors?.pincode?.type === "minLength" && (
               <p>Only 6 digit number</p>
             )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
          <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Upload Documents</label>
            <input type="file"  name="upload" className="form-control" accept=".xlsx,.xls, .doc, .docx,.pdf" onChange={Iconpdf} />
            {!dispdf ? (
              updatedpdf ? 
              <a href={updatedpdf} target="_blank" rel="noreferrer"> <img src="images/pdf.png" alt="pdfIcon" style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}/> </a>
             : "" ) 
            : (
              renderpdf(dispdf)
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
            <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage}
          />
            {!disImage ? (
              updatedImage ? 
                <img
                  className="mt-2"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  src={updatedImage}
                />
             : "" ) 
            : (
              renderImages(disImage)
            )}
          </div>
        </div>
      </div>
    
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          data-bs-dismiss="modal"
          onClick={()=>{
            reset()
            props.onBox()
          }}
        >
          Cancel
        </button>
        <button
        type="submit"
        className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
}

export default Updateusers;