import React, { useState,useRef } from "react";
import axios from "axios";
import "./city.css";
import Footer from "../../shared/Footer/footer";
import { MdOutlineSignalCellularNull } from "react-icons/md";
import { useForm } from "react-hook-form";

const UpdateCities = (props) => {
    
  const [Cities, setCities] = useState("");
  const [image, setImage] = useState("");
  const [disImage, setDisImage] = useState("");
  const form=useRef(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  console.log(props.updatedData?.image,"<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
  function updateCitiesData(postdata) {
    var data = new FormData();
data.append("citiesId", props.updatedData?._id);
      data.append(
        "city", postdata.city === "" || null ? props.updatedData?.city  : postdata.city);
        data.append(
          "image",
          image === "" || null ? props.updatedData?.image : image
        );
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editCities`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.box()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  const handleclick=()=>{
    form.current.reset();
  }

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit City
      </h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={()=>{
          props.box();
        }}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateCitiesData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit City<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.city}
              className="form-control"
              {...register("city", {
                required: true,
                min:1
              })}
            />
            {errors?.city?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.city?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  props.updatedData?.image ?
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  /> : ""
                ) : (
                  renderImages(disImage)
                )}
          </div>

    </div>
    <div className="modal-footer">
      <button type="submit" className="btn btn-danger CancelBtn" onClick={()=>{props.box();}}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
      
    </form>
  </>)
}

export default UpdateCities;