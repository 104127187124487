import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import UpdateCategory from "./Updatecategory";
import brandUpdate from "./brandUpdate";
import BrandUpdate from "./brandUpdate";

import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import ToggleButton from "../../Components/ToggleButton";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

function Brand() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [DeleteDeleteId,setDeleteDeleteId] = useState("")
  const [UpdateBrand, setUpdateBrand] = useState({});
  const [brandView, setViewBrand] = useState([]);
  const [brands, setBrands] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(brandGet);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [brandErrorMessage, setBrandErrorMessage] = useState("");
  const pagination = true;
//   const paginationPageSize = 10;
//   const rowHeight = 55;
// console.log(brandView,"brandView")
  const MoodRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <stong>{props.data.category}</stong>
      </span>
    );
  };
  const imageRerander = (props) => {
    return (
      // <img src={props.data.image}/>
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }

  const SrNoRenderer = (props) => {
    console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
        onClick={() => {
          console.log(props.data.brands,"edit")
          setUpdateBrand(props.data);
          setShow(true)
      }}
        >
          <MdModeEditOutline
        
            className="ActionIcon viewicon"
            
          />
        </div>
        <div className="ViewIcon"
   onClick={() => {
    console.log(props, "propstyty");
    setViewBrand(props.data);
  }}
  data-bs-toggle="modal"
  data-bs-target="#UserViewId"

alt=""
src="../../images/view.jpg"
        >
          <AiFillEye
               
          />
        </div>
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };

  // ------Post API-------//

  function addBrand(postdata) {
   setDisabled(true)
    var data = new FormData();
    data.append("brands", postdata.brands);
    data.append("image", postdata.image[0]);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Brand`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response,"brandResponse")
        if(response.data.status === false){
          setBrandErrorMessage(response.data.massage);
          setDisabled(false)
        }else{
          setBrandErrorMessage("");
          brandGet();
          setIsOpen(false);
          reset();
          setBrands("")
        }   
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    brandGet();
  }, []);

  function brandGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBrand`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
          console.log(response.data,"brand")
        
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function box() {
    setShow(false);
    cross();
  }
  
  function brandDelete(id) {
    var data = JSON.stringify({
        brandsid: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteband`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response,"brand")
     brandGet();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
function crossForm() {
  setIsOpen(false);
  cross();
}
function cross() {
  reset();
}

 function canclefun() {
   setIsOpen(false);
   cancelForm();
 }

 function cancelForm() {
   reset();
 }
  const editdataReloadFunc = () => {
     brandGet();
  };

  
  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeCategoryStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  function changeCategoryStatus(){}

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "brands",
      headerName: "Brands",
      width: 200,
      sortable: false,
      filter: false,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      sortable: false,
      filter: false,
      cellRenderer: "imageRender",
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "statusRenderer",
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      sortable: true,
      filter: true,
      colId: "params",
      cellRenderer: "childMessageRenderer",
    },
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Brands
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {
                      setBrandErrorMessage("")
                      setIsOpen(true)
                      setDisabled(false)
                    }}
                  >
                    Add New Brand
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Brand
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addBrand)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Brand Name<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      // value={brands}
                                   
                                      {...register("brands", {
                                        onChange: () => setBrandErrorMessage(""),
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.brands?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                      {errors?.brands?.type === "min" && (
                                        <p>Minimum One Letter Required</p>
                                      )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">image<span style={{color:"red"}}>*</span></label>
                                  <div className="position-relative">
                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      className="form-control"
                                      {...register("image",{required:true})}
                                 
                                    />
                                     {errors?.image?.type === "required" && (
                                        <p>This field is required</p>
                                      )}
                                      <p style={{color:"red"}}>{brandErrorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                onClick={() => canclefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <BrandUpdate
                      updatedData={UpdateBrand}
                      onEditDataFunction={brandGet}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Brand?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                brandDelete(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Brand Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Brand : {brandView.brands}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> brand image : {brandView.image}</h6> */}
                                    <img
                                      src={brandView.image}
                                      width="110px"
                                      height="110px"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  //   paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brand;
