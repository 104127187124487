import React, { useState } from "react";
import axios from "axios";
import "./training.css";
import Footer from "../Footer/footer";
import { useForm } from "react-hook-form";

const UpdateTraining = (props) => {
    
  const [title, setTitle] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [url, setUrl] = useState("");
  const [disImage, setDisImage] = useState("");

  const {register,handleSubmit,formState:{errors},reset} =useForm();
  
  function updateTrainingData(postdata) {
    console.log(props.updatedData,"123456789")
    var data = JSON.stringify({
      "id": props.updatedData?._id,
      "heading": postdata.title === "" || null ? props.updatedData?.heading  : postdata.title,
     
        "Disperctions": postdata.discreption === "" || null ? props.updatedData?.Disperctions  : postdata.discreption,
      
        "url":
        postdata.url === "" || null ? props.updatedData?.url : postdata.url 
      });
  
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editTraningdata`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Training
      </h5>
      <button
        type="button"
        className="btn-close"
        onClick={()=>props.onBox()}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateTrainingData)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Title<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.heading}
              className="form-control"
              {...register("title", {
                required: true,
                min:1
              })}
            />
            {errors?.title?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.title?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Description<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.Disperctions}
              className="form-control"
              {...register("discreption", {
                required: true,
                min:1
              })}
            />
            {errors?.discreption?.type ===
              "required" && (
              <p>This field is required</p>
            )}
            
            {errors?.discreption?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row">
       
      <div className="col-md-12 mb-3">
          <label className="form-label">Edit Url<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.url}
              className="form-control"
              {...register("url", {
                required: true,
                min:1,
                pattern : /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/
              })}
            />
            {errors?.url?.type === "required" && (
              <p>This field is required</p>
            )}
            {errors?.url?.type === "min" && (
              <p>Minimum One Letter Required</p>
            )}
            {errors?.url?.type === "pattern" && (
              <p>Invalid URL</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>


      </div>
      
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>props.onBox()}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>
</form>
  </>)
}

export default UpdateTraining;