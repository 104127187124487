import React, { useEffect, useState } from "react";
import "./OrderView.css";
import Header from "../../shared/Header/header";
import Sidebar from "../../shared/Sidebar/sidebar";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import "./OrderView.css";

const OrderView = () => {
  let location = useLocation();
  const [data, setData] = useState();
  let params = useParams();
  console.log(params);
  const getOrderDetails = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getEditorder?_id=${params.id}`,
      method: "GET",
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => {
        console.log(res?.data, "sfsasds");
        let orderData = res?.data;
        console.log(orderData, "gdhsa");
        setData(orderData);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  let orderData = [];
  data?.order?.orderData?.map((item) => {
    orderData.push(item);
  });
  // let plans = [];
  // data?.order?.orderData?.map((item) => {
  //   item?.selectplan?.map((ele) => {
  //     plans.push(ele);
  //   });
  // });

  let preInspectionDetails = [];

  data?.PreInsPectionData?.map((item, index) => {
    if (data?.PreInsPectionData?.length - 1 === index) {
      preInspectionDetails.push(JSON.parse(item?.condition));
    }
  });

  let condition = preInspectionDetails?.flat();
  console.log(condition, "custom");
  let genCondition = [];

  data?.PreInsPectionData?.map((item, index) => {
    if (data?.PreInsPectionData?.length - 1 === index) {
      genCondition.push(JSON.parse(item?.genCondition));
    }
  });
  let gen = genCondition?.flat();

  let customerConcerns = [];

  data?.PreInsPectionData?.map((item, index) => {
    if (data?.PreInsPectionData?.length - 1 === index) {
      customerConcerns.push(JSON.parse(item?.customerConcerns));
    }
  });
  let customer = customerConcerns?.flat();

  let postInspectionDetails = [];
  let overHallCarHealth = "";
  data?.postInspectionData?.map((item, index) => {
    if (data?.postInspectionData?.length - 1 === index) {
      postInspectionDetails.push(JSON.parse(item?.MainHealth));
      overHallCarHealth =
        item.overalcarhealth == "G"
          ? "Good"
          : item.overalcarhealth == "A"
          ? "Average"
          : "Poor";
    }
  });

  // let abc = data?.postInspectionData[data?.postInspectionData?.length-1].MainHealth

  let mainHealth = postInspectionDetails.flat();

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mb-4">
              <h5>Booking Id : {data?.order?.orderid}</h5>
              <div className="card scoutCard">
                <h5>
                  <b>Scout Details</b>
                </h5>
                <div className="scDtBox">
                      <ul style={{ listStyleType: "none", padding: "0px" }}>
                    {data?.order?.Scout_id?.image ? (
                      <img src={data?.order?.Scout_id?.image} className="mb-2" />
                    ) : (
                      ""
                    )}
                    <li>
                      <b>Name :</b> {data?.order?.Scout_id?.name}
                    </li>
                    <li>
                      <b>Mobile No. :</b> +{data?.order?.Scout_id?.mobile}
                    </li>
                    <li>
                      <b>Email ID :</b> {data?.order?.Scout_id?.email}
                    </li>
                    <li>
                      <b>Address : </b>
                      {data?.order?.Scout_id?.Street},{" "}
                      {data?.order?.Scout_id?.State},{" "}
                      {data?.order?.Scout_id?.PinCode}
                    </li>
                  </ul>
                  
                </div>
                
                <ul style={{ listStyleType: "none", margin:'0', opacity:'0' }}>
                  <li>
                    <b>Car No.</b> - {orderData[0]?.bookingdata?.carno}
                  </li>

                  <li>
                    <b>Car Model</b> - {orderData[0]?.cars?.brand?.brands}{" "}
                    {orderData[0]?.cars?.cars}{" "}
                    {orderData[0]?.cars?.fuel
                      .map((e) => {
                        return e.fuel;
                      })
                      .join(",")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <h5 className="d-flex justify-content-between"><span>Order Status : {data?.order?.orderStatus}</span><a href={data?.order?.invoice}>Download PDF</a></h5>
              <div className="card scoutCard">
                <h5>
                  <b>User Details</b>
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="scDtBox">
                      <ul style={{ listStyleType: "none", padding: "0px" }}>
                        {orderData[0]?.user?.image ? (
                          <img src={orderData[0]?.user?.image} className="mb-2" />
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="scDtBox">
                      <ul style={{ listStyleType: "none", padding: "0px" }}>
                        <li>
                          <b>Pickup Date : </b>
                          {orderData[0]?.bookingdata?.date.slice(0, 10)}
                        </li>
                        <li>
                          <b>Pickup Time : </b>
                          {orderData[0]?.bookingdata?.time}
                        </li>
                        {/* <li>Pin - {item?.bookingdata?.pincode}</li> */}
                      </ul>
                    </div>
                  </div>
                  
                  <div className="col-md-12">
                    <div className="scDtBox">
                      <ul style={{ listStyleType: "none", padding: "0px" }}>
                        <li>
                          <b>Name :</b> {orderData[0]?.bookingdata?.name}
                        </li>
                        <li>
                          <b>Mobile No. :</b> +
                          {orderData[0]?.bookingdata?.contect}
                        </li>
                        <li>
                          <b>Email ID :</b> {orderData[0]?.bookingdata?.email}
                        </li>
                        <li>
                          <b>Address :</b> {orderData[0]?.bookingdata?.houseNo},{" "}
                          {orderData[0]?.bookingdata?.Street},{" "}
                          {orderData[0]?.bookingdata?.state},{" "}
                          {orderData[0]?.bookingdata?.pincode}
                        </li>
                        {/* <li>Pin - {item?.bookingdata?.pincode}</li> */}
                      </ul>
                    </div>
                  </div>
                  
                <ul style={{ listStyleType: "none", margin:'0' }}>
                  <li>
                    <b>Car No.</b> - {orderData[0]?.bookingdata?.carno}
                  </li>

                  <li>
                    <b>Car Model</b> - {orderData[0]?.cars?.brand?.brands}{" "}
                    {orderData[0]?.cars?.cars}{" "}
                    {orderData[0]?.cars?.fuel
                      .map((e) => {
                        return e.fuel;
                      })
                      .join(",")}
                  </li>
                </ul>
                </div>
              </div>
            </div>
            
            <div className="col-md-12">
              <div className="card scoutCard">
                <h4 className="heading"> Booking Details</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Pack Name</th>
                      <th scope="col">Description </th>
                      <th scope="col">Price</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Payment Status</th>
                    </tr>
                  </thead>
                 { data?.order?.orderData?.map((item,index) => {
                  console.log(item,"item?.plans?.plan[0]?.planName")
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.Plans?.plan[0]?.planName} {item?.Plans?.plan[1]?.planName}</td>
                          <td>{item?.Plans?.plan[0]?.subPlanName} {item?.Plans?.plan[1]?.subPlanName}</td>
                          <td>{item?.selectplan[0]?.typeprice}</td>
                          <td>{data?.order?.status2}</td>
                          <td>
                            {data?.order?.adminStatus === true &&
                            data?.order?.paymentStatus === "paid"
                              ? "Deposited"
                              : data?.order?.paymentStatus}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card scoutCard">
                <h4 className="heading"> Tracking Updates</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Heading</th>
                      <th scope="col">Sub Heading </th>
                      <th scope="col">Description</th>
                      <th scope="col">File</th>
                    </tr>
                  </thead>
                  {data?.Custom?.map((item, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.heading}</td>
                          <td>{item.subheading}</td>
                          <td>{item.Description}</td>
                          <td>
                            {item.image ? (
                              <a href={`${item?.image}`}>
                                {" "}
                                <img
                                  src="../images/Download.svg"
                                  width="25px"
                                  height="25px"
                                />{" "}
                              </a>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card scoutCard">
                <h4 className="heading"> Approvals</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Heading</th>
                      <th scope="col">Sub-Heading </th>
                      <th scope="col">Description</th>
                      <th scope="col">Price</th>
                      <th scope="col">Qty.</th>
                      <th scope="col">Status</th>
                      <th scope="col">File</th>
                    </tr>
                  </thead>
                  {data?.Approve?.map((item, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.heading}</td>
                          <td>{item.subheading}</td>
                          <td>{item.description}</td>
                          <td>{item.price}</td>
                          <td>{item.qty}</td>
                          <td>
                            {item.status === true &&
                            item.ApprovelStatus === false
                              ? "Approved"
                              : item.status === false &&
                                item.ApprovelStatus === true
                              ? "Pending"
                              : item.status === false &&
                                item.ApprovelStatus === false
                              ? "Refused"
                              : ""}
                          </td>
                          {item?.image == "" ? (
                            <td></td>
                          ) : (
                            <td>
                              <a href={`${item?.image}`}>
                                <img
                                  src="../images/Download.svg"
                                  width="25px"
                                  height="25px"
                                />
                              </a>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card scoutCard">
                <h4 className="heading">Pre - Inspection</h4>
                <table class="table">
                  <tbody>
                  <p style={{color:"black"}}>Tyre Condition</p>
                    <tr>
                      <td>
                        Front-Left :{" "}
                        {data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.frontL === "G"
                          ? "Good"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.frontL === "A"
                          ? "Average"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.frontL === "P"
                          ? "Poor"
                          : ""}
                      </td>
                      <td>
                        Front-Right :{" "}
                        {data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.frontR === "G"
                          ? "Good"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.frontR === "A"
                          ? "Average"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.frontR === "P"
                          ? "Poor"
                          : ""}
                      </td>
                      <td>
                        Rear-Left :{" "}
                        {data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.backL === "G"
                          ? "Good"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.backL === "A"
                          ? "Average"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.backL === "P"
                          ? "Poor"
                          : ""}
                      </td>
                      <td>
                        Rear-Right :{" "}
                        {data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.backR === "G"
                          ? "Good"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.backR === "A"
                          ? "Average"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.backR === "P"
                          ? "Poor"
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <tbody>
                    <tr>
                      Gen Condition :
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Parts</th>
                            <th scope="col"> Status</th>
                          </tr>
                        </thead>
                        {gen?.map((e) => {
                          return (
                            <>
                              <tr>
                                <td>{e.heading}</td>
                                <td>
                                  {e.x == "1"
                                    ? "Available"
                                    : e.x == "2"
                                    ? "Not Available"
                                    : e.x == "3"
                                    ? "Missing"
                                    : ""}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                      <table class="table">
                        Customer Concerns :
                        {customer?.map((e) => {
                          return (
                            <>
                              <tr>
                                <td> {e}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        Battery :{" "}
                        {
                          data?.PreInsPectionData[
                            data?.PreInsPectionData?.length - 1
                          ]?.Battery
                        }
                      </td>
                      <td>
                        Battery Condition :{" "}
                        {data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.batterycondition === "G"
                          ? "Good"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.batterycondition === "A"
                          ? "Average"
                          : data?.PreInsPectionData[
                              data?.PreInsPectionData?.length - 1
                            ]?.batterycondition === "P"
                          ? "Poor"
                          : ""}
                      </td>
                      <td>
                        Odometer Kms :{" "}
                        {
                          data?.PreInsPectionData[
                            data?.PreInsPectionData?.length - 1
                          ]?.OdometerKms
                        }
                      </td>
                      <td>
                        Fuel :{" "}
                        {
                          data?.PreInsPectionData[
                            data?.PreInsPectionData?.length - 1
                          ]?.fuel
                        }
                      </td>
                      {/* <td>Gst : {data?.PreInsPectionData[data?.PreInsPectionData?.length-1]?.Gst}</td> */}
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex align-items-center">
                  <div className="me-3">Files :</div>
                  {
                    data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.video?.length>0 ? <video
                    src={
                      data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.video
                    }
                    width="100px"
                    height="100px"
                    controls className="me-2"
                  ></video> : ""
                  }
                  
                  {
                    data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.image?.length>0 ? 
                      <a
                    href={`${
                      data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.image
                    }`}
                  >
                    <img
                      src={
                        data?.PreInsPectionData[
                          data?.PreInsPectionData?.length - 1
                        ]?.image
                      }  className="me-2"
                      width="100px"
                      height="100px"
                    />
                  </a> : ""
                  }
                {
                  data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.document?.length>0 ? 
                      <a
                    href={`${
                      data?.PreInsPectionData[
                        data?.PreInsPectionData?.length - 1
                      ]?.document
                    }`}
                  >
                    <img
                      src="../images/document.svg"
                      height="100px"
                    />
                  </a> : ""
                }
                  
                </div>

                </div></div>
            <div className="col-md-12">
              <div className="card scoutCard">
                <h4 className="heading">Post - Inspection</h4>
                <p style={{ color: "black" }}>
                  {" "}
                  Overall Car Health : {overHallCarHealth}
                </p>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Parts</th>
                      <th scope="col"> Description</th>
                      <th scope="col"> Rating</th>
                    </tr>
                  </thead>
                  {mainHealth?.map((e) => {
                    return (
                      <>
                        <tr>
                          <td>{e.heading}</td>
                          <td>{e.description}</td>
                          <td>{e.rate}</td>
                          <td>{e.overalcarhealth}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderView;
