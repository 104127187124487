import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

// import "./Category.css";
import Footer from "../Footer/footer";

const UpdateInspect = (props) => {
  console.log(props.updatedData?.subhading, "props");
  const [PreInspection, setGeneralinformation] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({mode:'onChange'});

  function UpdateInspectionData(postdata) {

    var data = JSON.stringify({
      _id: props.updatedData?._id,
      subhading:
      postdata.name === "" || null
          ? props.updatedData?.subhading
          : postdata.name,
    });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/EditPostInspection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox()
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
        Edit Post-Inspection
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(UpdateInspectionData)}>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Post-Inspection<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.subhading}
                className="form-control"
                {...register("name", {
                  required: true,
                  pattern: /^[0-9A-Za-z ]+$/i,
                  min:1
                })}
              />
              {errors?.name?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              {errors?.name?.type === "required" && (
                <p>This field is required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

     
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdateInspect;



