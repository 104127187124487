
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import { Value } from "sass";
import { useForm } from "react-hook-form";


function AddCar() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
    const [cars, setcars] = useState("");
    const [addimage, setimage] = useState("");
    const [brandselect, setBrandSelect] =useState([]);
    const [brandChang, setBrandChang] =useState("");
    const [fuelselect, setFuelSelect] =useState([]);
    const [fuelChange, setFuelChange] =useState([]);
    const [categoryselect, setCategoryselect] =useState([]);
    const [carCategoryChange, setCarCategoryChange] =useState("")
    const [cityData, setCityData] =useState([]);
    const [disabled, setDisabled] = useState(false);
  const [CityId, setCityId] =useState("")
  const [cityArray, setCityArray] = useState([]);

  const [err, setErr] = useState("");
  const [brandChangErr, setBrandChangErr] = useState("");
  const [fuelChangeErr, setFuelChangeErr] = useState("");
  const [carCategoryChangeErr, setCarCategoryChangeErr] = useState("");
  const [cityIdErr, setCityIdErr] = useState("");
  const [image, setImage] = useState("");
  const [imgErr, setImgErr] = useState("");
// console.log(subPlanHeading,"label")
let history = useHistory();

function imageGet(img){
  let data = new FormData()
  data.append("image",img)

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BASEURL}/AddFile`,
    headers: {
      Authorization: sessionStorage.getItem("token"),
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
    
      console.log(response.data);
      setImage(response.data.data.image)
      setImgErr("");
    })
    .catch(function (error) {
      console.log(error);
    });
  }

function addCarMaster(postdata) {
  if((brandChang==""||null) || (fuelChange==""||null) || (carCategoryChange==""||null) || (cityArray==""||null) || (image==""||null)){
    if(brandChang==""||null){
      setBrandChangErr("This field is required")
    }
    if(fuelChange==""||null){
      setFuelChangeErr("This field is required")
    }
    if(carCategoryChange==""||null){
      setCarCategoryChangeErr("This field is required")
    }
    if(cityArray==""||null){
      setCityIdErr("This field is required")
    }
    if(image == ""||null){
      setImgErr("This field is required");
    }
  }
  else{
    setDisabled(true)
    let arrObj = [];
    console.log(fuelChange,cityArray,"fuelChange")
    for(let j=0; j<cityArray.length; j++){
    for(let i = 0; i<fuelChange.length; i++){
      let obj = {
        cars : postdata.cars,
        image: image,
        brand : brandChang,
        fuel : fuelChange[i],
        carCategory : carCategoryChange,
        city : cityArray[j],
      }
      arrObj.push(obj)
    }
  }
    console.log(arrObj,"arrObj")
    var data = JSON.stringify({
      carName: arrObj
    })
    // data.append("carName", JSON.stringify(arrObj));
    // data.append("image", postdata.image[0]);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/car`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
      
        console.log(response.data);
        history.push("/Cars")
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  const config = {
    readonly: false,
    height: 400,
  };


  useEffect(() => {
     brandGet();
     fuelGet();
     carCategoryGet();
      getCity()
  }, []);


  function brandGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getBrand`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
          console.log(response.data.data,"brandselect")

          let arrBrand=[];
          response.data.data.map((brandName)=>{
            console.log(brandName.brands,"123")
            arrBrand.push({label: brandName.brands,value: brandName._id})
          });
          setBrandSelect(arrBrand)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeBrand=(e)=>{
    console.log(e.label,"label")
setBrandChang(e.value)
setErr("");
setBrandChangErr("");
  }

  function fuelGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/Getfuel`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
          console.log(response.data,"fuelselsct")
        
          let arrFuel=[];
          response.data.data.map((fuelName)=>{
            arrFuel.push({label: fuelName.fuel,value: fuelName._id})
          });
  
          setFuelSelect(arrFuel);
        // setFuelSelect(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeFuel=(event)=>{
  let fuelArraySelect =[];
  event.forEach((item)=>{
    fuelArraySelect.push(item.value)
  })
   setFuelChange(fuelArraySelect)
   console.log(fuelArraySelect,"car")
   setErr("");
   setFuelChangeErr("");
  }


  function carCategoryGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCarCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
          console.log(response.data.data,"6768627")

          let arrCategory=[];
          response.data.data.map((categoryName)=>{
            arrCategory.push({label: categoryName.carCategory,value: categoryName._id})
          });
  
          setCategoryselect(arrCategory);
        
        //  setCategoryselect(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
const handleChangeCategory =(e)=>{
  console.log(e.label,"cat")
  setCarCategoryChange(e.value)
  setErr("");
  setCarCategoryChangeErr("");
}

function getCity() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCity`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data,"city")
     
        let arrCity=[];
        response.data.data.map((item)=>{
          arrCity.push({label:item.city, value:item._id})
        })
        console.log(arrCity,"arrCity")
         setCityData(arrCity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  const handleChangeCity=(e)=>{
    let CityArraySelect =[];
  e.forEach((item)=>{
    CityArraySelect.push(item.value)
  })
  setCityArray(CityArraySelect)
   console.log(CityArraySelect,"city")
  setCityIdErr("");
  }




  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Car</h4>
              <form onSubmit={handleSubmit(addCarMaster)}>
                <div className="branchData">
                  <div className="row">
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">Car Name<span style={{color:"red"}}>*</span></label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            // value={cars}
                            // onChange={(e) => {
                            //   setcars(e.target.value);
                            // }}
                            {...register("cars", {
                              required: true,
                              min:1
                            })}
                          />
                          {errors?.cars?.type === "required" && (
                            <p>This field is required</p>
                          )}
                          {errors?.cars?.type === "min" && (
                            <p>Minimum one letter is required</p>
                          )}
                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-md-10 mb-3">
                        <label className="form-label">Select Brand<span style={{color:"red"}}>*</span> </label>
                        <Select
                          // isMulti
                          options={brandselect}
                          onChange={(e) => handleChangeBrand(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{brandChangErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">Select Fuel<span style={{color:"red"}}>*</span> </label>
                        <Select
                         isMulti
                          options={fuelselect}
                          onChange={(e) => handleChangeFuel(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{fuelChangeErr}</span>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">
                          Select Car Category<span style={{color:"red"}}>*</span>{" "}
                        </label>
                        <Select
                          //   isMulti
                          options={categoryselect}
                          onChange={(e) => handleChangeCategory(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{carCategoryChangeErr}</span>
                      </div>
                    </div>

                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">Select City<span style={{color:"red"}}>*</span> </label>
                        <Select
                          isMulti
                          options={cityData}
                          onChange={(e) => handleChangeCity(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <span style={{color:"red"}}>{cityIdErr}</span>
                      </div>
                    </div>
                    <div className="row bgBox ">
                      <div className="col-md-10 mb-3">
                        <label className="form-label">image<span style={{color:"red"}}>*</span></label>
                        <div className="position-relative">
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            className="form-control"
                            onChange={(event) => {
                          imageGet(event.target.files[0]);
                         
                        }}     
                            />
                             <span style={{color:"red"}}>{imgErr}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn CancelBtn me-3"
                        onClick={() => {
                          history.push("./Cars");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn submitBtn me-3" disabled = {disabled ? true : false}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddCar;
