

import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import Footer from "../../shared/Footer/footer";
import Select from "react-select";
import {useForm} from 'react-hook-form';

const ServiceUpdat = (props) => {
    console.log(props,"props")
    const [catId, setCaeId] = useState("");
    const [CatSelect, setcatChang] = useState("");
    const [Plan, setPlan] = useState([]);
    const [planId, setPlanId] = useState([]);
   
  const [title, setService] = useState("");
  const [subCatChang, setSubCatChang] = useState([]);
  const [seubCatId, setSubCatId] = useState([]);
  const [image, setImage] = useState("");
  const form=useRef(null)
  const [disImage, setDisImage] = useState("");

  const {register, handleSubmit,formState:{errors},reset} =useForm();

  function updateServiceData(postdata) {
    // console.log(props,'update123')


    var data = new FormData();
    data.append("servicecategoryId", props.updatedData?._id);
    data.append(
      "title", postdata.title === "" || null ? props.updatedData?.title  : postdata.title);
  
        data.append(
          "category",
          catId.toString() === "" || null ? props.updatedData?.category?.map((item) => 
          { return item._id }).toString()
    
            : catId
        );
        
        data.append(
          "subcategory",
          seubCatId.toString() === "" || null ? props.updatedData?.subcategory?.map((item) => 
          { return item._id }).toString()
    
            : seubCatId
        );

        data.append(
          "Plans",
          planId.toString() === "" || null ? props.updatedData?.Plans?.map((item) => 
          { return item._id }).toString()
    
            : planId
        );
        
    data.append(
      "image",
      image === "" || null ? props.updatedData?.image : image
    );

  
    console.log(data);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASEURL}/editService`,
      headers: {
        'Authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    
    subCategoryGet();
    getCategory()
    getPlanService()
  }, []);
  function subCategoryGet() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/get-sub-category`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {   
          console.log(response,"subcategory")   
          
          let arrSubCat=[];
          response.data.data.map((catSubName)=>{
            arrSubCat.push({label: catSubName.title,value: catSubName._id})
          });
          setSubCatChang(arrSubCat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeSubCategory=(e)=>{
   let subarrSelect=[];
   e.forEach((item)=>{
    subarrSelect.push(item.value)
   })
   setSubCatId(subarrSelect)
   console.log(subarrSelect,"subarrSelect")
   
    }
const handleclick=()=>{
  form.current.reset();
}
    

    function getCategory() {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getcategory`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response.data,"catget")
          let arrCat=[];
          response.data.getdata.map((catName)=>{
            arrCat.push({label: catName.title,value: catName._id})
          });
  
          setcatChang(arrCat);
          // setCategorySelect(response.data.getdata);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

      const handleChangeCategory=(e)=>{
    console.log(e.value,"element")
   setCaeId(e.value)
    }



      //---PLAN GET API--//

  function getPlanService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCommonService`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "plan");
        let planarr = [];
        response.data.data.map((item) => {
          planarr.push({ label: item.planName, value: item._id });
        });
        console.log(planarr, "planarr");
        setPlan(planarr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const handleChangePlan = (e) => {

    console.log(e.value,"element")
    setPlanId(e.value)
    
  };
  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

    const renderImages = (image) => {
        return (
          <img
            style={{ width: "110px", height: "140px" }}
            src={image}
            key={image}
          />
        );
      };

  return (<>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        Edit Service
      </h5>
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={()=>{
          props.onBox()
        }}
      ></button>
    </div>
    <form onSubmit={handleSubmit(updateServiceData)}>
    <div className="modal-body">
      <div className="row">
     
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Service Element Name<span style={{color:"red"}}>*</span> </label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={props.updatedData?.title}
              className="form-control"
              {...register("title", {
                required: true, min:1,
              })}
            />
            {errors?.title?.type === "required" && (
              <p>This field is required</p>
            )}
              {errors?.title?.type === "min" && (
              <p>Min one letter is required</p>
            )}
            <div
              className="hint_box"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      
        <div className="col-md-12 mb-3">
            <label className="form-label d-block">Edit Service Part Image<span style={{color:"red"}}>*</span></label>
                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg" onChange={IconImage} />
                {!disImage ? (
                  <img className="mt-2" 
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    src={props.updatedData?.image}
                  />
                ) : (
                  renderImages(disImage)
                )}
          </div>
      
       
      
      </div>
      
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-danger CancelBtn" onClick={()=>{props.onBox()}}
      >Cancel</button>
      <button
        type="submit"
        className="btn submitBtn">
        Submit
      </button>
    </div>

    </form>
  </>)
}

export default ServiceUpdat;