// import { BrowserRouter as Router,  Route,  Redirect,  Switch,} from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Cars from "./shared/cars/cars";
import Category from "./shared/category/category";
import Login from "./shared/Login/login";
import Scout from "./shared/Scout/Scout";
import Workshop from "./shared/workshop/Workshop";
import User from "./shared/users/user";
import Dashboard from "./shared/Dashboard/dashboard";
import City from "./shared/citys/cityes";
import service from "./shared/service/service";
import Userview from "./shared/users/Userview";
import About from "./shared/CmsPages/About";
import Brand from "./shared/brand/brand";
import Fuel from "./shared/fuel/fuel";
import CarCategory from "./shared/CarCategory/CarCategory";
import ScoutView from "./shared/Scout/ScoutView";
import WorkshopView from "./shared/workshop/WorkshopView";
import Order from "./shared/Order/Order";
import OrderView from "./shared/Order/OrderView";
import Addservice from "./shared/service/Addservice";
import Subcategory from "./shared/Subcategory/Subcategory";
import Plan from "./shared/Plan/Plan";
import MasterPlan from "./shared/masterPlan/MasterPlan";
import AddPlan from "./shared/masterPlan/Addplan";
import AddMasterPlan from "./shared/masterPlan/AddMasterplan";
import Serviceparts from "./shared/Serviceparts/Serviceparts";
import PlanView from "./shared/Plan/PlanView";
import MasterPlanView from "./shared/masterPlan/MasterPlanView";
import Input from "./Components/Input";
import SubCategoryView from "./shared/Subcategory/SubCategoryView";
import "./index.css";
import PlanUpdate from "./shared/Plan/PlanUpdate";
import MasterPlanUpdate from "./shared/masterPlan/MasterPlanUpdate";
import AddCar from "./shared/cars/AddCar";
import ViewCar from "./shared/cars/ViewCar";
import UpdateCars from "./shared/cars/updateCar";
import OrderAssign from "./shared/Order/OrderAssign";
import InspectionSubCategory from "./shared/InspectionSubCategory/InspectionSubCategory";
import Preset from "./shared/Preset/Preset";
import UpdatePreset from "./shared/Preset/UpdatePreset";
import UpdateInspectionSubCat from "./shared/InspectionSubCategory/UpdateInspectionSubCat";
import Sos from "./shared/SOS/Sos";
import ViewSos from "./shared/SOS/ViewSos";
import JobCard from "./shared/JobCard/JobCard";
import PreInspection from "./shared/PreInspection/PreInspection";
import PostInspection from "./shared/PostInspection/PostInspection";
import UpdateInspection from "./shared/PreInspection/UpdateInseption";
import UpdateInspect from "./shared/PostInspection/UpdateInspect";
import BannerCategory from "./shared/bannerCategory/bannerCategory";
import Usermsg from "./shared/Support/Usermsg";
import Scoutmsg from "./shared/Support/Scoutmsg";
import Offer from "./shared/offer/Offer";
import Blog from "./shared/blog/Blog";
import Training from "./shared/training/Training";
import Messaging from "./shared/messaging/messaging";
import Feedback from "./shared/feedback/Feedback";
import PrivacyPolicy from "./shared/privacypolicy/PrivacyPolicy";
import Termsandconditions from "./shared/termconditions/Termsandcondition";
import Faq from "./shared/faq/Faq";
import PaymentDetails from "./shared/paymentDetails/PaymentDetails";
import UserRoleManagement from './shared/userRolemanagement/UserRoleManagement'
import Profile from "./shared/Profile/profile";

const PrivateRoute = (props) => {
  const token = sessionStorage.getItem("token");
  // console.log(token);
  // console.log(props, "privateRoute");

  return (
    <>
      {token ? (
        <Route {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
    </>
  );
};

const PublicRoute = (props) => {
  const token = sessionStorage.getItem("token");

  return token ? (
    <Redirect to={{ pathname: "/dashboard" }} />
  ) : (
    <Route {...props} />
  );
};

function App() {
  console.log("hello this is app.js");
  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/offer" component={Offer} />
          <PrivateRoute exact path="/User" component={User} />
          <PrivateRoute exact path="/Cars" component={Cars} />
          <PrivateRoute exact path="/Category" component={Category} />
          <PrivateRoute exact path="/Citys" component={City} />
          <PrivateRoute exact path="/Service" component={service} />
          <PrivateRoute exact path="/Scout" component={Scout} />
          <PrivateRoute exact path="/message/:id" component={Messaging} />
          <PrivateRoute exact path="/Userview" component={Userview} />
          <PrivateRoute exact path="/About" component={About} />
          <PrivateRoute exact path="/Brand" component={Brand} />
          <PrivateRoute exact path="/Fuel" component={Fuel} />
          <PrivateRoute exact path="/CarCategory" component={CarCategory} />
          <PrivateRoute exact path="/ScoutView" component={ScoutView} />
          <PrivateRoute exact path="/Order" component={Order} />
          <PrivateRoute
            exact
            path="/paymentdetails"
            component={PaymentDetails}
          />
          <PrivateRoute exact path="/OrderView/:id" component={OrderView} />
          <PrivateRoute exact path="/Addservice" component={Addservice} />
          <PrivateRoute exact path="/Subcategory" component={Subcategory} />
          <PrivateRoute exact path="/masterplan" component={MasterPlan} />
          <PrivateRoute exact path="/plan" component={Plan} />
          <PrivateRoute exact path="/addplan" component={AddPlan} />
          <PrivateRoute exact path="/addmasterplan" component={AddMasterPlan} />
          <PrivateRoute exact path="/serviceparts" component={Serviceparts} />
          <PrivateRoute exact path="/workshopmaster" component={Workshop} />
          <PrivateRoute exact path="/workshopview" component={WorkshopView} />
          <PrivateRoute exact path="/planView" component={PlanView} />
          <PrivateRoute
            exact
            path="/masterplanView"
            component={MasterPlanView}
          />
          <PrivateRoute
            exact
            path="/subCategoryView"
            component={SubCategoryView}
          />
          <PrivateRoute
            exact
            path="/masterplanUpdate"
            component={MasterPlanUpdate}
          />
          <PrivateRoute exact path="/planUpdate" component={PlanUpdate} />
          <PrivateRoute exact path="/addCar" component={AddCar} />
          <PrivateRoute exact path="/viewCar" component={ViewCar} />
          <PrivateRoute exact path="/updateCars" component={UpdateCars} />
          <PrivateRoute exact path="/orderAssign" component={OrderAssign} />
          <PrivateRoute
            exact
            path="/InspectionSubCategory"
            component={InspectionSubCategory}
          />
          <PrivateRoute exact path="/preset" component={Preset} />
          <PrivateRoute exact path="/updatePreset" component={UpdatePreset} />
          <PrivateRoute
            exact
            path="/updateInspectionSubCat"
            component={UpdateInspectionSubCat}
          />
          <PrivateRoute exact path="/blog" component={Blog} />
          <PrivateRoute exact path="/sos" component={Sos} />
          <PrivateRoute exact path="/viewSos" component={ViewSos} />
          <PrivateRoute exact path="/training" component={Training} />
          <PrivateRoute exact path="/faq" component={Faq} />
          <PrivateRoute exact path="/feedback" component={Feedback} />
          <PrivateRoute exact path="/privacypolicy" component={PrivacyPolicy} />
          <PrivateRoute
            exact
            path="/termsandconditions"
            component={Termsandconditions}
          />
          <PrivateRoute exact path="/jobcard" component={JobCard} />
          <PrivateRoute exact path="/preInspection" component={PreInspection} />
          <PrivateRoute
            exact
            path="/updateInspection"
            component={UpdateInspection}
          />
          <PrivateRoute
            exact
            path="/postInspection"
            component={PostInspection}
          />
          <PrivateRoute exact path="/UpdateInspect" component={UpdateInspect} />
          <PrivateRoute
            exact
            path="/bannerCategory"
            component={BannerCategory}
          />
          <PrivateRoute exact path="/usermsg" component={Usermsg} />
          <PrivateRoute exact path="/scoutmsg" component={Scoutmsg} />

          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/userrole" component={UserRoleManagement} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
