import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "./Plan.css";
import ToggleButton from "../../Components/ToggleButton";

function MasterPlan() {
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [UpdateCategoryData, setUpdateCategoryData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [rowData, setRowData] = useState(getPlanService);
  const [carId, setCarId] = useState([]);
  const [planId, setPlanId] = useState("");
  const pagination = true;
  const paginationPageSize = 25;
  const rowHeight = 55;
  console.log(rowData, "rowData");
  let history = useHistory();
  console.log(price, "");

  const SrNoRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const MoodRendererfour = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.plan[0]?.planName}</span>
      </>
    );
  };

  const MoodRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.citys?.city}</span>
      </>
    );
  };

  const categoryRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.Category?.title}</span>
      </>
    );
  };

  const carRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.car?.cars}</span>
      </>
    );
  };

  const fuelRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.fuel[0]?.fuel}</span>
      </>
    );
  };

  const subCategoryRenderer = (props) => {
    // console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.data?.Subcategory?.title}</span>
      </>
    );
  };

  // const PriceRenderer = (props) => {
  //     console.log(props.data, "iam12333iam12333");
  // //  console.log(pricearr,"pricearr")
  //   // })
  //   return (
  //     <>
  //        <span>{}</span>
  //     </>
  //   );
  // };

  const PriceNameRenderer = (props) => {
    let pricearrname = [];
    props?.data?.planName?.map((items) => {
      pricearrname.push(items.planName);
      // console.log(pricearr,"pricearr")
    });
    return (
      <>
        <span>{pricearrname}</span>
      </>
    );
  };
  const SubPriceRenderer = (props) => {
    console.log(props, "iam12333iam12333");
    let subpricearr = [];
    props?.data?.servicepackprice?.map((items) => {
      console.log(items, "items");
      subpricearr.push(items.price);
    });
    return (
      <>
        <span>{subpricearr}</span>
      </>
    );
  };

  const SubPriceNameRenderer = (props) => {
    console.log(props.data, "iam12333iam12333");
    let subNamepricearr = [];
    props?.data?.servicepackprice?.map((items) => {
      console.log(items, "items");
      subNamepricearr.push(items.servicename);
    });
    return (
      <>
        <span>{subNamepricearr}</span>
      </>
    );
  };

  // const priceRenderer=(props)=>{
  //   console.log(props.data.data.servicepackprice,"props6666")
  //   let text=[];
  //   let arr=props.data.data.servicepackprice;
  //   arr.map((item)=>{
  //     console.log(item.tags,"arr")
  //     text.push(item.tags);
  //   })

  //   return (
  //     <span className="profle_img_box">
  //       <span>{text.join(", ")}</span>
  //     </span>
  //   );
  // };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            console.log(props.data, "update");
            history.push({
              pathname: "MasterPlanUpdate",
              state: { details: props.data },
            });
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>

        <div
          className="ViewIcon"
          onClick={() => {
            console.log(props.data, "propsdata");
            history.push({
              pathname: "MasterPlanView",
              state: { details: props.data },
            });
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>

        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  // ------Post API-------//

  function getPlanService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getmasterPlan`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "plan");

        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function plandeleteData(id) {
    var data = JSON.stringify({
      id: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/delete_master_plan`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getPlanService();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  var cars = [];

  function getCars() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCar`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data, "carget");
        let car = response.data.data;
        let allCar = [];
        car.map((item) => {
          console.log(item._id, "allCarItems");
          allCar.push(item._id);
          console.log(allCar, "allCar");
        });
        setCarId(allCar);
        console.log(carId, "carId");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const ApplyStatusRenderer = (props) => {
    return (
      <>
        {props.data?.ApplayStatus == false ? (
          <button
            className="btn btn-theme btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#AddCarsAll"
            onClick={() => {
              setPlanId(props.data?._id);
            }}
          >
            Apply
          </button>
        ) : (
          <button className="btn btn-theme btn-primary" disabled>
            {" "}
            Applied{" "}
          </button>
        )}
      </>
    );
  };

  // function changeApplyStatustwo(Id) {
  //   var config = {
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASEURL}/ApplayStatus?id=${Id}`,
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response,"response")
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  function changeApplyStatus(Id) {
    var data = JSON.stringify({
      id: Id,
      // cars: carId,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Applaytoall`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getPlanService();
        history.push("./masterplan");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // const editdataReloadFunc = () => {
  //   getCategory();
  // };

  const StatusRenderer = (props) => {
    console.log(props.data.plan, "fghijok");
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            RecommendedPackStatus(props?.data?._id);
          }}
          status={props?.data?.mostpopularpack}
        />
      </>
    );
  };

  function changeStudentStatus(Id) {
    var data = JSON.stringify({
      planid: Id,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/mostpopularPack`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getPlanService();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const RecommendedPack = (props) => {
    console.log(props, "fghijok");
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeStudentStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  };

  function RecommendedPackStatus(id) {
    var data = JSON.stringify({
      recommendedid: id,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/REcommendedPack`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getPlanService();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "planName",
      headerName: "Service Pack Name",
      width: 100,
      cellRenderer: "MoodRendererfour",
      sortable: false,
      filter: false,
      getQuickFilterText: (params) => {
        return params.data?.plan[0]?.planName;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "MoodRenderer",
      getQuickFilterText: (params) => {
        return params.data?.citys?.city;
      },
    },
    {
      field: "Category",
      headerName: "Category",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "categoryRenderer",
      getQuickFilterText: (params) => {
        return params.data?.citys?.city;
      },
    },
    {
      field: "SubCategory",
      headerName: "Sub-Category",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "subCategoryRenderer",
      getQuickFilterText: (params) => {
        return params.data?.citys?.city;
      },
    },
    // {
    //   field: "most popularPack",
    //   headerName: "Most popular pack",
    //   width: 220,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "statusRenderer",
    // },
    // {
    //   field: "ReCommendedPack",
    //   headerName: "Recommended Pack",
    //   width: 220,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "recommendedPack",
    // },
    {
      field: "applytocars",
      headerName: "Apply to all Cars",
      width: 220,
      sortable: false,
      filter: false,
      cellRenderer: "applystatusRenderer",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      colId: "params",
      cellRenderer: "childMessageRenderer",
      sortable: true,
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Master Plan
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => history.push("./addmasterplan")}
                  >
                    Add New Master Plan
                  </button>
                </div>
              </h4>
              <div>
                <div
                  className="modal fade"
                  id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Plan
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Category Name</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                // value={category}
                                // onChange={(e) => {
                                //   setCategory(e.target.value);
                                // }}
                              />
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">image</label>
                            <div className="position-relative">
                              <input
                                type="file"
                                className="form-control"

                                // onChange={(e) => {
                                //   setimage(e.target.files[0]);
                                //  }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          // onClick={addCategory}
                          type="button"
                          className="btn submitBtn"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateStudentsData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    {/* <UpdateCategory
                      updatedData={UpdateCategoryData}
                      onEditDataFunction={CategoryEdit}
                    /> */}
                  </div>
                </div>
              </div>

              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Master Plan?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                plandeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Users Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Category : {Viewuser.category}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6> Category image : {Viewuser.image}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body border">
                              <div>
                                <ul className="nav nav-pills" role="tablist">
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#home"
                                      role="tab"
                                    >
                                      {/* <span>Users Details</span> */}
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content pt-3">
                                  <div
                                    className="tab-pane active"
                                    id="home"
                                    role="tabpanel"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade DeletePopup"
                id="AddCarsAll"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to add this Plan to all
                              cars?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                changeApplyStatus(planId);
                                // changeApplyStatustwo(planId)
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ag-theme-alpine cts_datatable">
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    //  priceRenderer:PriceRenderer,
                    PriceNameRenderer: PriceNameRenderer,
                    recommendedPack: RecommendedPack,
                    applystatusRenderer: ApplyStatusRenderer,
                    // SubPriceRenderer: SubPriceRenderer,
                    // SubPriceNameRenderer: SubPriceNameRenderer,
                    MoodRendererfour: MoodRendererfour,
                    MoodRenderer: MoodRenderer,
                    categoryRenderer: categoryRenderer,
                    subCategoryRenderer: subCategoryRenderer,
                    carRenderer: carRenderer,
                    fuelRenderer: fuelRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterPlan;
