import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";

// import "./Category.css";
import Footer from "../../shared/Footer/footer";

const UpdataSubCategory = (props) => {
  console.log(props,"props")

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();

  let catarr = [];
  catarr.push(props?.updatedData?.parentsid);
  console.log(catarr,"catarr")

  const [title, setTitle] = useState("");
  const [parentsid, setparentsid] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [Plan, setPlan] = useState([]);
  const [plan_id, setPlanId] = useState([]);
  const [CatSelect, setcatChang] = useState("");
  const [catId, setCaeId] = useState("");
  const form = useRef(null);
  function updateSubCategory(postdata) {

    var data = JSON.stringify({
      'categoryid': props.updatedData?._id,
      'parentsid': catId === '' || null ? props.updatedData?.parentsid : catId,
      'title': postdata.title === '' || null ? props.updatedData?.title : postdata.title,
      'discreption': postdata.discreption === '' || null ? props.updatedData?.discreption : postdata.discreption,

      'plan_id':plan_id.toString() === '' || null ? props.updatedData?.plan_id?.map((item) => { return item._id }).toString() : plan_id
     });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/updateSubCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        console.log(response,"gahsdfh");
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getPlanService();
    getCategory();
  }, []);

const handleclick=()=>{
  form.current.reset();
}

  function getCategory() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "catget");
        let arrCat = [];
        response.data.getdata.map((catName) => {
          arrCat.push({ label: catName.title, value: catName._id });
        });

        setcatChang(arrCat);
        // setCategorySelect(response.data.getdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeCategory = (e) => {
    console.log(e, "element");
    setCaeId(e.value);
  };


  //---PLAN GET API--//

  function getPlanService() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCommonService`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "plan");
        let planarr = [];
        response.data.data.map((item) => {
          planarr.push({ label: item.planName, value: item._id });
        });
        console.log(planarr, "planarr");
        setPlan(planarr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangePlan = (e) => {
    let selsctArrChange = [];
    e.forEach((item) => {
      selsctArrChange.push(item.value);
    });
    setPlanId(selsctArrChange);
    
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Sub Category
        </h5>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={()=>{
            props.onBox()
          }}
        ></button>
      </div>
      <form onSubmit={handleSubmit(updateSubCategory)}>
      <div className="modal-body">
        <div className="row">

        <div className="col-md-12 mb-3">
            <label className="form-label">Edit Category<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <Select
                // isMulti
                defaultValue={catarr.map((item) => {
                  console.log(item, "565");
                  return { value: item._id, label: item.title }
                })}
                key={catarr.map((item) => {
                  return item._id;
                })}
                options={CatSelect}
                onChange={(e) => handleChangeCategory(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={true}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Sub Category Name<span style={{color:"red"}}>*</span></label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.title}
                className="form-control"
                 {...register("title", {
                                        required: true, 
                                        min:1

                                      })}
                                    />
                                    {errors?.title?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.title?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          

          {/* <div className="col-md-12 mb-3">
            <label className="form-label">Edit Plan</label>
            <div className="position-relative">
              <Select
                isMulti
                defaultValue={props.updatedData?.plan_id?.map((item) => { 
                  console.log(item,"special")
                  return { value: item._id, label: item.planName } })}
                  key={props.updatedData?.plan_id?.map((item) => { return item._id })}

              
               
                options={Plan}
                // onChange={(e) => handleChangePlan(e)}

                onChange={(e) => {
                  e.persist = () => { }
                  handleChangePlan(e)
              }}

                className="basic-multi-select"
                classNamePrefix="select"
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div> */}

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Description<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.discreption}
                className="form-control"
                {...register("discreption", {
                  required: true, min:1
                })}
              />
              {errors?.discreption?.type ===
                "required" && (
                <p>This field is required</p>
              )}
                {errors?.discreption?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          data-bs-dismiss="modal"
          onClick={()=>{
            props.onBox()
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdataSubCategory;
