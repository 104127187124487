import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";

// import "./Category.css";
import Footer from "../../shared/Footer/footer";

const UpdatePreset = (props) => {
  console.log(props,"props")
  const [preset, setPreset] = useState("");
  const [parentsid, setparentsid] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [Plan, setPlan] = useState([]);
  const [plan_id, setPlanId] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  function updatePresetData(postdata) {

    var data = JSON.stringify({
      'presetsid': props.updatedData?._id,
      'deriveLocation': postdata.deriveLocation === '' || null ? props.updatedData?.deriveLocation : postdata.deriveLocation,
 
     });
    
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editPresets`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        props.onEditDataFunction();
        props.onBox();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //---PLAN GET API--//





  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Tracking Preset
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={()=>props.onBox()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(updatePresetData)}>
      <div className="modal-body">
        <div className="row">
      

        

          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Tracking Preset<span style={{color:"red"}}>*</span> </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.deriveLocation}
                className="form-control"
                {...register("deriveLocation", {
                  required: true,
                  min:1
                })}
              />
              {errors?.deriveLocation?.type ===
                "required" && (
                <p>This field is required</p>
              )}
              {errors?.deriveLocation?.type === "min" && (
                <p>Minimum One Letter Required</p>
              )}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          

     
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          onClick={()=>props.onBox()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn submitBtn"
        >
          Submit
        </button>
      </div>
      </form>
    </>
  );
};

export default UpdatePreset;
