
import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AiFillEye } from "react-icons/ai";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import UpdateInspect from "./UpdateInspect";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};





function PostInspection() {
  const [Generalinformation, setGeneralinformation] = useState("");

  const [rowData, setRowData] = useState(getInspection);
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inspectionData, setInspectionData] = useState({});
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const pagination = true;
    const paginationPageSize = 10;
   const rowHeight = 55;

  let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({mode:'onChange'});

  
  function box() {
    setShow(false);
    cross();
  }

  function cross() {
    reset();
  }

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            console.log(props, "99999");
            setInspectionData(props.data);
            setShow(true)
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>
        {/* <div
          className="ViewIcon"
             onClick={() => {
              history.push({
                pathname: "SubCategoryView",
                state: { details: props.data},
              });
            }}
        >
          <AiFillEye />
        </div> */}
        <div
          className="DeleteIcon"
          onClick={() => {
            console.log(props.data._id, "props.data._id");
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCarCategoryData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function crossForm() {
    setIsOpen(false);
    reset();
  }

  function canclefun() {
    setIsOpen(false);
    reset();
  }

 
  

  // ------Post API-------//

  function addInspectionSubCategory(postdata) {
    setDisabled(true);
    var data = JSON.stringify({
      subhading: postdata.name,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/postInspection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "9999");
        getInspection();
        setIsOpen(false);
        reset();
        resetForm("");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const resetForm = () => {
    setGeneralinformation("");
  };

  useEffect(() => {
    getInspection();
  }, []);

  function getInspection() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getPostinspection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "getPostinspection");
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function InspectionDeleteData(id) {
    var data = JSON.stringify({
        _id: id,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletepostInspection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getInspection();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    console.log(props, "fghijok");
    return (
      <>
        {props?.data.status === true ? (
          <button
            className="btn btn-success btn-sm"
            onClick={() => {
              changeCategoryStatus(props?.data._id);
            }}
          >
            Verified
          </button>
        ) : (
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              changeCategoryStatus(props?.data._id);
            }}
          >
            Not Verified
          </button>
        )}
      </>
    );
  };


  function changeCategoryStatus() {}


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "subhading",
      headerName: "Post-Inspection",
      width: 250,
      Srno: true,
      sortable: false,
      filter: false,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 320,
      colId: "params",
      sortable: true,
      filter: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };


  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Post-Inspection
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => {
                      setIsOpen(true)
                    setDisabled(false)
                    }
                    }
                  >
                    Add New Post-Inspection
                  </button>
                </div>
              </h4>
              <div>
              <Modal show={isOpen} hide>
                  <Modal.Body>
                <div
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Post-Inspection
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => crossForm()}
                        ></button>
                      </div>
                      <form onSubmit={handleSubmit(addInspectionSubCategory)}>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Name<span style={{color:"red"}}>*</span></label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                {...register("name", {
                                  required: true,
                                  pattern: /^[0-9A-Za-z ]+$/i,
                                  min:1
                                })}
                              />
                              {errors?.name?.type === "min" && (
                                <p>Minimum One Letter Required</p>
                              )}
                              {errors?.name?.type === "required" && (
                                <p>This field is required</p>
                              )}
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                        onClick={() => canclefun()}
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn submitBtn"
                          disabled = { disabled ? true : false}
                        >
                          Submit
                        </button>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
                </Modal.Body>
                </Modal>
              </div>
              
              <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateInspect
                      updatedData={inspectionData}
                      onEditDataFunction={getInspection}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteCarCategoryData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Inspection ?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                InspectionDeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Users Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    {/* <h6> Sub Category  : {viewCategory.title}</h6> */}
                                  </div>
                                  <div>
                                    {" "}
                                    {/* <h6> Category image : {ViewFuel.image}</h6> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                   paginationPageSize={paginationPageSize}
                  //
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  //   defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostInspection;
