import React, { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateTraining from "./UpdateTraining";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete,AiFillEye } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Input from "../../Components/Input";
import { useForm } from "react-hook-form";
// import Modal from "react-bootstrap/Modal";
import Modal from 'react-bootstrap/Modal'
import ToggleButton from "../../Components/ToggleButton";


function Training() {
  const [DeleteDeleteId,setDeleteDeleteId] = useState("")
  const [UpdateTrainingData, setUpdateTrainingData] = useState([]);
  const [Viewuser, setViewuser] = useState([]);
  const [title, setTitle] = useState("");
  const [discreption, setDiscreption] = useState("");
  const [addimage, setimage] = useState("");
  const [rowData, setRowData] = useState(getTraining);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const pagination = true;
  const paginationPageSize = 20;
  const rowHeight = 55;

  const {register,handleSubmit,formState:{errors},reset} =useForm();

  const [isOpen, setIsOpen] =useState(false);


  const MoodRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <stong>{props.data.Training}</stong>
      </span>
    );
  };
  const imageRerander = (props) => {
    return (
      // <img src={props.data.image}/>
      <span className="profle_img_box">
      {props.data.image ? <img className="profile_img_table" src={props.data.image} alt="icon" /> : ""}
    </span>
    )
  }

  

  const SrNoRenderer = (props) => {
    console.log(props, "iam12333iam12333");
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon"
         onClick={() => {
          setUpdateTrainingData(props.data);
          setShow(true)
        }}
        >
          <MdModeEditOutline
            className="ActionIcon viewicon"
           
          />
        </div>
        <div className="ViewIcon">
          <AiFillEye
            onClick={() => {
              console.log(props, "propstyty");
              setViewuser(props.data);
            }}
            data-bs-toggle="modal"
            data-bs-target="#UserViewId"
            alt=""
            src="../../images/view.jpg"
          />
        </div>
        <div className="DeleteIcon"
         onClick={() => {
          setDeleteDeleteId(props.data._id);
        }}
        data-bs-toggle="modal"
        data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete
            className="ActionIcon"
           
          />
        </div>
      </div>
    );
  };

  function box() {
    setShow(false);
    cross();
  }

  // ------Post API-------//

  function addTraining(postdata) {
   setDisabled(true)
    var data = JSON.stringify({
      "heading": postdata.title,
      "Disperctions":postdata.discreption,
      "url":postdata.url,
 
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Addtraning`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
        
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getTraining();  
        crossForm();
        resetForm();      
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function canclefun() {
    setIsOpen(false);
    cancelForm();
  }

  function cancelForm() {
    reset();
  }

  function crossForm() {
    setIsOpen(false);
    cross();
  }

  function cross() {
    reset();
  }

  const resetForm = () => {
	
    setTitle('');
    setDiscreption('');
    setimage('');
    setimage('');
	  };


    // axios.get(`${process.env.REACT_APP_BASEURL}/getTraining`).then(function(response){
    //   console.log(response,"morning")
    // })

    // useEffect(() => {
   
    //   res()
    // }, []);

//  axios.get('http://54.221.3.19:/admin/getTraining', {
//  headers: {
//         Authorization: sessionStorage.getItem("token"),
//  }
// }).then(function(response){
//   setRowData(response.data.getdata);
// }) .catch(function (error) {
//          console.log(error);
//  });;


// res.data.headers[Authorization]; // "test-value"


  useEffect(() => {
   
     getTraining();
  }, []);

  function getTraining() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getTraning`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response,"catget")
        setRowData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function TrainingdeleteData(index) {
    var data = JSON.stringify({
      id: index,
    });
    console.log(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/DeleteTraningData`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
         getTraining();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const editdataReloadFunc = () => {
    // getTraining();
  };

  
  const StatusRenderer = (props) => {
    console.log(props, "fghijok")
    return (
      <>
        <ToggleButton
          handleToggle={() => {
            changeTrainingStatus(props?.data?._id);
          }}
          status={props?.data?.status}
        />
      </>
    );
  }

  function changeTrainingStatus(){}

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs, setColumnDefs] = useState([
    // simple column, easy to understand
    {
      field: "SrNo",
      headerName: "Serial No.",
      Srno: true,
      width: 90,
      Srno: true,
      sortable: false,
      filter: false,
      cellRenderer: "srNoRenderer",
    },
    {
      field: "heading",
      headerName: "Title",
      width: 200,
      sortable: false,
      filter: false,
    },
    // { field: "parentsid", headerName: "Training",
    // width:160,
    // sortable:false,
    // filter:false },
    {
      field: "Disperctions",
      headerName: "Description",
      width: 160,
      sortable: false,
      filter: false,
    },
    {
      field: "url",
      headerName: "URL",
      width: 200,
      sortable: false,
      filter: false,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "statusRenderer",
    // },
    {
      field: "Action",
      headerName: "Status",
      width: 200,
      colId: "params",
      sortable: true,
      filter: true,
      cellRenderer: "childMessageRenderer",
    },
    // the grid works with embedded fields
  ]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onFilterTextChange = (e) => {
    console.log(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Training
                <input
                  type="text"
                  style={{ marginLeft: "10px" }}
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextChange}
                />
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => {setIsOpen(true) 
                      setDisabled(false)}}
                  >
                    Add New Training
                  </button>
                </div>
              </h4>
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      // className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Training
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => crossForm()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addTraining)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Title<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <Input style="form-control"
                              onChange={(e) => {
                                console.log(e,"thisisevent")
                                setTitle(e?.target?.value);
                              }}/> */}

                                    {/* <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      {...register("title", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.title?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.title?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Description<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <textarea
                                type="text"
                                className="form-control"
                                value={discreption}
                                onChange={(e) => {
                                  setDiscreption(e.target.value);
                                }}
                              /> */}
                                    <textarea
                                      className="form-control p-2"
                                      {...register("discreption", {
                                        required: true,
                                        min:1
                                      })}
                                    />
                                    {errors?.discreption?.type ===
                                      "required" && (
                                      <p>This field is required</p>
                                    )}
                                    
                                    {errors?.discreption?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    URL<span style={{color:"red"}}>*</span>
                                  </label>
                                  <div className="position-relative">
                                    {/* <Input style="form-control"
                              onChange={(e) => {
                                console.log(e,"thisisevent")
                                setTitle(e?.target?.value);
                              }}/> */}

                                    {/* <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              /> */}

                                    <input
                                      className="form-control"
                                      {...register("url", {
                                        required: true,
                                        min:1,
                                        pattern : /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/
                                      })}
                                    />
                                    {errors?.url?.type === "required" && (
                                      <p>This field is required</p>
                                    )}
                                    {errors?.url?.type === "min" && (
                                      <p>Minimum One Letter Required</p>
                                    )}
                                    {errors?.url?.type === "pattern" && (
                                      <p>Invalid URL</p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                                onClick={() => canclefun()}
                              >
                                Cancel
                              </button>
                              <button
                                // onClick={addTraining}
                                type="submit"
                                className="btn submitBtn"
                                disabled = {disabled ? true : false}
                                // data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
             <Modal show={show} hide>
              <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdateTraining
                      updatedData={UpdateTrainingData}
                      onEditDataFunction={getTraining}
                      onBox = {box}
                    />
                  </div>
                </div>
              </div>
              </Modal.Body>
              </Modal>
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Training?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                TrainingdeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Training Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6> Training Title : {Viewuser.heading}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Training Description :{" "}
                                      {Viewuser.Disperctions}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Training URL :{" "}
                                      {Viewuser.url} {" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine cts_datatable"
                style={{ height: 645 }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{ flex: 1 }}
                  //   rowHeight={rowHeight}
                  style={{ width: "100%", height: "100%;" }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  domLayout="autoHeight"
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                  }
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  // defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    imageRender: imageRerander,
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Training;
