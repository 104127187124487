import { createContext, useState } from "react";
import * as React from "react";

const GlobalContext = createContext({
  show: false,
  displayMore: () => {},
});

export function GlobalContextProvider(props) {

  const [show, setshow] = useState(false);


  const itemDisplayMoreHandler =() =>{
    console.log("function")
    if (show) {
      setshow(false);
    } else {
      setshow(true);
    }
  }

  const context = {
    show: show,
    displayMore: itemDisplayMoreHandler,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
